// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import "./variable.scss";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$theme-primary: mat.define-palette(mat.$indigo-palette);
$theme-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$theme-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

// Define the second theme (e.g., a dark theme)
$theme-dark-primary: mat.define-palette(mat.$deep-orange-palette);
$theme-dark-accent: mat.define-palette(mat.$lime-palette, A200, A100, A400);
$theme-dark-warn: mat.define-palette(mat.$yellow-palette);

$dark-theme: mat.define-dark-theme((
  color: (
    primary: $theme-dark-primary,
    accent: $theme-dark-accent,
    warn: $theme-dark-warn,
  )
));
.dark-theme {
  @include mat.all-component-themes($dark-theme);
}

.btn-primary {
  background: mat.get-color-from-palette($theme-primary);
  color: $whitecolor;
  border: 1px solid mat.get-color-from-palette($theme-primary);

  &:hover {
    background: mat.get-color-from-palette($theme-primary, 700); // Use a darker shade for hover
    transition: all 0.3s ease !important;
    color: $whitecolor;
    border-color: mat.get-color-from-palette($theme-primary, 700);
  }
}

.header{
  background: $whitecolor;
  box-shadow: 0px 3px 3px 0px rgb(230 231 238 / 40%);
}
.control-panel{
  background: $snowgray;
}
.spark-whitebox{
  background: $whitecolor;
  border: 1px solid $grayborder;
}
.header-sparklogo{
  border-right: 1px solid $gainsboro;
}
.border-bottom {
  border-bottom: 1px solid $bdrBottom !important;
}
.speedo-mid-c{
  background-color: $speedoBg;
  &::before{
    color: $darkgray;
  }
  &::after {
    color: $darkgray;
  }
}
.vertical-line{
  opacity: 0.3;
  background-color: $darkblackcolor;
}
.line-marker{
  color: $dimgray;
}
.spark-label{
  color: $nightblue;
}
.speedo-mid-c > div {
  background: $whitecolor;
  border-color: $whitecolor;
  box-shadow: 1px 0px 13px inset #c2c4e0;
}
.cyber-reilience{
  .control-panel-col-header {
    border-bottom: 1px solid $grayborderlight;
  }
  .custom-btn{
    color: $aquamarine;
    background-color:none;
    border: none;

  }
}
.download-icon{
  fill: $aquamarine;
  color: $aquamarine;
}
.region-data{
  background: $whitecolor;
}
.spark-region-info {
  .region-data{
    background: $regionBg;
    border: 1px solid $regionbdr;
  }
}
.region-data-tab {
  color: $darkblackcolor;
}
.control-panel-col-header{
  border-bottom:1px solid $grayborderlight;
}
.ag-row{
  border: none;
  &:nth-child(odd){
      background-color: ghostwhite;
  }
}
.ag-theme-material {
  --ag-row-hover-color: $listhover !important;
}
:host ::ng-deep .mat-menu-panel .mat-menu-item {
  color: rgb(255 255 255 / 50%);
}
:host ::ng-deep .mat-menu-panel .mat-menu-item:hover {
  color: $aquamarine;
}
.mat-menu-panel {
  &.white-menu {
          background-color: #fff;
          .mat-menu-item {
              color: #8784AA;             
              &:hover {
                  color: #222222;
                  background: #F6F6FA;
              }
              &.active {
                  color: #222222;
                  background: #F6F6FA;
              }
          }
  }
}


.control-panel-row {
  .control-panel-item {
    .btn-custom {
      background-color: $menuItemColorHover;
      border: 1px solid $menuItemColorHover;
    }
   }
}
.ag-header-cell {
  color: $bluedepths;
}
.ag-cell {
  color: $lightblack;
}
.devices-item-right{
  color: $darkblackcolor;
}
.parameter-value{
  color: $darkblackcolor;
}
.new_vul_tabel .ag-cell{
  color: $darkblackcolor;
}
.region_name {
  color: $darkcharcoal;
}
.page_content {
  background: $whitecolor;
}
input.mat-input-element {
  color: $lightblack !important;
}
.cve-header-details-section{
  label{
    color:  $gcolor;
  }
 }
 .mat-cve-tabs{
  .mat-tab-header {
    background: $whitecolor;
  }
}
.cve-summaryinfo{
  p{
    color:$darkblackcolor;
  }
}
.summaryitem-value { 
  color: $darkcharcoal;
}
.authoruty-value{
  color: $darkcharcoal;
}
.vector-list.nist-list  {
  ul {
    li{
      color: $lightblack;
    }
  }
}
.cve-epss-title {
  color: $darkcharcoal;
}
.cve-reference-content{
  background-color: $ghostwhite;
}
.cve-enumerationitem {
  .name-item {
    color: $darkblackcolor;
  }
}
.cve-accordion-title{
  &.mat-expansion-panel-header{
      .mat-expansion-indicator{
          color: #000;
          &:after{
              color: #000;
          }
      }
  }
}
.empty-text{
  color: $darkblackcolor;
}


.notification-main .notification-header .header-1 p{
  color: $darkblackcolor; 
}

.notification-main .notification-filter .notification-filter-left {
  .btn{
    &:hover {
      border: 1px solid $notificationColor;
      color: $notificationColor;
   }
    &.active {
        color: $whitecolor;
    }
  }
}
.notification-main .notification-list .notification-container {
  .notification-item{
    &:hover {
      background: $lavendermist;
    }
    &.unread{
      background: $lavendermist;
      .notification-item-text span{
        color: $darkblackcolor;
      }
    }
  }
}
.notification-main .notification-filter .notification-filter-right .markall-btn{
  :hover{
   color: $notificationColor;
  }
}
.profiletab-box {
  background: $graybackground;
  .profiletab-itemlable{
    color: $blackcolor;
    p{
      color: $blackcolor;
    }
  }
  .profiletab-itemvalue {
    color: $blackcolor;
  }
}
.profile_details{
  background: $graybackground;
}
.profiletab-itemlable {
  color: $blackcolor;
}
.profiletab-itemvalue{
  color: $blackcolor;
  p{
    color: $blackcolor;
  }
}
.profile_page .sso-info .sso-switch{
  background: $graybackground;
}
.subscription-graybox{
  background: $graybackground;
}
.mat-drawer-container {
  background: transparent;
}
.plan-infolist{
  li {
    color: $darkblackcolor;
  }
}
.subscription-infogreybox{
  background: $lavenderlight;
  box-shadow: 0px -1px 0px 0px $lavenderlight;
  color: $darkblackcolor;
}
.regex-label{
  color: $darkgunmetal;
}
.search-results-btn{
  border: 1px solid $stormgray; 
  color: $stormgray;
  &.active {
    background: $aquamarine;
    border: 1px solid $aquamarine;
    color: $whitecolor;
  }
}
.search-container .search-results .search-results-content .search-tab-results .netsec_table1 .mat-row{
  &:nth-child(odd) {
    background:$ghostwhite; 
  } 
  &:hover {
      background: $paleLilac; 
      cursor: pointer;
  }
}
.spark_table_schedule .mat-row{
  &:nth-child(odd) {
    background:$ghostwhite; 
  } 
  &:hover {
      background:  $paleLilac; 
      cursor: pointer;
  }
}
.control-panel .control-panel-row .shedule-scan-item .schedule-scan-box { 
 background: $whitecolor;
}
.control-panel .control-panel-row .shedule-scan-item .schedule-scan-box .schedule-scan-col-header .asset-tittle { 
  color: $darkblackcolor;
}
.control-panel .control-panel-row .shedule-scan-item .schedule-scan-box.Assets-detail-table .devices-data .devices-list .devices_name {
  color: $darkblackcolor;
 }
 .control-panel .control-panel-row .shedule-scan-item .schedule-scan-box .Assets-detail-table .devices-data p {
  color: $darkblackcolor;
}
.spark_table_schedule  .mat-header-cell{
  color:$bluedepths;
}
.spark_table_schedule   .mat-row  .mat-cell{
  color:$lightblack;
}
.font{
  color:$darkcharcoal;
}
.cred-management-page .role-search .cred-search-list .cred-label:nth-child(even){
  background: $ghostwhite;
}

.cred-management-page .role-search .cred-search-list .cred-label:hover{
  background: $Platinum;
}

.cred-management-page .role-search .cred-search-list .cred-label:active {
  p{
    color: $whitecolor !important;
  }
 }


.mat-chip.mat-standard-chip{
  background-color: $ghostwhite;
}
// .input-box-container .exec-field p {
//   color:$nightblue;
// }

.netsec-modelbox .modelbox-body  p {
  color: $dimgray;
}

.netsec-modelbox p {
  color: $blackopacity;
}

.netsec-modelbox .sub-text {
  color: $blackopacity;
}


.device-header {
  color: $nightblue;
}

.control-panel-col-header h3 {
  color: $nightblue;
}

.score-details {
  margin-left: auto;
  font-weight: 700;
  font-size: 12px;
  color: $lightblack;
  display: inline-flex;
  align-items: center;

  .score-with-direction {
    color: $blackblue;

    &.high-val {
      color: $coralRed ;
    }

    .low-val {
      color: $seaGreen;
      ;
    }
  }
}

.comparator-drop-section {
  background-color: #EFF4F9;
}

monaco-editor,
.monaco-editor-background,
.monaco-editor .inputarea.ime-input {
  background-color: $softwhite;
}

.mtk1 {
  color: $blackcolor;
}

.margin-view-overlays {
  background-color: $softwhite;
  color: $blackcolor;
}

.new-scan-box {
  color: $darkblackcolor;

  .header-box p {
    color: $eastbay;

    .new-scan-boxtitle {
      color: $tristesse;
      border-bottom: 1px solid $charcoalGray;
    }

    .new-scan-boxsubtitle p {
      color: $tristesse;
    }
  }
}

.config-label {
  color: $bluedepths;
}

.value-with-direction {
  color: #150F49;
}

.card-title {
  color: $darkcharcoal;
}

.parameter-label {
  color: $darkblackcolor;
}

.profile-disc-text {
  color: $darkblackcolor
}

.profile-disc-label {
  color: $bluedepths;
}

.person-details-header {
  color: $dimgray;
}
.notification-icon{
  img{
    &.dark{
      display: none;
    }
  }
}
.side-nav-ul li{
  &:hover {
    background: rgb(231 230 243 / 50%);
  } 
}
.side-nav-ul li a.active, .side-nav-ul li a:hover { 
  color: $aquamarine;
  .arrow-menu{
    border-color: $aquamarine;
  }
}
.details-popup .details-right .scan-item-details .details-info .details-info-content 
.details-info-values .details-info-values-legends .legends li label{
  color: $russianblack;
}
.details-popup .details-right .scan-item-details .details-info .details-info-content .details-info-values .details-info-values-graf{
  .graf-value{
    color: $darkblackcolor;
  }
  .graf-title{
    color: $russianblack;
  }
  
}
.mat-menu-panel.benchmark-info::before{
  background-color: $eggwhite;
}
.details-popup .details-right .scan-item-details .details-info .details-info-content .details-info-list-item .benchmark-list .list-header-section .list-item{
  color: $darkblackcolor;
}

.filter-header {
  .header-tool .header-tool-content .header-text{
    color: $whitecolor;
  }
}
:host ::ng-deep {
  .all-selection{
    color:  #10111E;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .nested-tree-node{
    border-bottom:  1px solid rgba(0, 0, 0, 0.1);
  }
}
 .upload-tree {
  label{
    color: #10111E;
  }
  .tree-node {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.upload-tree .mat-icon-rtl-mirror{
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
  &.tringle {
    border-top: 5px solid #323232;
  }
  &.invert-tringle {
      border-left: 5px solid #323232;
      border-bottom: 5px solid transparent;
      border-top: 5px solid transparent;
  }
}
.page_header .page_header_right {
  .btn.btn-plain{
    &:hover {
      color:$darkblackcolor
    }
  }
}
.benchmark-list {
  background-color: $graybackground;

  .list-header-section {


    .list-item {
      color: $darkblackcolor;
    }

    .expand-btn {
      color: #79799A;
    }
  }

  .list-item-discription {
    color: $darkblackcolor;
  }
}
.selected-list .mat-form-field-appearance-fill .mat-form-field-flex {
  background: $whitecolor;
}
.disable-selected-list-item:nth-child(odd) {
  background-color: $ghostwhite; 
}
.disable-selected-list-item {
  &.list-search-box {
    background-color: $whitecolor;
    border-top: 1px solid $linkwater !important;
    border-bottom: 1px solid $linkwater !important;
  }
  .selected-list-item-left{
    color: #787777;;
    &:hover {
      color: $darkblackcolor;
    
    }
}
}
.mat-form-field-appearance-fill .mat-form-field-flex,
.mat-form-field-appearance-outline .mat-form-field-outline {
    border-radius: 4px;
    background: $lightgrayish;
    height: 44px;
    height: 44.5px;
}
.telent-selected-item {
  border-bottom: 1px solid $linkwater;
  border-top: 1px solid $linkwater;
    p {
    color: $darkblackcolor;
  }
}
.invite-modalbox {
  h3 {
    color: $darkblackcolor;
  }

  .sub-text {
    color: $blackopacity;
  }
}
.inviteuser-name{
  color:$darkcharcoal;
}
.inviteuser-dorpdown{
  color:$darkcharcoal;
}
.inviteuser-id :after{
  background: rgba(51, 51, 51, 0.5);
}
.custom-sidebar {
  background-color: $speedoBg;
  border: 1px solid #ddd !important;

}
app-new-scan{
.new-scan{
  background: url(assets/images/upload-conceptbg.png) center no-repeat;
}
}
.dark-image-sec{
  img{
    display: inline;
    &.dark{
      display: none;
    }
  }
}
.cyber-management-page .cyber-main-section .vpn-list .vpn-search-list .vpn-list-item{
  &:nth-child(even) {
        background: $ghostwhite;
  
    }
  
    &:hover {
        background: $Platinum;
    }
  }
  .cyber-management-page .cyber-main-section .vpn-list .vpn-search-list .vpn-list-item .vpn-tunnelname .dark-mode-visible {
   display: none;
  }
  .dark-mode-visible{
    display: none;
  }
  .dark-mode-hidden {
    display: block;
  }
  .cyber-management-page .cyber-main-section .vpn-list .vpn-search-list .vpn-list-item .vpn-tunnelname .dark-mode-hidden  {
    display: block;
   }
   .cyber-management-page .cyber-main-section .vpn-data-section .vpn-data .vpn-data-inner .right-inner .item .dark-mode-visible{
    display: none;
   }
  
   .cyber-management-page .cyber-main-section .vpn-data-section .vpn-data .vpn-data-inner .right-inner .item .dark-mode-hidden{
    display: block;
   }
  
   .edit_cloud_scan .edit_cloud_scan_header .header1 p {
    color: $darkblackcolor;
    }
    .cloud-scan-container .cloud-scan-header {
      border-bottom: 2px solid lightgray;
    }
    .cloud-scan-container .cloud-scan-header .header-left p{
     color:$darkcharcoal; 
    }
  
    .cloud-scan-container .cloud-scan-content .sub-text {
      color: $eastbay;
     }
  
     .cloud-scan-container .cloud-scan-content .radio-btn-secction .report-label .radio-title{
      color: $darkcharcoal;
     }
     .cloud-scan-container .cloud-scan-content .info-fields .setup-info .setup-list{
      color: $blackblue;

     }

     .whitelisted-history .whitelisted-history-section .whitelisted-history-info .whitelisted-history-item .flex-data .whitelisted-history-right .history-title {
      color: $darkcharcoal;
     }

     .whitelisted-history .whitelisted-history-section .whitelisted-history-info .whitelisted-history-item .circle-position:before {
      background-color: $whitecolor;
    }
    .whitelisted-history .whitelisted-history-section .whitelisted-history-info .whitelisted-history-item .circle-position:after {
      background-color: $whitecolor;
    }
  
.newscan-info .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-start, .newscan-info .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-end,
.newscan-info .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border-color: $darkblackcolor ;
    transition: all 0.2s;
}
.newscan-info .mat-form-field-appearance-outline .mat-form-field-outline-end {
   
    border:1px solid $darkblackcolor !important;
    border-left-style: none!important;
}
.newscan-info .mat-form-field-appearance-outline .mat-form-field-outline-gap, .newscan-info .mat-form-field-appearance-outline:hover .mat-form-field-outline-gap {
    border: 1px solid $darkblackcolor !important;
    border-left-style: none !important;
    border-right-style: none !important;
} 
.newscan-info  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
    border-top-color: rgba(0,0,0,0) !important;
}
.newscan-info .mat-form-field-appearance-outline .mat-form-field-outline-start {
   
    border:1px solid $darkblackcolor !important;
    border-right-style: none!important;
}
.newscan-info .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-end {
   
    border:2px solid $darkblackcolor !important;
    border-left-style: none!important;
}
.newscan-info .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-start {
   
    border:2px solid $darkblackcolor !important;
    border-right-style: none!important;
}
.newscan-info .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border: 2px solid $darkblackcolor !important;
    border-left-style: none !important;
    border-right-style: none !important;
    border-top-style: none !important;
}
.uploaded-files-section .uploaded-files{
  color: $darkblackcolor;
}
.create-role-modalbox {
  background: $whitecolor;
}
.mat-form-field-appearance-outline.border-field .mat-form-field-outline-start, .mat-form-field-appearance-outline.border-field .mat-form-field-outline-end {
     
  border-color: $darkcharcoal;
}
.mat-form-field-appearance-outline.border-field .mat-form-field-outline-thick .mat-form-field-outline-start, .mat-form-field-appearance-outline.border-field .mat-form-field-outline-thick .mat-form-field-outline-end, .mat-form-field-appearance-outline.border-field .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-color: $darkcharcoal; 
  }

.create-role-modalbox .sub-text{
  color: $dimgray;
}
.mat-form-field-appearance-outline.border-field .mat-form-field-outline-gap { 
  border-color: $darkcharcoal; 
 }
 app-active-session .modal-container  {
  .header{
    color: $blackcolor;
  }
  
  .content{
    color: $dimgray;
  }
 }
 app-session-expired-modal{
  .header{
    color: $pastelred;
  }
  
  .content{
    color:$dimgray;
  }
 }
 .field_length{
  color: rgba(0, 0, 0, 0.3);
 }
 .cloud-scan-container .cloud-scan-header .header-left {
  p{
    color: $darkcharcoal;
  }
  .cloud-scan-content .radio-btn-secction {
    .report-label{
      .radio-title{
        color: $darkcharcoal;
      }
    }
    .info-fields .setup-info.setup-list{
      color: $nightblue;
    }
  }
   
 }
 .netsec-modelbox {
  p{
    color: $darkblackcolor;
  }
  .sub-text{
    color: $blackopacity;
  }
 }
 .toast-container{
  background-color: $whitecolor;
}
.toast-message{
  color: $darkblackcolor;
}
.app-container{
  background: $lightshadegray;
}
.myscan_page .myscan-mgnt-header{
  border-bottom: 1px solid lightgray;
}
.scan-details-container .left-side-container .scan-details-header-section {
  border-bottom: 1px solid #ccc;
}
.white-box, .white-box-svg{
  background: $whitecolor;
  border: 1px solid $grayborderlight;
}
.noti-rght-header,.sub-item-row{
  border-bottom: 1px solid $linkwater;
}
.right-side-header {
  .selected-list-header{
    border-bottom: 1px solid $grayborderlight;
  }
  &.border{
    border-bottom: 1px solid $grayborderlight;
  } 
}
.scan-item-details .details-section .details-info {
  .details-info-content{
    .details-info-values{
      border-bottom: 1px solid $grayborderlight;
    }
  }
  .details-info-benchmark{
    border-bottom: 1px solid $gainsboro;
  }
}
.benchmark-mapping{
  border-bottom: 1px solid $grayborderlight;
}
.device_page .device-mgnt-header{
  border-bottom: 1px solid lightgray;
}
.whitelist_page .whitelist-page-header{
  border-bottom: 1px solid lightgray;
}
.parameter-chart{
  border-bottom: 1px solid $gainsboro;
}
.whitelist-parameter .parameter-info-container .parameter-info:after{
  background: $gainsboro;
}
.whitelisted-history .whitelisted-history-section .whitelisted-history-info::before{
  background: $gainsboro;
}
.cred-management-page {
  .cred-data {
    .cred-data-header{
      border-bottom: 1px solid $gainsboro;
    }
  }
  .role-search{
    .role-search-header{
      border-bottom: 1px solid $grayborderlight;
    }
  }
}
.si-content {
  .scan-details-header-section{
    border-bottom: 1px solid #ccc;
  }
}

.cyber-management-page .cyber-main-section {
  .vpn-list .vpn-search-header{
    border-bottom: 1px solid $grayborderlight;
  }
  .vpn-data-section .vpn-data .vpn-data-header{
    border-bottom: 1px solid #D9D9D9;
  }
}
.vpn-management-page .vpn-main-section  {
  .vpn-list{
    .vpn-search-header{
      border-bottom: 1px solid $grayborderlight;
    }
  }
  .vpn-data{
    .vpn-data-header{
      border-bottom: 1px solid #D9D9D9;
    }
  }
}
.user_page .user-mgnt-header{
  border-bottom: 1px solid lightgray;
}

.role-management-page {
  .role-search .role-search-header{
    border-bottom: 1px solid $grayborderlight;
  }
  .role-data .role-data-header{
    border-bottom: 1px solid #D9D9D9;
  }
}
.super-permission{
  border-bottom: 1px solid $gainsboro;
  border-right: 1px solid $gainsboro;
}
.region-section {
  .region-left .region-box{
    border-bottom: 1px solid $gainsboro;
  }
  .region-right .region-list .region-list-item{
    border: 1px solid #ccc;
    &:last-child {
      border: 1px solid #ccc;
  }
  }
}

//ag-loaders
.ag-grid-tabel{
  .ag-pinned-left-header{
      border: none;
  }
  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell){
      border: none;
  }
  .ag-overlay-panel{
      .ag-overlay-loading-wrapper{
          background: none;
      }
  }
  .ag-overlay-panel{
      background:#fff;
  }
}
.account_dropdown.mat-menu-panel .mat-menu-content ul .mat-menu-item button {
  color: $darkgray;
}
.mail-text {
  color: $darkgray;
}
.account_dropdown {
  &.mat-menu-panel {
       background: $whitecolor;
  }
}
.account_dropdown {
  &.mat-menu-panel {
    .mat-menu-content {
      ul {
        .mat-menu-item {
          color: $darkgray;

          &:hover {
            background: $lavendermist;
          }
        }
      }
    }
  }
}
.tool-sublist{
  background: $graybackground;
 }
 .accordion-header {
  .accordian-label {
   color:#A39FCB;
}
.accordion-arrow {
    color: #A39FCB;
}
}
.add-level {
  color:  $aquamarine;
  background: transparent;
}
.filter-section {
  .search-bar {
    background-color: #EAEBF4;
}
}
// .org-level-lables{
//   color: $blackcolor;
//   background-color: $whitecolor;
// }
.cybertooltip-popup-section {
  background-color: $whitecolor;
  color: $darkblackcolor;
  h5 {
    border-bottom: 1px solid $grayborderlight;
  }
  .cyber-score-list {
    li{
      border-bottom: 1px solid $grayborderlight;
      color: $lightblack;
    }
  }
}   
.mat-tooltip, .tooltip {
  background: $whitecolor !important;
  color: $blackcolor !important;
}   
.ag-grid-custom-font {
  .ag-tooltip {
    background: $whitecolor ; 
        color:$blackcolor ;
  }
} 
.asset-vul-tooltip{
  > .ag-dialog{
    background-color: #fff;
    color: #1E2036;
  }
} 
.no-data-section {
  height: calc(100vh - 61px);
  align-items: center;
  justify-content: center;

  .no-data-info {
      h3 {
          margin: 0px;
          padding: 0px;
          color: $nightblue;
          font-size: 20px;
          line-height: 25px;
          padding-bottom: 10px;
          font-weight: 700;
          margin-top: 40px;
      }

      p {
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          padding-bottom: 30px;
          max-width: 416px;
      }

      .btn {
          border-radius: 4px;
          line-height: 37px !important;
          height: 37px;
          padding: 0px 45px;
          font-weight: 600;
      }
  }
}
.password-field:-webkit-autofill {
  -webkit-text-fill-color: $blackcolor;
}
.complaince-header {
  .header {
    p {
      color: $darkblackcolor;
    }
  }
}
.custom-field:-webkit-autofill {
  -webkit-text-fill-color: $blackcolor;
}
.complaince-main  {
  .complaince-header{
    .header p{
      color: $darkblackcolor;
    }
  }
  .complaince-body {
    p{
      color: $nightblue;
    }
  }
}
.ag-custom-tooltip{
  background: $whitecolor;
  color: $darkblackcolor ;
 
}
.ag-vul-tooltip-score{
  > .ag-dialog{
    color: $darkblackcolor;
    background-color: $whitecolor;
  }
}
.ag-vul-tooltip {
  > .ag-dialog{
    color: $darkblackcolor;
    background-color: $whitecolor;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
  }
}
.region-data .region-data-inner .region-data-tabs {
  border-bottom: 1px solid #D5DBE3;
}
.notification-dropdown.mat-menu-panel {
  background: $whitecolor;
}
.notification-details{
  background-color: $whitecolor;
}
.sub-item-list {
  border-top: 1px solid #ddd;

}

.filter-section.has-second-level .filter-row.child-check.sub-check-fields.vendor-name{
  color: #2B335F;
}
.search-container {
  .regex-invalid-container {
    .instruction {
      .instruction-title{
        color: #33323A;
      }
      .instructions-list{
        background-color: $lavender;
      }
    }
  }
  .search-header{
    
    .search-header-right {
      
      border: 1px solid #E9E9F8;
      .search-clear {
        .search-text {
          color: $lightblack;
        }
      }
      .serch-field {
        .search-input{
          color: #80809E;
          background-color: #F5F5FF;
          &.hover {
            border: 2px solid $aquamarine;
          }

          &:focus {
            border: 2px solid $aquamarine;
          }
        }
      }
    }
  }
}
.search-text-highlight {
  color: $aquamarine;
}
.notification-main .notification-list .notification-container .notification-item{
  border-bottom: 1px solid #E2E6F3; 

}
.notification-dropdown.mat-menu-panel .mat-menu-content .mat-radio-button:hover {
  background: #F4F4FE;
}
.asset-tabgroup .mat-tab-header{
  border-bottom: 1px solid #DBDBEF;
}
.assets-info-section .search-bar {
  border-bottom: 1px solid #DBDBEF;
}
.log-title {
   color: $darkblackcolor;
}

.cve-vuldetails-item {
  border: 1px solid #DDDDE0;
  display: flex;
  background: #E2E2F233;
}
.page-title{
  mat-icon {
    color:$nightblue;
  }
}
.region-table tbody tr{
  &:nth-child(odd) {
    background: #F9FAFE;
}
}
.region-table{
  thead {
    tr{
      th{
        background: #E5E8F0;
        color: #333333;
        border-right: 1px solid #B0B8C8;
        border-bottom: 1px solid #B0B8C8;
      }
      td {
        .add-data {
          background: none;
          color: $aquamarine;
        }
      }
    }
  
  }
}
.region-table tbody tr td .label-path{
  border-bottom : 1px solid #B0B8C8;
}
.region-table tbody tr td .add-data {
  background: transparent;
  color: $aquamarine;
}
.mat-menu-panel.mapregion-info {
  background: $eggwhite;
  border: 1px solid #D6CA8C;
  &.mapregion-info::before {
    background-color: $eggwhite;
    border-top: 1px solid #D6CA8C;
    border-left: 1px solid #D6CA8C;
  }
}
.mat-menu-panel.mapregion-info p {
  color: #787777  ;
}
.selected-list-filecount {
  color: $nightblue;
}
.text-critical {
  color: $pastelred;
}
.text-bluelight {
  color: $aquamarine;
}
.text-medium-level {
  color: $orange
}
.text-low {
  color: #19A71F;
}
.text-high {
  color: $gray23color;
}
.text-green {
  color: $junglegreen;
}
.cve-score{
  color: $lightblack;
}
.browse-file-content {
  p{
    color: $tristesse;
  }
}
.collapse-btn {
  color: $aquamarine;
}
.whitelisted-history-pic{
  .time-label{
      color: $aquamarine;
  }
}
.btn-outline { 
  color: $aquamarine;
  border: 1px solid $aquamarine;
  background: transparent;
  cursor: pointer;
  &:hover {
      background: $aquamarine;
      transition: all 0.3s ease !important;
      color:$whitecolor ;
      border-color: $aquamarine;
  }
  &.secondary-outline {
      color: $lightblack;
      border: 1px solid $lightblack;
      &:hover {
          color: $whitecolor;
          background: $lightblack;
      }
  }
  &.red-outline {
      color: $tartorange;
      border: 1px solid $tartorange;
      &:hover {
          color: $whitecolor;
          background: $tartorange;
      }
  }
  &.diamond-outline {
      color: $diamondbtn;
      border: 1px solid $diamondbtn;
      &:hover {
          color: $whitecolor;
          background: $diamondbtn;
      }
  }
}
.search-term-wrapper {
  background-color: #E2E6F3;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background: $aquamarine;
  color: $whitecolor;
}.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-tab-label-content {
  color: $aquamarine !important;
}
.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label.mat-tab-label-active {
  border-bottom: 3px solid  $aquamarine !important;
}
.vector-list.nist-list ul li{
  &:hover {
    background: $lavendermist; 
}
&.selected {
  background: $lavendermist; 

}
&.active {
  background: $lavendermist; 
}
}
.side-nav-header .mat-icon { 
  color: #0B0D16;
}
.search-container.search-results .search-results-content .table-header h5 {
  color: $aquamarine;

}
.cve-overlay-header, .vulnerabilities-header,.scan-mgnt-header {
  border-bottom: 1px solid #B0B8C8;
}
.no-data-section {
  .no-data-info {
     h3 {
         color: $nightblue;
     }
     p {
         color: $darkblackcolor;
     }
   }
 }
 .option-card {
  background: $ghostwhite;
  border: 1px solid #DBDBEF;
  .download-template,
  .go-to-aws {
      color: $tristesse;
  }
  .order-list {
     color: $lightblack;

      b {
          color: $tristesse;
      }
  }
  .btn-link{
     background: $aquamarine;

  } 

}
 .nav-btn{
  border: 1px solid rgba(0, 0, 0, 0.1);
 }
 .calander-data-tabel {
  .devices-list {
      .scanName {
          color: $darkblackcolor;
      }
  }
}.schedule-tooltip {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 12px 8px;
  .tooltip-header{
    color: #333;
    border-bottom: 1px solid #ccc;
  }
}
.schedule-tooltip::after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.schedule-tooltip.arrow-top::after {
  border-bottom: 10px solid transparent;
  border-top: 10px solid #fff;
}
.schedule-tooltip.arrow-bottom::after { 
  border-top: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.history-details-header {
  border-bottom: 1px solid $gainsboro;
}
.history-telnet-item {
  border-bottom: 1px solid $gainsboro;
}
.person-details-header {
  border-bottom: 1px solid $gainsboro;
}
.history-telnet-list{
  border-right: 1px solid $gainsboro;
}
.upload-browse-file {
  border: 1px dashed $aquamarine;
  background: rgb(223 222 236 / 30%);
}
.label-data {
  .label-text{
    color: $grayundertone;
  }
  .label-count{
    &.critical-count {
      color: #670004;
  }
  &.high-count {
      color: #A7000F;
  }
  &.medium-count {
      color: #E85800;

  }
  &.low-count {
      color: #F2BF00;

  }
  }
}
.view-btn {
  color: $grayundertone;
}
.cve-name {
  color: $darkblackcolor;

}
.cve-time {
  color: #66618F;
}
.cve-left{
  border-right: 1px solid #CCCAE7;

}
.cve-new-data {
  border: 1px solid #CCCAE7;
}
.asset-count {
  color: #150F49;
}
.asset-text {
  color: #66618F;
}
.input-container .btn {
  color: $aquamarine;
}
.cloud-resource-header {
  border-bottom: 1px solid lightgray;
}
.config-cnt{
  color: #B61111;
}
.dark_color_type{
  color:$lightblack
}
.mat-badge-content {
  background: $aquamarine;
}
.cve-reference-content {
  .hyperlinks-item {
    a {
      color: $aquamarine;

    }
  }
}
.cve-reference-header {
  .cve-resourcesitem {
    color: $bluedepths;
  }
}
.enumeration-font {
  color: $darkcharcoal;
}
.configurations-label {
  color: $darkcharcoal;
}
.ag-header-cell:hover{
  background-color: #edf4f4 !important;
}
.vultooltip-section{
  background-color: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 12px 8px;
  &::after {
      border-top: 19px solid #fff; 
  }
   div {
      color: rgb(58, 42, 218);
      span{
          color: rgb(105, 101, 149);
      }
  }
}
.paramer-value{
  color: #000;
}
.rescan-modalbox {
  background: $whitecolor;

  .sub-text {
    color: $dimgray;
  }

}
// Dark theme button styling (Optional)
.dark-theme {
  background: $darkThemeBg;
  color: $whitecolor;
  .ag-header-cell:hover{
    background-color: $darkpopup !important;
  }
  .configurations-label {
    color: $regionbdr;
  }
  .cve-reference-header {
    .cve-resourcesitem {
      color: $beatrice;
    }
  }
  .enumeration-font {
    color: $beatrice;
  }
  .cve-reference-content {
    .hyperlinks-item {
      a {
        color: $brightcobalt;
  
      }
    }
  }
  .mat-badge-content {
    background: $aquamarine;
  }
  .config-cnt{
    color: $pastelred;
 }
   .mat-select-panel  {
    background-color: $dspeedoCircle !important;
    box-shadow: 0px 2px 2px  rgba(0, 0, 0, 0.7);
  }
.mat-option.mat-active,.mat-option:hover {
  background-color: $darkThemebgColor !important;
}
  .cloud-resource-header {
    border-bottom: 1px solid $charcoalGray;
  }
  .input-container .btn {
    color: #7678FF;
  }
  .asset-text { 
   color:  #8480AD;
  }
  .asset-count {
    color: #BDBDDA;
  }
  .cve-new-data {
    border: 1px solid $dimgray;
  }
  .cve-left{
    border-right: 1px solid $charcoalGray;
  
  }
  .cve-name {
    color: $whitecolor;
  
  }
  .cve-time {
    color: #8480AD;
  }
  .view-btn {
    color:$linkwater ;
  }
  .label-data {
    .label-text{
      color: $linkwater;
    }
    .label-count{
      &.critical-count {
        color: #B2102F;
    }
    &.high-count {
        color: #A7000F;
    }
    &.medium-count {
        color: #E85800;
  
    }
    &.low-count {
        color: #F2BF00;
  
    }
    }
  }

  .upload-browse-file {
    border: 1px dashed $brightcobalt;
    background: $darkpopup;
  }
  .schedule-tooltip {
    background-color: #262830;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 12px 8px;
    .tooltip-header{
      color: $whitecolor;
      border-bottom: 1px solid $charcoalGray;
    }
  }
  .schedule-tooltip::after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
  .schedule-tooltip.arrow-top::after {
    border-bottom: 10px solid transparent;
    border-top: 10px solid #262830;
  }
  .schedule-tooltip.arrow-bottom::after { 
    border-top: 10px solid transparent;
    border-bottom: 10px solid #262830;
  }
  .history-details-header {
    border-bottom: 1px solid $charcoalGray;
  }
  .history-telnet-item {
    border-bottom: 1px solid $charcoalGray;
  }
  .person-details-header {
    border-bottom: 1px solid $charcoalGray;
  }
  .history-telnet-list {
    border-right: 1px solid $charcoalGray;
  }
  .nav-btn{
    border: 1px solid $darkThemeBdr;
    button{
      color: $whitecolor;  
      &:disabled{
        span{
          opacity: 0.4;
          cursor: not-allowed;
        }
      }
    }
   }
  .cve-overlay-header, .vulnerabilities-header,.scan-mgnt-header {
    border-bottom: 1px solid $charcoalGray;
  }
  .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent, 
  .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker{
    color: #f44336;
  }
  .mat-focused .mat-form-field-required-marker{
    color: #f44336;
  }
  .history-details-popup .history-details-content .history-telnet-list .history-telnet-item
  .history-telnet-itemright,.history-telnet-itemleft{
    span .mat-icon{
      color: $whitecolor;
    }
  } 
  .parameter-details .benchmark-mapping .bn-section .bn-right .d-crt  {
    .details-info-values-graf .graf-value, .graf-title{
      color: $whitecolor !important;
    }
    .details-info-values-legends .legends li label{
      color: $whitecolor !important; 
    }
  }
  
  .scan-item-details .selected-list-details .selected-list 
  .disable-selected-list-item.accepted-item{
    .selected-list-item-left{
      color: $beatrice !important;
      &:hover {
        color: #696595;
      
      }
    }
  }
  .filter-section  .search-bar .mat-form-field-appearance-fill .mat-form-field-flex, 
  .filter-section  .search-bar .mat-form-field-appearance-outline .mat-form-field-outline{
    background: #4a4f57 !important;
  }
  .edit-button{
    color: $whitecolor !important;
  }
  .search-container.search-results .search-results-content .table-header h5 {
    color: $whitecolor;
 
  }
  .side-nav-header .mat-icon { 
    color: $steelgray;
  }
  .vector-list.nist-list ul li{
    &:hover {
      background: $darkThemebgColor; 
  }
  &.selected {
    background: $blackcolor; 
  
  }
  &.active {
    background: $darkThemebgColor; 
  }
  }
  .mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-tab-label-content {
    color: $whitecolor !important;
  }
  .mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label.mat-tab-label-active {
    border-bottom: 3px solid  $whitecolor !important;
  }
  .browse-file-content {
    p{
      color: $whitecolor;
    }
  }
  .cve-score{
    color: $beatrice;
  }
  .text-green {
    color: $junglegreen;
}
  .text-low {
    color: #19A71F;
}
.text-high {
  color: $gray23color;
}
  .text-medium-level {
    color: $orange
}
  .text-critical {
    color: $pastelred;
  }
  .text-bluelight {
    color: $brightcobalt;
}
  .selected-list-filecount {
    color: $whitecolor;
  }
  .mat-menu-panel.mapregion-info {
    background: #262830;
    border: 1px solid #4A4F57;
    color: $whitecolor;

    &.mapregion-info::before {
      background-color: #262830;
      border-top: 1px solid $charcoalGray;
      border-left: 1px solid $charcoalGray;
    }
  }
  .mat-menu-panel.mapregion-info p {
    color: $whitecolor;
  }

  .page-title{
    mat-icon {
      color:$steelgray;
    }
  }

.apexcharts-xaxis-title-text {
fill: $whitecolor;
  }
  .log-title {
    color: $whitecolor;
 }
 .noti-rght-header, .sub-item-row{
  border-bottom: 1px solid rgba(164,163,187,.3);
}
.cve-vuldetails-item {
  border: 1px solid rgba(164,163,187,.3);
  display: flex;
  color: $whitecolor;
  background: $darkpopup;
}
  &.header-stick {
    .login_page.container .login_form{
      background: $blackGrey !important;
      color: $whitecolor !important;
     .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-start,
     .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-end,
     .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-gap {
      border-color: $whitecolor !important; 
     }
     .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-end {
      border:2px solid $whitecolor !important;
      border-left-style: none!important;
     }
    .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-start { 
      border:2px solid $whitecolor !important;
      border-right-style: none!important;
     }
    .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-gap {
       border-bottom: 2px solid $whitecolor !important; 
     }
    .mat-form-field-appearance-outline .mat-form-field-outline-start, 
    .mat-form-field-appearance-outline .mat-form-field-outline-end {
      border: 1px solid $whitecolor !important; 
    } 
    .mat-form-field-appearance-outline .mat-form-field-outline-start { 
        border-right-style: none !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline-end { 
      border-left-style: none !important; 
    }
    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
      border-top-color: $whitecolor !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline-gap { 
        border: 1px solid $whitecolor !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline-gap { 
        border: 1px solid $whitecolor !important;
        border-left-style: none !important;
        border-right-style: none !important;
    }
    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label{
      color: $whitecolor !important;
      mat-label{
        background: $blackGrey;
      }
    }
    .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker{
      color: inherit !important; 
    }
    &.change-password{
      .mat-form-field-infix {
        input:autofill,  input{
          color: $whitecolor !important;
          -webkit-text-fill-color:  $whitecolor !important;
        }
      }
    }
     
    }
  }
  .search-container {
    .regex-invalid-container {
      .instruction {
        .instruction-title {
          color: $lavendergray;
        }
        .instructions-list{
          background: none;
        }
      }
    }
    .search-text-highlight {
      color: $whitecolor;
    }
    .search-header{
      .search-header-right {
        border: 1px solid $darkblackcolor;
        .search-clear {
          .search-text {
            color: $whitecolor;
          }
        }
        .serch-field {
          color: $whitecolor;
          .search-input{
            color: $whitecolor;
            background-color:  $darkblackcolor;
            &.hover {
              border: 2px solid $darkblackcolor;
            }
  
            &:focus {
              border: 2px solid $darkblackcolor;
            }
          }
        }
      }
    }
  }
  .asset-tabgroup .mat-tab-heade, .assets-info-section.search-bar {
    border-bottom: 1px solid rgba(164,163,187,.3);
  }
  .assets-info-section .search-bar {
    border-bottom: 1px solid rgba(164,163,187,.3);
  }
  .sub-item-list {
    border-top: 1px solid rgba(164,163,187,.3);
  }
  .filter-section.has-second-level .filter-row.child-check.sub-check-fields.vendor-name{
    color: #888994;
  }
  .notification-dropdown.mat-menu-panel {
    background: #424242;
  }
  .notification-details{
    background-color: $darkThemebgColor;
  }
  .notification-dropdown.mat-menu-panel .mat-menu-content .mat-radio-button:hover {
    background: #191C24;
    color: #fff;
  }
  .region-data .region-data-inner .region-data-tabs {
    border-bottom: 1px solid rgba(164,163,187,.3);
  }
.custom-sidebar{
  border: 1px solid #4A4F57 !important;
}
  .ag-vul-tooltip-score{
    > .ag-dialog{
      color: $whitecolor;
      background-color: $darkpopup  ;
    }
  }
  .ag-vul-tooltip {
    > .ag-dialog{
      color: $whitecolor;
      background-color: $darkpopup;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 12px 8px;
    }
  }
  .apexcharts-datalabel-label {
    fill: $whitecolor;
  }
  .apexcharts-datalabel-value {
    fill: $whitecolor;
  }
  .ag-custom-tooltip{
    background: $darkblackcolor;
    color: $whitecolor ;
   
  }
  .asset-vul-tooltip{
    > .ag-dialog{
      background: #262830;
    border: 1px solid #4A4F57;
      color: $whitecolor;
    }
  }
  .ag-theme-material input[class^=ag-][type=text]::placeholder{
    color: $beatrice;
  }
  .ag-grid-custom-font {
    .ag-tooltip {
      background:  $darkpopup; 
          color:$whitecolor ;
    }
  }  
  .mat-tooltip, .tooltip {
    background: $darkpopup !important;
    color: $whitecolor !important;
  }
  .cybertooltip-popup-section {
    background-color:$darkpopup;
    color: $whitecolor;
    border: 1px solid $dliver;
    h5 {
      border-bottom: 1px solid $obsidiantwilight;
    }
    .cyber-score-list {
      li{
        border-bottom: 1px solid $obsidiantwilight;
        color:$grayundertone;
        .cyber-status {
          .cyber-paramer-value{
            color: $grayundertone;
          }
      }
      .cyber-arrow {
        &.arrow-down-evt {
          .mat-icon {
          color: #FD6165;
          }
      }

      &.arrow-up-evt {
          .mat-icon{
            color: #11BB57;
          }
      }
      }
      }
    }
  }
  .vulnerab_label{
    &.new-critical{
      color: #B2102F;
      border: 0.2px solid #B2102F;
      &::after {
        background: #B2102F;
    }
    }
    &.new-medium {
      color: #E85800;
      border: 0.2px solid #E85800;
      &::after {
        background: #E85800;
    }
    }
    &.new-high {
      color: #FF0001;
      border: 0.2px solid #FF0001;
      &::after {
        background: #FF0001;
    }
    }
    &.new-low {
      border: 0.2px solid #F2BF00;
      color: #F2BF00;
      &::after {
        background: #F2BF00;
    }
    }
  }
  .region-section { 
    .region-left .region-box{
      border-bottom: 1px solid $dliver;
    }
    .region-right .region-list .region-list-item{
      border: 1px solid $dliver;
      border-right: 0px;
      &:last-child {
        border: 1px solid $dliver;
    }
    }
    .region-table tbody tr td .label-path{
      border-bottom : 1px solid $dliver;
    }
  }
  .region-table tbody tr{
    &:nth-child(even) {
      background: $darkBlue;
  }
  &:nth-child(odd) {
    background: none;
}
}
.region-table tbody tr td .add-data {
  background: $darkblackcolor;
  color: $whitecolor;
}
  .region-table{
    thead {
      tr{
        th{
          background: $darkBlue;
          color: $whitecolor;
           border-right: 1px solid $charcoalGray;
           border-bottom: 1px solid $charcoalGray;
        }
      }
   
    }
  }
  .super-permission{
    border-bottom: 1px solid $dliver;
    border-right: 1px solid $dliver;
  }
  .role-management-page {
    .role-search .role-search-header{
      border-bottom: 1px solid $dliver;
    }
    .role-data .role-data-header{
      border-bottom: 1px solid $dliver;
    }
  }
  .user_page .user-mgnt-header{
    border-bottom: 1px solid $dliver;
  }
  .vpn-management-page .vpn-main-section  {
    .vpn-list{
      .vpn-search-header{
        border-bottom: 1px solid $dliver;
      }
    }
    .vpn-data{
      .vpn-data-header{
        border-bottom: 1px solid $dliver;
      }
    }
  }
  .cyber-management-page .cyber-main-section {
    .vpn-list .vpn-search-header{
      border-bottom: 1px solid $dliver;
    }
    .vpn-data-section .vpn-data .vpn-data-header{
      border-bottom: 1px solid $dliver;
    }
  }
   
  .si-content {
    .scan-details-header-section{
      border-bottom: 1px solid $dliver;
    }
  }
  .cred-management-page {
    .cred-data {
      .cred-data-header{
        border-bottom: 1px solid $dliver;
      }
    }
    .role-search{
      .role-search-header{
        border-bottom: 1px solid $dliver;
      }
    }
  }
  
  .whitelisted-history .whitelisted-history-section .whitelisted-history-info::before{
    background: $dliver;
  }
  .whitelist-parameter .parameter-info-container .parameter-info:after{
    background: $dliver;
  }
  .parameter-chart{
    border-bottom: 1px solid $dliver;
  }
  .common-bottom-Bdr{
    border-bottom: 1px solid $dliver;
  }
  .whitelist_page .whitelist-page-header{
    @extend .common-bottom-Bdr;
  }
  .device_page .device-mgnt-header{
    border-bottom: 1px solid $dliver;
  }
  .benchmark-mapping{
    border-bottom: 1px solid $dliver;
  }
  .scan-item-details .details-section .details-info {
    .details-info-content{
      .details-info-values{
        border-bottom: 1px solid $dliver;
      }
    }
    .details-info-benchmark{
      border-bottom: 1px solid $dliver;
    }
  }
  .right-side-header {
    .selected-list-header{
      border-bottom: 1px solid $dliver;
    }
    &.border{
      border-bottom: 1px solid $dliver;
    }
  }
  .white-box, .white-box-svg{
    border: 1px solid $darkThemeBdr;
  }
  .scan-details-container .left-side-container .scan-details-header-section {
    border-bottom: 1px solid $dliver;
  }
  .myscan_page .myscan-mgnt-header{
    border-bottom: 1px solid $dliver;
  }
  .ag-paging-panel{
    border-top-color: $dliver;
  }
  .toast-container{
    background-color: $darkblackcolor;
  }
  .app-container{
    background: $darkThemeBg;
  }
  .toast-message{
    color: $whitecolor;
  }
  .netsec-modelbox {
    p{
      color: $whitecolor;
    }
    .sub-text{
      color: $whitecolor;
    }
   }
   .cloud-scan-container .cloud-scan-header {
    border-bottom: 2px solid $dliver;
  }
  .cloud-scan-container .cloud-scan-header .header-left {
    p{
      color: $whitecolor;
    }
    .cloud-scan-content .radio-btn-secction {
      .report-label{
        .radio-title{
          color: $darkcharcoal;
        }
      }
      .info-fields .setup-info.setup-list{
        color: #fff;
      }
    }
   }
  .field_length{
    color: $whitecolor;
   }
  .mat-form-field-appearance-outline.border-field .mat-form-field-outline-start, .mat-form-field-appearance-outline.border-field .mat-form-field-outline-end {
    border-color: $whitecolor;
  }
 
  app-active-session {
    .modal-container {
      .header{
        color: $whitecolor;
        background: none;
      }
      .content{
        color: $whitecolor;
      }
     }
  }
   app-session-expired-modal{
    @extend app-active-session;
   }
  .mat-form-field-appearance-outline.border-field .mat-form-field-outline-thick .mat-form-field-outline-start, .mat-form-field-appearance-outline.border-field .mat-form-field-outline-thick .mat-form-field-outline-end {
    border-color: $whitecolor; 
  }
  .mat-form-field-appearance-outline.border-field .mat-form-field-outline-gap { 
    border-color: $whitecolor; 
   }
  .create-role-modalbox .sub-text{
    color: $whitecolor;
  }
  .theme-bg{
    background: $darkThemeBg;
  }
  app-vpn{
    @extend .theme-bg;
  }
  .create-role-modalbox {
    background: $blackGrey; 
  }
  .dark-image-sec{
    img{
      display: none;
      &.dark{
        display: inline;
      }
    }
  }
  app-new-scan{
    .new-scan{
      background: url(assets/images/upload-conceptbg-dark.png) center no-repeat;
    }
  }
  
  .newscan-info .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-start, .newscan-info .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-end,
.newscan-info .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border-color: $whitecolor ;
}
.newscan-info .mat-form-field-appearance-outline .mat-form-field-outline-end {
   
    border:1px solid $whitecolor !important;
    border-left-style: none!important;
}
.newscan-info .mat-form-field-appearance-outline .mat-form-field-outline-gap, .newscan-info .mat-form-field-appearance-outline:hover .mat-form-field-outline-gap {
    border: 1px solid $whitecolor !important;
    border-left-style: none !important;
    border-right-style: none !important;
} 
.newscan-info  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
    border-top-color: rgba(255,255,255,0) !important;
}
.newscan-info .mat-form-field-appearance-outline .mat-form-field-outline-start {
   
    border:1px solid $whitecolor !important;
    border-right-style: none!important;
}
.newscan-info .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-end {
   
    border:2px solid $whitecolor !important;
    border-left-style: none!important;
}
.newscan-info .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-start {
   
    border:2px solid $whitecolor !important;
    border-right-style: none!important;
}
.newscan-info .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border: 2px solid $whitecolor !important;
    border-left-style: none !important;
    border-right-style: none !important;
    border-top-style: none !important;
}
.uploaded-files-section .uploaded-files{
  color: $whitecolor;
}
  .page_header .page_header_right {
    .btn.btn-plain{
      &:hover {
        color:$whitecolor
      }
    }
  }
  .upload-tree .mat-icon-rtl-mirror{
    &.tringle {
      border-top: 5px solid $whitecolor;
    }
    &.invert-tringle {
        border-left: 5px solid $whitecolor;
        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
    }
  }
  .upload-tree {
    label{
      color: $whitecolor; 
    }
   
  }
  :host ::ng-deep {
    .all-selection{
      color:  $whitecolor;
      
    }
    
  }
  .mat-tree{
    background: $darkThemebgColor;
  }
  .filter-section{
    input.mat-input-element{
      color: $whitecolor !important;
      caret-color: $darkblackcolor !important;
      
    }
    ::placeholder {
      color: $whitecolor;
      opacity: 1; /* Firefox */
    }
    .search-bar .mat-form-field-prefix .mat-icon{
      color: $whitecolor !important;
    }
  }
  .filter-header {
    .header-tool .header-tool-content .header-text{
      color: $whitecolor;
    }
    .mat-toolbar{
      background: $darkThemebgColor !important;
    }
  }
  
  .details-popup .details-right .scan-item-details .details-info .details-info-content .details-info-list-item .benchmark-list .list-header-section {
    .list-item{
      color: $whitecolor;
    }
    .expand-btn{
      color: $whitecolor;
    }
  }
  .benchmark-list {
    background-color: $graybackground;
  
    .list-header-section {
      .list-item {
        color: $whitecolor;
      }
      .expand-btn {
        color: #79799A;
      }
    }
  
    .list-item-discription {
      color: $whitecolor;
    }
  }
  .selected-list  .mat-form-field-appearance-fill .mat-form-field-flex{
    background: $darkblackcolor;
  }
  .disable-selected-list-item:nth-child(odd) {
    background-color: $darkThemeBdr; 
  }

  .disable-selected-list-item {

    &.list-search-box {
      background-color: $darkThemebgColor;
      border-top: 1px solid rgba(164,163,187,.3) !important;
    }
    .selected-list-item-left{
      color: $beatrice;
      &:hover {
        color: #696595;
      
      }
    }
  }
  
    .mat-form-field-appearance-fill .mat-form-field-flex,
    .mat-form-field-appearance-outline .mat-form-field-outline {
      border-radius: 4px;
      background: $red;
      height: 44px;
      height: 44.5px;
    }
    .telent-selected-item {
      border-bottom: 1px solid rgba(164,163,187,.3);
      border-top: 1px solid rgba(164,163,187,.3);
      p {
      color: $whitecolor;
    }
  }
    .invite-modalbox {
      h3 {
        color: $whitecolor;
      }
  
      .sub-text {
        color: $whitecolor;
      }
    }
    .inviteuser-name{
      color:$whitecolor;
    }
    .inviteuser-dorpdown{
      color:$whitecolor;
    }
    .inviteuser-id :after{
      color: $charcoalGray;
    }
    .custom-sidebar {
      background-color: $whitecolor;
    }

  .mat-menu-panel.benchmark-info::before{
    background-color: #262830;
    border-top: 1px solid #4A4F57;
    border-left: 1px solid #4A4F57;
  }
  .details-popup .details-right .scan-item-details .details-info .details-info-content .details-info-values .details-info-values-graf {
    .graf-value{
      color: $whitecolor;
    }
    .graf-title{
      color: $whitecolor;
    }
  }
  .details-popup .details-right .scan-item-details .details-info .details-info-content 
  .details-info-values .details-info-values-legends .legends li label{
    color: $whitecolor;
  }
  .side-nav-ul li{
    &:hover {
      background: $darkblackcolor;
    } 
  }
  .dark-theme .mat-dialog-container{
    background: $darkThemebgColor;
  }
  .search-container .search-results .search-results-content .search-tab-results .netsec_table1 .mat-row{
    &:nth-child(odd) {
      background: $darkblackcolor;  
    } 
    &:hover {
      background: $darkThemebgColor;
        cursor: pointer;
    }
  }
  .mat-table thead, .mat-table tbody, .mat-table tfoot,  mat-header-row,  mat-row,  mat-footer-row, 
  [mat-header-row],  [mat-row],  [mat-footer-row],  .mat-table-sticky{
    background: $darkThemebgColor;
  }
   .mat-dialog-container{
    background: $blackGrey;
    border: 1px solid $charcoalGray;
  }
  .search-results-btn{
    border: 1px solid $whitecolor; 
    color: $whitecolor;
    &.active {
      background: $darkblackcolor;
      border: 1px solid $aquamarine;
      color: $whitecolor;
    }
  }
.side-nav-menu{
  background-color: $darkThemebgColor !important;
}
// .arrow-menu{
//   border-color: $steelgray !important;
// }

.side-nav-ul li a.active, .side-nav-ul li a:hover { 
  color: $whitecolor;
  .arrow-menu{
    border-color: $whitecolor;
  }
}
.skeleton-loader {
  background-color: $darkThemeBdr !important;
}
.skeleton-loader.progress:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.14), rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0)) !important;
}
  .mat-raised-button {
    background: #131517;
    border-radius: 8px;
  }

  .region-sort {
    background-color: $charcoalsteel !important;
    color: $ghostwhite !important;
    border: none !important;
    border-radius: 4px !important;
  }

  .control-panel-col-header {
    border-bottom: 1px solid $obsidiantwilight !important;
  }

  .nodata-text {
    color: $grayundertone !important;
  }

  .mat-tab-header {
    border-bottom: 1px solid $obsidiantwilight !important;
    box-shadow: none;
  }

  .ag-header-cell {
    color: $beatrice !important;
  }

  .ag-icon-filter {
    color: $steelgray !important;
    line-height: 22px;
    padding-left: 5px;
  }

  .spark-sec-tittle {
    color: $softwhite;
    border-bottom: none;
  }

  .cyber-reilience.control-panel-col-header {
    border-bottom: none;
  }

  .commonvulnarabilites-tab, .mat-tab-header, .mat-tab-label-container, .mat-tab-list, .mat-tab-labels, .mat-tab-label , .mat-tab-label-active, .mat-tab-label-content {
    color: $softwhite;
  }

  .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: $softwhite;
  }

  .mat-icon {
    fill: $steelgray;
  }

  .btn-custom mat-icon {
    color: $brightcobalt;
  }

  .download-icon {
    color: $whitecolor;
  }

  .btn-primary {
    background: mat.get-color-from-palette($theme-primary);
    color: $whitecolor;
    border: 1px solid mat.get-color-from-palette($theme-primary);

    &:hover {
      background: mat.get-color-from-palette($theme-primary, 700); // Use a darker shade for hover
      transition: all 0.3s ease !important;
      color: $whitecolor;
      border-color: mat.get-color-from-palette($theme-primary, 700);
    }
  }

  .header {
    background: $darkThemebgColor;
    box-shadow: none;
  }

  .control-panel {
    background: $darkThemeBg;
  }

  .spark-whitebox {
    background: $darkThemebgColor;
    border: 1px solid $darkThemeBdr;
  }

  .header-sparklogo {
    border-right: 1px solid $dliver;
  }

  .ag-root-wrapper,
  .ag-sticky-top,
  .ag-sticky-bottom,
  .ag-dnd-ghost {
    background: $darkThemebgColor;
  }

  .ag-header,
  .ag-advanced-filter-header {
    background: $darkThemebgColor;
  }

  .border-bottom {
    border-bottom: 1px solid $transparentColor !important;
  }

  .speedo-mid-c {
    background-color: $darkblackcolor;

    &::before {
      color: $softwhite;
    }

    &::after {
      color: $softwhite;
    }
  }

  .vertical-line {
    opacity: 1;
    background-color: $softwhite;
  }

  .line-marker {
    color: $softwhite;
  }

  .spark-label {
    color: $softwhite;
  }

  .speedo-mid-c>div {
    background: $dspeedoCircle;
    border-color: $dspeedoCircle;
    box-shadow: 1px 0px 13px inset #000;
  }

  .cyber-reilience {
    .control-panel-col-header {
      border: none;
    }
  }

  .download-icon {
    fill: $whitecolor;
    color: $whitecolor;
  }

  .btn-custom mat-icon {
    color: $whitecolor;
  }

  .spark-region-info {
    .region-data {
      background: $dspeedoCircle;
      border: none;
    }
  }

  .region-data-tab {
    color: $whitecolor;
  }

  .control-panel-col-header {
    border-bottom: 1px solid $darkThemebgColor;
  }

  .ag-row {
    border: none;

    &:nth-child(odd) {
      background-color: $dspeedoCircle;
    }

    &:nth-child(even) {
      background-color: $darkThemebgColor;
    }

    &.ag-row-hover {
      background-color: $darkThemebgColor;
    }
  }

  --ag-modal-overlay-background-color: #191C24 !important;

  .ag-theme-material {
    --ag-row-hover-color: #191C24;
    --ag-secondary-foreground-color: #fff;
    --ag-background-color: #191C24;
    --ag-foreground-color: #fff;
    --ag-disabled-foreground-color: #bdbdda;
  }

  .ag-overlay-loading-wrapper {
    background-color: $darkThemebgColor;
  }

  .ag-grid-loader-bg {
    .ag-overlay-panel {
      background-color: $darkThemebgColor;
    }
  }
    .mat-menu-panel {
      &.white-menu {
        background-color: $dspeedoCircle;
  box-shadow: 0px 2px 2px  rgba(0, 0, 0, 0.7);
        .mat-menu-item {
          color: #8784AA;
  
          &:hover {
            color: $whitecolor;
            background: $darkThemebgColor;
          }
  
          &.active {
            color: $whitecolor;
            background: $darkThemebgColor;
          }
        }
      }
    }

  :host ::ng-deep .mat-menu-panel .mat-menu-item {
    color: rgb(255 255 255 / 50%);
  }

  :host ::ng-deep .mat-menu-panel .mat-menu-item:hover {
    color: $aquamarine;
  }

  .control-panel-row {
    .control-panel-item {
      .btn-custom {
        background-color: $charcoalsteel;
        border: 1px solid $charcoalsteel;
        color: $ghostwhite;
      }
    }
  }

  .sort-title {
    color: $ghostwhite;
    font-weight: 600;
    font-size: 10px;
  }

  .menu-icon {
    color: $steelgray !important;
  }

  .ag-header-group-cell:not(.ag-column-resizing)+.ag-header-group-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover,
  .ag-header-group-cell:not(.ag-column-resizing)+.ag-header-group-cell:not(.ag-column-hover).ag-column-resizing,
  .ag-header-cell:not(.ag-column-resizing)+.ag-header-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover,
  .ag-header-cell:not(.ag-column-resizing)+.ag-header-cell:not(.ag-column-hover).ag-column-resizing,
  .ag-header-group-cell:first-of-type:not(.ag-header-cell-moving):hover,
  .ag-header-group-cell:first-of-type.ag-column-resizing,
  .ag-header-cell:not(.ag-column-hover):first-of-type:not(.ag-header-cell-moving):hover,
  .ag-header-cell:not(.ag-column-hover):first-of-type.ag-column-resizing {
    background-color: $darkThemebgColor;
  }

  .ag-icon-filter {
    color: $beatrice;
  }

  .ag-grid-custom-font .ag-header-cell {
    color: $beatrice;
  }

  .ag-header-cell {
    color: $beatrice
  }

  .ag-cell {
    color: $beatrice
  }

  .ag-icon-asc {
    color: $beatrice
  }

  .devices-item-right {
    color: $whitecolor;
  }

  .parameter-value {
    color: $whitecolor;
  }

  .new_vul_tabel .ag-cell {
    color: $whitecolor;
  }

  .asset-btns {
    img {
      filter:brightness(0) invert(1);
    }
  }

  .region_name {
    color: $whitecolor;
  }

  .page_content {
    background: $darkThemebgColor;
  }

  input.mat-input-element {
    color: $whitecolor !important;
  }

  // .ag-custom-tooltip {
  //   color: $darkThemebgColor;
  // }

  .cve-header-details-section {
    label {
      color: $whitecolor;
    }
  }

  .mat-cve-tabs {
    .mat-tab-header {
      background: $darkblackcolor;
      border: 1px solid $charcoalGray !important;
      border-radius: 8px 8px 0 0 !important;

    }
  }

  .cve-summaryinfo {
    p {
      color: $whitecolor ;
    }
  }

  .summaryitem-value {
    color: $darkcharcoal;
  }

  .authoruty-value {
    color: $whitecolor;
  }

  .vector-list.nist-list {
    &.mat-menu-panel {
      background: $dspeedoCircle;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.7);
    }

    ul {
      li {
        color: $whitecolor;
      }
    }
  }

  .cve-epss-title {
    color: $whitecolor;
  }

  .cve-reference-content {
    background: $darkpopup;
  }

  .cve-enumerationitem {
    .name-item {
      color: $whitecolor;
    }
  }

  .cve-accordion-title {
    &.mat-expansion-panel-header {
      .mat-expansion-indicator {
        color: $whitecolor;

        &:after {
          color: $whitecolor;
        }
      }
    }
  }
  .mat-drawer-container{
    background-color: rgba(0,0,0,.5);
  }

  .new-scan-left {
    h4 {
      color: $softwhite !important;
    }

    p {
      color: $beatrice !important;
    }
  }
  .devicescan-list{
    &::after {
        
      background: $charcoalGray !important;
    }
    &::before {
      background: $darkThemeBg !important;
      color: $beatrice !important ;
      border: 1px solid $charcoalGray !important;
    }

  }
  .scan-type-right{
    p{
      color: $beatrice !important;
    }
  }
  .scan-radio-button{
    background: $darkThemebgColor !important;
    border: 1px solid #2C303C !important;
  }
    .newscan-tabgroup {
      .mat-tab-header {
        background: transparent;
        border-bottom: 1px solid $charcoalGray !important;
  
        .mat-tab-label-container {
          .mat-tab-list {
            .mat-tab-labels {
              .mat-tab-label {
                border-color: $charcoalGray;
  
                .mat-tab-label-content {
                  color: $beatrice !important;
                }
  
                &.mat-tab-label-active {
                  border-bottom: 3px solid $charcoalGray;
                  color:  $beatrice;
  
                  .mat-tab-label-content {
                    color: $softwhite !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  
    .new-scan-box {
      color: $softwhite;
    }
  
    .new-scan-boxtitle {
      color: $softwhite !important;
    }
  
    .input-box {
      border: 1px solid $charcoalGray;
      background: transparent;
      color: $softwhite;
      font-size: 14px;
      display: block;  
      &:-webkit-autofill,
      &:-internal-autofill-selected {
        color: $softwhite !important;
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        -webkit-text-fill-color: #fff;
      }
  
      &::placeholder {
        color: $softwhite;
      }
  
      &:-internal-autofill-selected {
        color: $softwhite;
        -webkit-box-shadow: 0 0 0px 1000px $softwhite inset;
      }
    }
  
    .switch-label {
      color: $beatrice !important;
    }
  
    p {
      color: $softwhite;
    }
  
    .mat-radio-label {
      color: $softwhite !important;
    }
  
    .mat-form-field-appearance-outline .mat-form-field-outline {
      border-radius: 4px !important;
      // border: 1px solid $gainsboro !important;
    }
  
    .mat-sidenav {
      background-color: $darkThemebgColor !important;
      box-shadow: 0 0 0 3px $darkThemebgColor !important;
    }
  
    .mat-card {
      background-color: $darkThemebgColor !important;
  
    }
  
    .shedular-check .mat-checkbox-layou .mat-checkbox-label,
    .mat-checkbox-label {
      color: $beatrice !important;
    }
  
    ul {
      li {
        color: $lavendergray !important;
      }
    }
  
    input.mat-input-element {
      color: $softwhite !important;
      caret-color: $softwhite !important;
    }
  
    .left-side-container {
      background: #191C24;
    }
  
    .switch-label-right {
      color: $softwhite !important;
    }
    .schedule-whitebox .disabled .mat-form-field-appearance-outline .mat-form-field-outline {
      background: $charcoalGray !important;
    }
    .white-box {
      background: $darkThemebgColor;
    }
    
    .benchmark-list,.mat-menu-panel.benchmark-info {
      background: #262830 !important;
      border: 1px solid #4A4F57 !important;
    }
    
    .benchmark-mapping, .benchmark-text, .sort, .text, .selec_val {
      color: $softwhite !important;
    }
    
    .custom-sidebar, .disable-selected-list-item {
      background: $darkpopup;
      border: 1px solis $dliver;
    }
    .disable-selected-list-item.list-search-box{
      background: $dliver;
      border-bottom: none !important;
    }
    .mat-icon{
      color:$steelgray;
    }
    .mat-form-field-appearance-fill .mat-form-field-flex{
      border-radius: 4px !important;
    background: $dliver !important;
    }
    .scan-item-details,.disable-telent,.telent-content{
      background: $darkThemebgColor !important;
      border-top: 1px solid $charcoalGray;
    }
     .log-item.passed-item{
      box-shadow:0 1px 0 0 $charcoalGray !important;
     }
     .log-item.failed-item{
      box-shadow:0 1px 0 0 $charcoalGray !important;
     }
     .cve-search{
      background-color: $darkThemebgColor !important;
     }
     .cve-main-section, .white-box-header{
      border: 1px solid $charcoalGray !important;
      border-radius: 8px;
     }
     .cve-tab-details-section, .mat-tab-header{
      background: $darkThemebgColor ;
     }
     .cve-assets-details-header, .cve-epss, .cve-vuldetails, .cve-summarybox{
      border-bottom: 1px solid $charcoalGray !important;
     }

    .empty-text{
      color: $whitecolor;
    }
    .notification-main .notification-header .header-1 p{
      color: $whitecolor; 
    }
    .notification-main .notification-filter .notification-filter-left {
      .btn{
        &:hover {
          border: 1px solid $whitecolor;
          color: $whitecolor;
       }
        &.active {
            color: $whitecolor;
        }
      }
    }
    .notification-main .notification-list .notification-container {
      .notification-item{
        background-color: $darkblackcolor;
        border-bottom: 1px solid #4a4f57;
        
        .notification-item-text span{
          color: $beatrice;
        }
        &.unread{
          background: $darkThemebgColor;
          border-color: $darkThemebgColor;
          border-bottom: 1px solid #4a4f57; 
          color: $whitecolor;
          .notification-item-text span{
            color: $beatrice;
          }
        }
        &:hover{
          background: $darkThemebgColor;
          border-color: $darkThemebgColor; 
          color: $whitecolor;
        }
      }
    }
    .notification-main .notification-filter .notification-filter-right .markall-btn{
      :hover{
       color: $whitecolor;
      }
    }  
    .profiletab-box {
      background: $darkblackcolor;
      .profiletab-itemlable{
        color: $whitecolor;
        p{
          color: $whitecolor;
        }
      }
      .profiletab-itemvalue {
        color: $whitecolor;
        p{
          color: $whitecolor;
        }
      }
    }  
    .profile_details{
      background: $darkblackcolor;
    }
    .profiletab-itemlable {
      color: $whitecolor;
    }
    .profiletab-itemvalue{
      color: $whitecolor;
      p{
        color: $whitecolor;
      }
    }
    .profile_page .sso-info .sso-switch{
      background: $darkblackcolor;
    }
    .subscription-graybox{
      background: $darkblackcolor;
    }
    .plan-infolist{
      li {
        color: $whitecolor;
      }
    }
    .subscription-infogreybox{
      background: $darkblackcolor;
      box-shadow: 0px -1px 0px 0px $darkblackcolor;
      color: $whitecolor;
    }
    .regex-label{
      color: $whitecolor;
    }  
        .new-scan-box {
          color: $softwhite;
    
          .header-box p {
            color: $softwhite;
    
            .new-scan-boxtitle {
              color: $softwhite;
              border-bottom: 1px solid $charcoalGray;
            }
    
            .new-scan-boxsubtitle p {
              color: $softwhite;
            }
          }
        }
    
        .device_page {
          .device-mgnt-header {
            .right-mgt-head p {
              color: $softwhite;
            }
            .dropdown-button{
              border: 2px solid #fff;
              color: #fff;
              .sort-icon{
                color: #fff;
              }
            }
          }
        }
    
        .device-header {
          color: $softwhite;
        }
    
        .control-panel-col-header h3 {
          color: $softwhite;
        }
    
        .score-details {
          color: $softwhite;
    
          .score-with-direction {
            color: $softwhite;
    
            &.high-val {
              color: $coralRed ;
            }
    
            .low-val {
              color: $seaGreen;
            }
          }
        }
    
        .config-label {
          color: $beatrice;
        }
    
        .value-with-direction {
          color: $lavendergray;
    
          .mat-icon {
            color: $steelgray;
          }
    
          & .high-val {
            color: $coralRed ;
          }
    
          .low-val {
            color: $seaGreen ;
          }
        }
    
        .comparator-drop-section {
          background-color: $darkThemebgColor;
        }
    
        .monaco-editor,
        .monaco-editor-background,
        .monaco-editor .inputarea.ime-input {
          background-color: $darkThemebgColor;
        }
    
        .margin-view-overlays {
          background-color: $darkThemebgColor;
          color: $softwhite;
        }
    
        .mtk1 {
          color: $softwhite;
        }
    
        .card-title {
          color: $softwhite;
        }
    
        .parameter-label {
          color: $softwhite;
        }
    
        .profile-disc-label {
          color: $retroblue;
        }
    
        .profile-disc-text {
          color: $softwhite;
        }
    
        .person-details-header p {
          color: $softwhite;
        }
 .notification-icon{
    img{
      display: none;
      &.dark{
        display: inline;
      }
    }
 }  
 .invite-modalbox h3{
  color: $softwhite 
 }

 .cred-label{

  background: $darkThemebgColor;
  &:nth-child(even) {
    background: $dspeedoCircle;
}

&:hover {
  background: $darkThemebgColor;
}

 }

 .role-search .role-search-header{
  border-bottom: 1px solid $dliver;
 }

 .cred-main-section {
  .white-box{
    border: 1px solid $dliver;
  }
 } 

 .cred-data .cred-data-header{
  border-bottom: 1px solid $dliver;
 }

 .creden_management .disabled .mat-form-field-appearance-outline .mat-form-field-outline {
  background: $darkThemeBg !important;
}

.creden_management   .cred-form .input-box-container input.mat-input-element {
color: $lavendergray !important;
}
.cred-management-page .role-search .cred-search-list .cred-label:nth-child(even){
background: $dspeedoCircle;
}

.cred-management-page .role-search .cred-search-list .cred-label:hover{
background: $darkThemebgColor;
}



.input-box-container .exec-field p {
color: $softwhite;
}



.creden_management .cred-data  .cred-form  .label-container  .radio-btn-section  p {
color: $softwhite;
}

.mat-chip.mat-standard-chip{
background-color: $charcoalsteel;
}

.netsec-modelbox .modelbox-body  p {
color: $softwhite;
}
.ag-grid-tabel{
  .ag-pinned-left-header{
      border: none;
  }
  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell){
      border: none;
  }
  .ag-overlay-panel{
      .ag-overlay-loading-wrapper{
          background: none;
      }
  }
  .ag-overlay-panel{
      background: $darkThemeBdr;
  }
}
.control-panel .control-panel-row .shedule-scan-item .schedule-scan-box { 
  background: $darkblackcolor;
 }
 .control-panel .control-panel-row .shedule-scan-item .schedule-scan-box .schedule-scan-col-header .asset-tittle { 
  color: $whitecolor;
}
.spark_table_schedule  .mat-header-cell{
   color:none;
}
.spark_table_schedule   .mat-row  .mat-cell{
  color:$lavendergray;
}
.spark_table_schedule .mat-row{
  &:nth-child(odd) {
    background:$dspeedoCircle; 
  } 
  &:hover {
      background: $charcoalsteel; 
      cursor: pointer;
  }
}
.control-panel .control-panel-row .shedule-scan-item .schedule-scan-box.Assets-detail-table .devices-data .devices-list .devices_name {
 color: none;
}
.control-panel .control-panel-row .shedule-scan-item .schedule-scan-box .Assets-detail-table .devices-data p {
  color: $lavendergray;
}
.font{
  color:$beatrice;
}
.cyber-management-page .cyber-main-section .vpn-list .vpn-search-list .vpn-list-item{
  &:nth-child(even) {
    background: $charcoalsteel;
    }
    &:hover {
      background: $charcoalsteel;
      cursor: pointer;
    }
  }

  .cyber-management-page .cyber-main-section .vpn-list .vpn-search-list .vpn-list-item .vpn-tunnelname .dark-mode-visible {
    display: block;
   }
   .dark-mode-visible{
    display: block;
   }
   .dark-mode-hidden{
    display: none;
   }
   .cyber-management-page .cyber-main-section .vpn-list .vpn-search-list .vpn-list-item .vpn-tunnelname .dark-mode-hidden  {
     display: none;
    }

    .cyber-management-page .cyber-main-section .vpn-data-section .vpn-data .vpn-data-inner .right-inner .item .dark-mode-visible{
      display: block;
     }
    
     .cyber-management-page .cyber-main-section .vpn-data-section .vpn-data .vpn-data-inner .right-inner .item .dark-mode-hidden{
      display: none;
     }

     .edit_cloud_scan .edit_cloud_scan_header .header1 p {
      color: $whitecolor;
      }
      .cloud-scan-container .cloud-scan-header .header-left p{
        color:$whitecolor; 
       }

       .cloud-scan-container .cloud-scan-content .btns-section .white-box {
        background: $whitecolor ;
       }
       .cloud-scan-container .cloud-scan-content .sub-text {
        color: $whitecolor;
       }
       .cloud-scan-container .cloud-scan-content .radio-btn-secction .report-label .radio-title{
        color: $whitecolor;
       }
       .cloud-scan-container .cloud-scan-content .info-fields .setup-info .setup-list{
        color: $manatee;
       }
       .netsec-modelbox p {
        color: $whitecolor;
      }
      .netsec-modelbox .sub-text {
        color: $whitecolor;
      }


.account_dropdown {
  &.mat-menu-panel {
       background: $dspeedoCircle;
       box-shadow: 0 2px 2px rgba(0, 0, 0, 0.7);
  }
}
.account_dropdown.mat-menu-panel .mat-menu-content ul .mat-menu-item button {
  color: $whitecolor;
}
.mail-text {
  color: $whitecolor;
}
.mat-menu-item button {
  color: $whitecolor;
}
.account_dropdown {
  &.mat-menu-panel {
    .mat-menu-content {
      ul {
        .mat-menu-item {
          color: $darkgray;

          &:hover {
            background: $blackcolor;
          }
        }
      }
    }
  }
}
.tool-sublist{
 background: $dspeedoCircle;
}
.accordion-header {
    .accordian-label {
     color:#A39FCB;
  }
  .accordion-arrow {
      color: #A39FCB;
  }
}
.add-level {
  color:  $whitecolor;
  background: transparent;
  background-color:$darkThemebgColor ;
}
.org-level-lables{
  color: $whitecolor;
  background-color: $darkThemebgColor;
}
.filter-section {
  .search-bar {
    background-color: $darkThemebgColor;
    border-radius: 4px solid $darkgray;
}
}
.no-data-section {
  height: calc(100vh - 61px);
  align-items: center;
  justify-content: center;

  .no-data-info {
      h3 {
          margin: 0px;
          padding: 0px;
          color: $whitecolor;
          font-size: 20px;
          line-height: 25px;
          padding-bottom: 10px;
          font-weight: 700;
          margin-top: 40px;
      }

      p {
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          padding-bottom: 30px;
          max-width: 416px;
      }

      .btn {
          border-radius: 4px;
          line-height: 37px !important;
          height: 37px;
          padding: 0px 45px;
          font-weight: 600;
      }
  }
}
.password-field:-webkit-autofill {
  -webkit-text-fill-color: $whitecolor;
}
.mat-error{
  color:$red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red ;
}
.mat-form-field-invalid .mat-input-element {
  color:$whitecolor;
  caret-color: $whitecolor;
}
.custom-field:-webkit-autofill {
  -webkit-text-fill-color: $whitecolor;
}
.mat-input-element {
  caret-color: $whitecolor;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $aquamarine;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle{
  background-color: $aquamarine;
}
.complaince-header {
  .header {
    p {
      color: $whitecolor;
    }
  }
}

.mat-checkbox-checked .mat-checkbox-background {
  background-color: $aquamarine !important; /* Custom color for checked state */
}

 .mat-checkbox-checkmark {
  color: $whitecolor !important; /* Checkmark color */
}

 .mat-checkbox-unchecked {
  border-color: $whitecolor !important; /* Unchecked state border color */
}

 .mat-checkbox-label {
  color: $whitecolor !important; /* Label text color */
}
 .mat-radio-button.mat-accent .mat-radio-outer-circle {
  border-color: $whitecolor !important; /* Border color for the radio button */
}

 .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $aquamarine !important; /* Custom checked border color */
}

 .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
  background-color: $aquamarine !important; /* Inner circle when checked */
}

 .mat-radio-label-content {
  color: $whitecolor !important; /* Label text color */
}
.complaince-main  {
  .complaince-header{
    .header p{
      color: $whitecolor;
    }
  }
  .complaince-body {
    p{
      color: $whitecolor;
    }
  }
}
 .mat-pseudo-checkbox {
  border-color: $whitecolor !important; /* Border color for unchecked state */
}

 .mat-pseudo-checkbox-checked {
  background-color: $aquamarine !important; /* Custom background when checked */
  border-color: $aquamarine !important;
}

 .mat-pseudo-checkbox-indeterminate {
  background-color: $aquamarine !important; /* Custom color for indeterminate state */
}

 .mat-pseudo-checkbox-checked::after,
 .mat-pseudo-checkbox-indeterminate::after {
  color: $whitecolor !important; /* Checkmark or dash color */
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled){
  color: $whitecolor;
}
.details-info{

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: $whitecolor !important;  /* Default border color */
    border-color: $whitecolor !important;
  }
  
   .mat-form-field.mat-focused .mat-form-field-outline-thick {
    color: $whitecolor !important;  /* Border color when focused */
    border-color: $whitecolor !important;
  }
  
  .mat-form-field.mat-form-field-invalid .mat-form-field-outline {
    color: $red !important;  /* Border color when invalid */
    border-color: $red !important;
  }
}
.details-right .details-info .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  color: $whitecolor !important;
}
.whitelisted-history .whitelisted-history-section .whitelisted-history-info .whitelisted-history-item .flex-data .whitelisted-history-right .history-title {
  color: $whitecolor;
 }
 .whitelisted-history .whitelisted-history-section .whitelisted-history-info .whitelisted-history-item .circle-position:before {
  background-color: $darkThemebgColor;
}
.whitelisted-history .whitelisted-history-section .whitelisted-history-info .whitelisted-history-item .circle-position:after {
  background-color: $darkThemebgColor;
}
.collapse-btn {
  color: $brightcobalt;
}
.whitelisted-history-pic{
  .time-label{
      color: $brightcobalt;
  }
}
.btn-outline { 
  color: $brightcobalt;
  border: 1px solid $brightcobalt;
  background: transparent;
  cursor: pointer;
  &:hover {
      background: $brightcobalt;
      transition: all 0.3s ease !important;
      color:$whitecolor ;
      border-color: $aquamarine;
  }
  &.secondary-outline {
      color: $lightblack;
      border: 1px solid $lightblack;
      &:hover {
          color: $whitecolor;
          background: $lightblack;
      }
  }
  &.red-outline {
      color: $tartorange;
      border: 1px solid $tartorange;
      &:hover {
          color: $whitecolor;
          background: $tartorange;
      }
  }
  &.diamond-outline {
      color: $diamondbtn;
      border: 1px solid $diamondbtn;
      &:hover {
          color: $whitecolor;
          background: $diamondbtn;
      }
  }
}
.search-term-wrapper {
  background-color: $darkThemebgColor;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background: $aquamarine;
  color: $whitecolor;
}
.no-data-section {
  .no-data-info {
     h3 {
         color: $whitecolor;
     }
     p {
         color: $whitecolor;
     }
   }
 }
 .option-card {
  background: $darkThemebgColor;
  border: 1px solid #DBDBEF;
  .download-template,
  .go-to-aws {
      color: $whitecolor;
  }
  .order-list {
     color: $whitecolor;

      b {
          color: $whitecolor;
      }
  }
  .btn-link{
     background: $aquamarine;

  } 

}
.calander-data-tabel {
  .devices-list {
      .scanName {
          color: $lightblack;
      }
  }
}
.dark_color_type{
  color:$beatrice
}
.ag-cell[col-id="hostname"] {
  color: #D7DADD !important;
}
.vultooltip-section{
  background-color: $darkThemebgColor !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 12px 8px ;
  &::after {
      border-top: 19px solid $darkThemebgColor;
  }
   div {
      color: rgb(58, 42, 218);
      span{
          color: $whitecolor;
          background-color: $darkThemebgColor;
      }
  }
}
.paramer-value{
  color:$whitecolor;
}
.rescan-modalbox {
  background: $blackGrey;
  .sub-text {
    color: $whitecolor;
  }

}
}
