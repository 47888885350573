$mobile: 320px;
$small: 500px;
$tab: 768px;
$desktop: 1024px;
$medium: 1240px;
$large: 1366px;
$retroblue: #2B73DB;
$blackcolor:#313131;
$aquamarine:#3A2ADA;
$aquamarinebackground: rgba(58, 42, 218, 0.1);
$aquamarinehover: #2718BF;
$palatinateblue:#4366E1;
$aquaobscura: #19535a;
$nightblue:#1E2036;
$darkgray:#222222;
$mutedblue : #8787AE;
$ash: #AAA7C6;
$tristesse:#110F27;
$lavendermist: #F4F4FE;
$darkgunmetal: #10111E;
$blackopacity: rgba(0, 0, 0, 0.5);
$steelblue: #80809E;
$steelgray: #989EA0;
$lavender:#F5F5FF;
$softwhite: #FFFEFF;
$lightlavender: #E9E9F8;
$borderlavender: #D6D4F4;
$coralRed : #F03C3C;
$coralRedbackground: rgba(240, 60, 60, 0.1);
$pastelred: #FD6165;
$red: #F53B3B; 
$notered: #ff0000;
$scarlet: #FF735C;
$redbackground: rgba(245, 59, 59, 0.1);
$tangerine: #E78B1E;
$diamondbtn: #CD7C03;
$tangerinebackground: rgba(231, 139, 30, 0.1);
$olivedrab: #B6B13A;
$mediumrisk: #BDB615;
$olivedrabbackground: rgba(182, 177, 58, 0.1);
$green: #00A61B;
$statusgreen:#19A71F;
$statusred:#FF5B5B;
$greenbackground: rgba(0, 166, 27, 0.1);
$pastelredhover:#c23035;
$tertiary: #B71E31;
$tartorange: #FB4B50;
$qingdynastyfire: #d92662;
$palepink: #FFECEC;
$tertiaryhover: #77121d;
$criticalindicator: #A50F15;
$highindicator: #D91C20;
$mediumindicator: #FF6600;
$lowindicator: #FFC900;
$plainIndicator: rgba(170, 167, 198, 0.26);
$mustardgreen: #04ab0b;
$junglegreen: #279c8a;
$junglegreenhover: #026e08;
$opalsilk: #9BA0B8;
$waterloo:#7D83A0;
$closebackground: #A6A5B6;
$grayundertone: #8480AD;
$lavenderlight : #EBEBF5;
$darkpopup : #262A30;
$whitecolor:#ffffff;
$whitecolor20: #ffffff33;
$whitecolor30: #ffffff4d;
$lightslategrey: #7B7B9A;
$lightshadegray: #fefefe;
$eastbay: #444067;
$blackblue: #150F49;
$sweetmintpesto: #EC8F91;
$eggwhite: #FFF1C2;
$linkwater:#C9CAD3;
$gainsboro: #D9D9D9; 
$aliceblue: #F3F3F9; 
$shadoblue : #8480AD; 
$yellowgreen: #DBD301;
$Pumpkin: #F7941F;
$darkblackcolor:#000000;
$darkblackcolor40: #00000040;
$russianblack: #787777;
$darkcharcoal: #333333;
$verdanfields: #3CCB42;
$dimgray: #666666;
$stormgray: #6D738F;
$paleLilac: #E7E6F3;
$suvagrey : #8B8B8B;
$manatee: #888994;
$dustygray: #999999;
$lightblack:#696595;
$lightblackbackground: rgba(105, 101, 149, 0.1);
$lightgrayishnavy: #70708B;
$hawkesblue : #E0E0F2;
$lightgrayish:#F4F6F8;
$ghostwhite: #F8F8FF;
$listhover: #F1EFFF;
$acapulco:#8191a1;
$neonsilver: #CCD0D3;
$gray23color:#F3931B;
$snowgray: #fbfbff;
$grayborder: #DBDBEF;
$grayborderlight: #E2E2F2;
$beatrice: #BDBDDA;
$graybackground: #F7F7F9;
$bluedepths: #2B335F;
$orange:#FFA500;
$lightorange: #F3931B;
$lightorangebackground: rgba(243, 147, 27, 0.1);
$bdrBottom: #dee2e6;
$speedoBg: #f4f4ff;
$regionBg: #F2F1FE;
$regionbdr: #D7D5EC;
$menuItemColor: #8784AA;
$menuItemColorHover: #F6F6FA;
$gcolor: #960006;
$notificationColor: #27282d;
// Dark theme colors
$transparentColor:rgba(0,0,0,0);
$darkThemebgColor: #191C24;
$darkThemeBg: #08090A;
$darkThemeBdr: #2C303C;
$brightcobalt: #7678FF;
$steelgray: #989EA0;
$softwhite: #FFFEFF;
$dliver: #4a4f57;
$dspeedoCircle: #131517;
$charcoalsteel: #31373D;
$obsidiantwilight: #33323A;
$charcoalGray: #4A4F57;
$lavendergray: #A39FCB;
$seaGreen: #30994E;
$blackGrey: #262830;
$imageversion: 1.1.2;
$Platinum: #E6E3FF;
$lightSkyBlue: rgba(30, 32, 54, 0.7);
$darkBlue: #1E2036;
$brightWhite:#FAFAFE
