/* You can add global styles to this file, and also import other style files */
@import "./variable.scss";
@import 'ngx-toastr/toastr'; 
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-material.css";
$small: 500px;
$mobile: 768px;
$ipadair: 820px;
$tab: 1024px;
$medium: 1280px;
$large: 1366px; 
.toggle-btn {
    position: relative;
    top: -1px;
    font-size: 15px;
  }
// .paramer-value{
//     color: #000;
// }
.cyber-paramer-value {
color: $menuItemColor;
font-weight: 500;
}
.ag-paging-panel  {
    >
    .ag-paging-page-size .ag-wrapper {
        // box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
        border: 1px solid $grayborderlight;
    }
}
.theme-dropdown{
    margin-top: -4px;
}
.ag-theme-materia {
    font-size: 10px;
}
.hide-table{
    visibility: hidden;
}
.ag-pinned-right-header{
    border: none;
}
.relative{
    position: relative;
}
.ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell){
    @extend .ag-pinned-right-header;
}
.ag-grid-custom-font {
    font-family: Manrope,sans-serif;
    .ag-tooltip {
        background: $whitecolor ; 
        color:$blackcolor ;
        font-size: 11px;
        margin: 0px !important;
        font-weight: 700 !important;
        line-height: 12px !important;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
        font-family: Manrope,sans-serif;
        border-radius: 4px !important;
        padding: 6px 8px !important;
    }
    .ag-header-row{
        font-size: 13px;
    }
    .ag-header-cell {
        font-weight: 600;
        padding-left: 10px;
        padding-right:10px;
        .ag-cell-label-container{
            width: auto;
            .ag-header-icon{
                padding-left: 2px;
            }
        }
    }
    .ag-cell {
        font-weight: 600;
        padding-left: 10px;
        padding-right:15px;
    }
}
.new_vul_tabel{
    &.cve-tabel {
        .ag-cell {
            color: $lightblack !important;
        }
    }
}

a {
    color: $retroblue;
}

.edge-chromium-styles-unique {
   // edge styles
}

.chrome-styles-unique {
    //chrome styles
}

.opera-styles-unique {
    //opera styles
}

.firefox-styles-unique {
    //firefox styles
}

.safari-styles-unique {
    //safari styles
}

.unknown-browser-styles-unique {
     // Unknown or not in use like IE(internet Explorer).
}



body {
    margin: 0;
    font-size: 12px;
    color: $darkblackcolor;
    background: $snowgray;
    height: 100vh;
    overflow: hidden;
    
    // .mail-text{
    //     color: $whitecolor !important
    // }

    &.overflow-hidden {
        overflow: hidden;
    }

    &.header-stick {
        padding-top: 60px;
    }

    &.edge-chromium-styles {
      //edge styles
    }

    &.chrome-styles {
        //chrome styles
    }

    &.opera-styles {
      //opera styles
    }

    &.firefox-styles {
        //firefox styles
    }

    &.safari-styles {
        // safari styles
    }

    &.unknown-browser-styles {
        // Unknown or not in use like IE(internet Explorer).
    }
}
body {
    .toast-container{
        pointer-events: all;    
     }
}
.zoom-panel{
    .mat-dialog-container{
        overflow: visible;
    }
} 

.toast-custom {
    background-color: white !important;
    color: black !important;
    border-color: #cccccc !important;
  }

  .toast-custom .custom-toast {
  z-index: 1;
  background-color: #FFFFFF;
  color: #000000;
  padding: 12px 40px 0px 20px;
  border: 1px solid #3A2ADA;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  min-width: 340px;
  max-width: 340px;
  height: 60px;
  position: fixed;
  right: 10px;
  top: 10px;
  display: flex;
  flex-direction: row;
}

.toast-custom .custom-toast img {
  margin-right: 10px;
  height: 23px;
  width: 27px;
}

.toast-custom .notification-msg {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  width: 100%;
  line-height: 25px;
  text-overflow: ellipsis;
}

.toast-custom .close-icon {
  padding: 0px !important;
  min-width: 18px !important;
  min-height: 18px !important;
  position: absolute;
  right: 10px;
  color: #A6A5B6;
}

.toast-custom .close-icon .mat-icon {
  font-size: 22px !important;
}


.mat-drawer.left-nav-section {
    transform: translate3d(0%, 0, 0) !important;
}

.mat-drawer.left-nav-section[style*="visibility: hidden"] {
    display: block !important;
    visibility: visible !important;
}

.app-container .mat-drawer.left-nav-section {
    background-color: transparent;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    box-shadow: 1px 4px 12px 0px #0000000D !important;
    border-radius: 0px 24px 24px 0px;
}

.mat-table {
    font-family: Manrope,sans-serif;
}
.mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6 {
    font-family: Manrope,sans-serif;
}
.mat-card, .mat-tab-group {
    font-family: Manrope,sans-serif;
}

.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
    padding: 0px !important;
    position: relative;
    font-size: 11px;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    padding: 0px !important;
    position: relative !IMPORTANT;
    font-size: 11px;
} 
* {
    box-sizing: border-box; 
    outline: none; 
} 
p, td {
    &:after {
        content: '';
        display: block;
    } 
}

label {
    margin-bottom: 0px !important;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0px;
}

.mat-form-field-underline {
    display: none;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline,
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: $lightgrayish;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
    color: $lightgrayish;
}
.login_form .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-start, .login_form .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-end,
.login_form .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border-color: $aquamarine !important; 
    transition: all 0.2s;
}
.login_form .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-end {
   
    border:2px solid $aquamarine !important;
    border-left-style: none!important;
}
.login_form .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-start {
   
    border:2px solid $aquamarine !important;
    border-right-style: none!important;
}

.login_form .mat-focused.mat-form-field-appearance-outline .mat-form-field-outline-gap { border-bottom: 2px solid $aquamarine !important; }
// .mat-form-field-appearance-fill .mat-form-field-flex,
// .mat-form-field-appearance-outline .mat-form-field-outline {
//     border-radius: 4px !important;
//     background: $lightgrayish !important;
//     height: 44px;
//     height: 44.5px;
// }
.login_page .mat-form-field-appearance-fill .mat-form-field-flex,
.login_page .mat-form-field-appearance-outline .mat-form-field-outline { 
    height: 48px; 
}
.region-list .mat-form-field-appearance-fill .mat-form-field-flex {
    height: 50px;
    height: 50.5px;
 }
.search-bar .mat-form-field-appearance-fill .mat-form-field-flex,
.search-bar .mat-form-field-appearance-outline .mat-form-field-outline { 
    background: transparent !important; 
    height: 30px;
    padding: 4px;
} 
.log-selection .mat-form-field-appearance-fill .mat-form-field-flex,
.log-selection .mat-form-field-appearance-outline .mat-form-field-outline {
    border-radius: 4px !important;
    background: $lightgrayish !important;
    height: 35px;
    border: 1px solid $dimgray;
}

.log-selection .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 8px 0px;
    margin-top: -4px;
}

.log-selection .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(10%);
}

.log-selection .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.log-selection .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    display: none;
}
.login_form .mat-form-field-appearance-outline .mat-form-field-outline-start, .login_form .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border: 1px solid $nightblue !important; 
} 
.login_form .mat-form-field-appearance-outline .mat-form-field-outline-start { 
    border-right-style: none !important;
}
.login_form .mat-form-field-appearance-outline .mat-form-field-outline-end { 
    border-left-style: none !important; 
}
.login_form .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
    border-top-color: rgba(0,0,0,0) !important;
}
.login_form .mat-form-field-appearance-outline .mat-form-field-outline-gap { 
    border: 1px solid $nightblue !important;
}
.login_form .mat-form-field-appearance-outline .mat-form-field-outline-gap { 
    border: 1px solid $nightblue !important;
    border-left-style: none !important;
    border-right-style: none !important;
}
// .selected-list .mat-form-field-appearance-fill .mat-form-field-flex {
//     background: $whitecolor !important;
// }

.search .mat-form-field-appearance-outline .mat-form-field-outline {
    background: transparent !important;
    color: $whitecolor;
    max-width: 300px;
}

.input-box-container .mat-form-field-appearance-fill .mat-form-field-flex,
.input-box-container .mat-form-field-appearance-outline .mat-form-field-outline {
    background: transparent !important;
}

.input-box-container .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    margin: 0px !important;
    margin-top: 10px !important;
}

.search .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 10px 0px;
}

.search .mat-form-field-appearance-outline .mat-form-field-infix {
    margin-left: 5px;
}
.login_page .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 10px 0px;
}
.search .mat-form-field-appearance-outline .mat-form-field-prefix {
    top: 7px;
}

.search-bar .mat-form-field-appearance-outline .mat-form-field-flex {
    border-radius: 6px !important;
    height: 30px;
    padding: 6px 10px 4px;
    border: 1px solid $stormgray;
    background: transparent !important;
}

.search-bar .mat-form-field-label-wrapper {
    overflow: visible !important;
}

.search-bar .mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
    margin: 0px;
}

.search-bar .mat-form-field-prefix .mat-icon {
    width: 16px;
    height: 16px;
    font-size: 17px;
    color: $stormgray;
}

.search-bar .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 2px 0px;
    border-top: 0px;
    margin-left: 5px;
}

.search-bar .mat-form-field-appearance-fill .mat-form-field-label {
    margin-top: -1px;
}

.whitelist-page .search-bar .mat-form-field-appearance-fill .mat-form-field-label,
.details-header .search-bar .mat-form-field-appearance-fill .mat-form-field-label {
    margin-top: -5px;
}
 

.whitelist-page .search-bar input.mat-input-element,
.whitelist-page .search-bar .mat-form-field-prefix .mat-icon {
    color: #6F779B !important;
    margin-top: -5px;
}
.details-header .search-bar input.mat-input-element,
.details-header .search-bar .mat-form-field-prefix .mat-icon {
    color: #6F779B !important;
    margin-top: -5px;
}

.whitelist-page .search-bar input.mat-input-element,
.details-header .search-bar input.mat-input-element {
    height: 21px;
}
.white-box-header {
    .left-mgt-head {
        width: 70%;
    }
    width: 100%;
}
 .white-box-header .left-mgt-head .search-bar .mat-form-field  {
    width: 100%;
}
.white-box-header .left-mgt-head .search-bar .mat-form-field input.mat-input-element,.device_page .device-mgnt-header .left-mgt-head .search-bar .mat-form-field input.mat-input-element {
    color: #696595 !important;
}
.myscan_page{
    .myscan-mgnt-header {
        .left-mgt-head {
            .search-bar {
                mat-form-field input.mat-input-element {
                    color: #696595 !important;

                }

            }
        }



    }
}
.device_page .device-mgnt-header .left-mgt-head .search-bar .mat-form-field,.myscan_page .myscan-mgnt-header .left-mgt-head .search-bar .mat-form-field {
    width: 100%;
}
.address-field .mat-form-field-appearance-outline .mat-form-field-outline {
    height: 100px;
}

.mat-form-field-wrapper {
    padding-bottom: 0px !important;
}

.mat-form-field-appearance-fill .mat-form-field-label,
input.mat-input-element {
    font-size: 14px;
}
.VPN-item .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label{
    color: #7C79A3;
    font-weight: 500;
    font-size: 12px;
 }
.half-width input.mat-input-element {
    font-size: 12px !important;
    
}

.input-box-container {
    .exec-field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        text-align: left;
        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;  
          color: $nightblue;
        }
        .sub-text {
          display: flex;
          flex-direction: column;
          gap: 4px;
          span {
            font-weight: 400;
            font-size: 11px;
            max-width: 250px;
            line-height: 13px;
          }
      
        }
        .tool_switch {
            display: flex;
            align-items: center;
        &.exec-tool_switch{
            
        .mat-slide-toggle-bar {
            background-color: $lightblack !important;
              height: 21px !important;
              width: 42px !important;
            }
            .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
                background-color: $aquamarine !important;    
            }
            .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
                background-color: $whitecolor !important;
                border: 1px solid $aquamarine !important;
                left: auto;
            }
            .mat-slide-toggle-thumb {
              width: 15px !important;
              height: 15px !important;
            }
        
            .mat-slide-toggle-thumb-container {
              transform: translate3d(1px, 0, 0) !important;
            }
        
             .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
              transform: translate3d(23px, 0, 0) !important;
            }
        
            .mat-slide-toggle-thumb {
              background-color: $whitecolor !important;
              width: 15px !important;
              height: 15px !important;
              position: relative;
              top: 1px;
              left: 2px;
          
            }
        }
            .switch-label {
              padding-right: 10px;
              margin-bottom: 0px;
              font-size: 14px;
              font-weight: 500;
              line-height: 16px;
              letter-spacing: 0em;
              text-align: left;
              color: $nightblue;
        
              &.right {
                padding-left: 10px;
                padding-right: 0px;
              }
            }
          }
      }
}
.input-box-container .mat-form-field-label,
.input-box-container .mat-select-arrow {
    color: $acapulco !important;
}
.newscan-info .input-box-container .mat-form-field-label .mat-placeholder-required{
    display: none;
} 
.newscan-info .mat-form-field-appearance-fill .mat-form-field-flex, .newscan-info .mat-form-field-appearance-outline .mat-form-field-outline { height: 48px; }
.input-box-container .mat-focused .mat-form-field-label {
    color: $neonsilver !important;
    height: 20px;
}

.input-box-container input.mat-input-element::placeholder {
    color: $acapulco;
}

.input-box-container .mat-form-field-appearance-outline .mat-form-field-outline-gap,
.input-box-container .mat-form-field-appearance-outline .mat-form-field-outline-start,
.input-box-container .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-color: $acapulco;
}

.mat-radio-outer-circle {
    border-color: #C5CADE;
}
.role-search-header .search-bar .mat-form-field-appearance-fill .mat-form-field-infix {
    width: 130px;;
}
.container {
    width: 80%;
    max-width: 1320px;
    margin: 0px auto;

    @media screen and (max-width: $small) {
        width: 100%;
    }
}
.new-device-page {
    .new-device-section{
        overflow-x: visible;
        overflow: visible;
    }
}
a {
    color: #97888e;
    text-decoration: none !important;
    cursor: pointer;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.5rem;
    font-weight: bold;
    line-height: 1.2;
}

.h1,
h1 {
    font-size: 40px;
}

.h2,
h2 {
    font-size: 32px;
}

.h3,
h3 {
    font-size: 28px;
}

.h4,
h4 {
    font-size: 24px;
}

.h5,
h5 {
    font-size: 18px;
}

.h6,
h6 {
    font-size: 16px;
}

ul,
li {
    list-style-type: none;
    padding: 0px;
}

p {
    margin: 0px;
    font-size: 12px;
    line-height: 16px;
}

.font_regular {
    font-weight: 400;
}

.font_semibold {
    font-weight: 500;
}

.font_bold {
    font-weight: 700;
}

.font_black {
    font-weight: 900;
}

.sunsetorange-bg {
    background: #ff5b5b;
}

.purpleheart-bg {
    background: $gray23color;
}

.vividorange-bg {
    background: #FFA41C;
}

.green-bg {
    background: #11BB57;
}

.bluelight-bg {
    background: $aquamarine;
}

.text_center {
    text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background-color: lightyellow;
    transition: background-color 5000s ease-in-out 0s;
}

.shadow-sm {
    box-shadow: 0 2px 2px rgba(0, 0, 0, .075);
} 

.shadow {
    box-shadow: 0 2px 2px rgba(0, 0, 0, .15);
}

.shadow-lg {
    box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
}

.shadow-none {
    box-shadow: none !important;
}

.mat-raised-button {
    font-size: 12px;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
    background: $aquamarine;
    color: $whitecolor;
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: rgba(0, 0, 0, 0.6);
}

.personal_details .mat-form-field.mat-focused .mat-form-field-label {
    color: $darkblackcolor;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background: $aquamarine;
}

.bg-white {
    background: $whitecolor;
}

.bg-light {
    background-color: #EEF2F2 !important;
}

.text-light {
    color: $whitecolor;
    opacity: 0.5;
    font-size: 12px;
    line-height: 16px;
    padding-bottom: 20px;
}

.full-width {
    width: 100%
}
.full-height {
    height: 100%
}
.half-width {
    width: 50%
}

.small,
small {
    font-size: 11px !important;
}

.text-muted {
    color: #888888 !important;
    font-weight: 
    400;
}

.text-white {
    color: $whitecolor !important;
    transition: all 0.65s;
}
.text-white:hover{
    color: $aquamarine !important;
    transition: all 0.65s;  }
.text-dark {
    color: #343a40 !important;
}

.font_w6 {
    font-weight: 600;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;

    @media screen and (max-width: $small) {
        display: block !important;
    }
}

.md-flex {
    @media screen and (max-width: $small) {
        display: flex !important;
    }
}

.d-inlineblock {
    display: inline-block;
    vertical-align: top;
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

.d-table {
    display: table;
}

.d-tcell {
    display: table-cell;
    vertical-align: top;
}

// .align-items-center {
//     -ms-flex-align: center !important;
//     align-items: center !important;
// }

.justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.out-layout {
    padding: 20px;
    height: calc(100vh - 60px);

}

.page_content {
    border-radius: 8px;
    overflow: hidden;
    // box-shadow: 0px 1px 16px 0px #0000000D;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);

}

.mat-drawer-content {
    overflow: visible !important;
}

.p0 {
    padding: 0px !important;
}

.p1 {
    padding: 4px;
}

.p2 {
    padding: 8px;
}

.p3 {
    padding: 16px;
}

.p4 {
    padding: 24px;
}

.p5 {
    padding: 48px;
}

.py_1 {
    padding-bottom: 4px;
    padding-top: 4px;
}

.py_2 {
    padding-bottom: 8px;
    padding-top: 8px;
}

.py_3 {
    padding-bottom: 16px;
    padding-top: 16px;
}

.py_4 {
    padding-bottom: 24px;
    padding-top: 24px;
}

.py_5 {
    padding-bottom: 28px;
    padding-top: 48px;
}

.px_1 {
    padding-left: 4px;
    padding-right: 4px;
}

.px_2 {
    padding-left: 8px;
    padding-right: 8px;
}

.px_3 {
    padding-left: 16px;
    padding-right: 16px;
}

.px_4 {
    padding-left: 24px;
    padding-right: 24px;
}

.px_5 {
    padding-left: 48px;
    padding-right: 48px;
}

.pr_1 {
    padding-right: 4px;
}

.pr_2 {
    padding-right: 8px;
}

.pr_3 {
    padding-right: 16px;
}

.pr_4 {
    padding-right: 24px;
}

.pr_5 {
    padding-right: 48px;
}

.pl_1 {
    padding-left: 4px;
}

.pl_2 {
    padding-left: 8px;
}

.pl_3 {
    padding-left: 16px;
}

.pl_4 {
    padding-left: 24px;
}

.pl_5 {
    padding-left: 48px;
}

.pt_1 {
    padding-top: 4px;
}

.pt_2 {
    padding-top: 8px;
}

.pt_3 {
    padding-top: 16px;
}

.pt_4 {
    padding-top: 24px;
}

.pt_5 {
    padding-top: 48px;
}

.pb_0 {
    padding-bottom: 0px;
}

.pb_1 {
    padding-bottom: 4px;
}

.pb_2 {
    padding-bottom: 8px;
}

.pb_3 {
    padding-bottom: 16px;
}

.pb_4 {
    padding-bottom: 24px;
}

.pb_5 {
    padding-bottom: 48px;
}

.m0 {
    margin: 0px;
}

.m1 {
    margin: 4px;
}

.m2 {
    margin: 8px;
}

.m3 {
    margin: 16px;
}

.m4 {
    margin: 24px;
}

.m5 {
    margin: 48px;
}

.my_1 {
    margin-bottom: 4px;
    margin-top: 4px;
}

.my_2 {
    margin-bottom: 8px;
    margin-top: 8px;
}

.my_3 {
    margin-bottom: 16px;
    margin-top: 16px;
}

.my_4 {
    margin-bottom: 24px;
    margin-top: 24px;
}

.my_5 {
    margin-bottom: 28px;
    margin-top: 48px;
}

.mx_1 {
    margin-left: 4px;
    margin-right: 4px;
}

.mx_2 {
    margin-left: 8px;
    margin-right: 8px;
}

.mx_3 {
    margin-left: 16px;
    margin-right: 16px;
}

.mx_4 {
    margin-left: 24px;
    margin-right: 24px;
}

.mx_5 {
    margin-left: 48px;
    margin-right: 48px;
}

.mr_0 {
    margin-bottom: 0px;
}

.mr_1 {
    margin-right: 4px;
}

.mr_2 {
    margin-right: 8px;
}

.mr_3 {
    margin-right: 16px;
}

.mr_4 {
    margin-right: 24px;
}

.mr_5 {
    margin-right: 29px;
}

.ml_0 {
    margin-left: 0px;
}

.ml_1 {
    margin-left: 4px;
}

.ml_2 {
    margin-left: 8px;
}

.ml_3 {
    margin-left: 16px;
}

.ml_4 {
    margin-left: 24px;
}

.ml_5 {
    margin-left: 29px;
}

.mt_0 {
    margin-top: 0px;
}

.mt_1 {
    margin-top: 4px;
}

.mt_2 {
    margin-top: 8px;
}

.mt_3 {
    margin-top: 16px;
}

.mt_4 {
    margin-top: 24px;
}

.mt_5 {
    margin-top: 29px;
}

.mb_0 {
    margin-bottom: 0px;
}

.mb_1 {
    margin-bottom: 4px;
}

.mb_2 {
    margin-bottom: 8px;
}

.mb_3 {
    margin-bottom: 16px;
}

.mb_4 {
    margin-bottom: 24px;
}

.mb_5 {
    margin-bottom: 29px;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    font-size: 14px;
    margin-top: -12px;
    line-height: 13px;
}
.search-bar .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
}
.search-bar input.mat-input-element { height: 21px; }
.list-search-box .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    margin-top: -6px;
}

.whitelist-page .whitelist-right .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label mat-label {
    background: $snowgray;
}


.search-bar .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label mat-label {
    background: $whitecolor;
}


.ml_auto {
    margin-left: auto !important;
}

.mr_auto {
    margin-right: auto !important;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
    position: relative;
}

.h1 {
    font-size: 36px;
    font-weight: normal;
}

.h2 {
    font-size: 32px;
    font-weight: normal
}

.h3 {
    font-size: 28px;
    font-weight: normal
}

.h4 {
    font-size: 24px;
    font-weight: normal
}

.h5 {
    font-size: 20px;
    font-weight: normal
}

.h6 {
    font-size: 16px;
    font-weight: normal
}

.form-group {
    margin-bottom: 16px;
}

label {
    display: inline-block;
    margin-bottom: 8px;
}

.rounded-pill {
    border-radius: 50% !important;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {

    position: relative;
}

.rounded {
    border-radius: 0.25rem !important;
}

.sticky_sidebar {
    position: sticky;
    top: 10px;
}

.overflow-hidden {
    overflow: hidden !important;
}
.v-top { vertical-align: top;}
button {
    box-shadow: none !important;
}

.btn {
    display: inline-block;
    border-radius: 5px;
    padding: 0px 24px;
    line-height: 20px;
    font-size: 12px;
    border: 0px;
    border-radius: 6px;
    line-height: 14px;
    border: 1px solid transparent;
    height: 30px;
    line-height: 28px !important;
    transition: all 0.3s ease;
    cursor: pointer;
}
.btn-link {
    color: $aquamarine;
    cursor: pointer;
    border-style: none;
    background: none;

}
:host ::ng-deep .mat-button {
    display: block;
    border-radius: 5px;
    padding: 11px 24px;
    line-height: 20px;
    font-size: 12px;
    cursor: pointer;
    border: 0px;
    border-radius: 6px;
    line-height: 14px;
}

.btn-agent {
    background: $Pumpkin;
    color: $whitecolor;
    border: 1px solid $Pumpkin;
    &:hover {
    background: $Pumpkin;
    transition: all 0.3s ease !important;
    color:$whitecolor ;
    border-color: $Pumpkin;
    }
}
.btn-primary {
    background: $aquamarine;
    color: $whitecolor;
    border: 1px solid $aquamarine;
    &:hover {
    background: $aquamarinehover;
    transition: all 0.3s ease !important;
    color:$whitecolor ;
    border-color: $aquamarinehover;
    }
}
.btn-outline { 
    color: $aquamarine;
    border: 1px solid $aquamarine;
    background: transparent;
    cursor: pointer;
    &:hover {
        background: $aquamarine;
        transition: all 0.3s ease !important;
        color:$whitecolor ;
        border-color: $aquamarine;
    }
    &.secondary-outline {
        color: $lightblack;
        border: 1px solid $lightblack;
        &:hover {
            color: $whitecolor;
            background: $lightblack;
        }
    }
    &.red-outline {
        color: $tartorange;
        border: 1px solid $tartorange;
        &:hover {
            color: $whitecolor;
            background: $tartorange;
        }
    }
    &.diamond-outline {
        color: $diamondbtn;
        border: 1px solid $diamondbtn;
        &:hover {
            color: $whitecolor;
            background: $diamondbtn;
        }
    }
}
.btn-small {  
    padding: 0px 8px;
    height: 22px;
    line-height: 20px !important;
    font-size: 13px;
    font-weight: 500;
}
 
.btn-primary-outline {
    color: $aquamarine;
    border: 1px solid $aquamarine !important;
    background: transparent;
    line-height: 28px !important;
    border-radius:0 4px 4px 0 !important;
    &.br-radius {
border-radius: 4px !important;
    }
}

.btn-primary-outline:hover {
    background: $aquamarine;
    transition: all 0.3s ease !important;
    color:$whitecolor ;
    border-color: $aquamarine;
}

.btn-primary-outline-highlight {
    background: $aquamarine;
    transition: all 0.3s ease !important;
    color:$whitecolor ;
    border-color: $aquamarine;
}

.btn-alert-outline {
    color: $pastelred;
    border: 1px solid $pastelred;
    background: transparent;
    line-height: 28px !important;
    border-radius: 4px 0 0 4px !important;
}

.btn-alert-outline:hover {
    background: $pastelred;
    transition: all 0.3s ease !important;
    color:$whitecolor ;
    border-color: $pastelred;
}

.btn-alert-outline-highlight {
    background: $pastelred;
    transition: all 0.3s ease !important;
    color:$whitecolor ;
    border-color: $pastelred;
}

.btn-dark-outline {
    color: #1E2036;
    border: 1px solid #1E2036;
    background: transparent;
    &.active{
        color: $whitecolor !important;
    }
}
.btn-lightblack-outline {
    color: $lightblack;
    border: 1px solid $lightblack !important;
    background: transparent;
    &:hover{
        color: $lightblack;
        border: 1px solid $lightblack;
    }
}
.btn-dark-outline:hover {
    background: #1E2036;
    transition: all 0.3s ease !important;
    color:$whitecolor !important ;
    border-color: #1E2036;
}

.btn-dark-outline-highlight {
    background: #1E2036;
    border-radius: 5px;
    transition: all 0.3s ease !important;
    color:$whitecolor !important;
    border-color: #1E2036;
}

.btn-green {
    background: $junglegreen;
    color: $whitecolor;
    border: 1px solid $junglegreen;
    &hover {
        background: $junglegreenhover;
        transition: all 0.3s ease !important;
    }
}  

.btn-secondary {
    color: $whitecolor;
    background-color: #6c757d;
    border-color: #6c757d;
    &:hover {
        color: $whitecolor;
        background-color: #5a6268;
        border-color: #545b62;
    } 
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    &:hover {
        color: #212529;
        background-color: #e2e6ea;
        border-color: #dae0e5;
    }
} 
.btn-red-outine {
        color: $tartorange;
        border: 1px solid $tartorange;
        background: transparent;
        line-height: 3px !important;
        border-radius: 4px !important;
        &:hover {
            background: $tartorange;
            transition: all 0.3s ease !important;
            color:$whitecolor ;
            border-color: $tartorange;
        }
}
.btn-white {
    color: $nightblue;
    background-color: $whitecolor;
    border: 1px solid $manatee !important;
    &:hover {
        color: $aquamarine;
        background-color: #f8f9fa;
        border: 1px solid #575859 !important;
    }
}



.btn-plain {
    background-color: transparent;
    border: 0 !important;
    padding: 0px;
    font-weight: 600;
    
    &.red-text {
        color: $tartorange;
    }
    &.blue-text {
        color: $aquamarine;
    }
    &:hover {
        opacity: 0.9;
    }
}

.btn-grayborder {
    border-color: $dimgray !important;
}
.btn-tartorange-plane {
    color: $tartorange;
    background: transparent;
    line-height: 28px !important;
    border-radius: 4px;
}
.btn-tartorange-plane:hover {
    background: $tartorange;
    transition: all 0.3s ease !important;
    color:$whitecolor ;
    border-color: $tartorange;
}
.btn-tartorange-outline {
    color: $tartorange;
    border: 1px solid $tartorange;
    background: transparent;
    line-height: 28px !important;
    border-radius: 4px;
}
.btn-tartorange-outline:hover {
    background: $tartorange;
    transition: all 0.3s ease !important;
    color:$whitecolor ;
    border-color: $tartorange;
}
.btn-tartorange {
    color: $whitecolor;
    background-color: $tartorange;
    border-style: none;
    &:hover {
        color: $whitecolor;
        background-color: $tartorange;
        border-color: $tartorange;
    }
} 
.btn-Pumpkin {
    color: $whitecolor;
    background-color: $Pumpkin;
    border-color:$Pumpkin ;
}
.btn-red {
    color: $whitecolor;
    background-color: $pastelred;
    border-color: $pastelred;
    &:hover {
        color: $whitecolor;
        background-color: $pastelredhover;
        border-color: $pastelredhover;
    }
} 

.btn-blue {
    color: $whitecolor;
    background-color: $aquamarine;
    border-color: $aquamarine;
    &:hover {
        color: $whitecolor;
        background-color: #2161bb;
        border-color: #2161bb;
    }
}



.btn-tertiary {
    color: $tertiary;
    border: 1px solid $tertiary !important;
    border-radius: 4px;
    &:hover {
        color: $whitecolor !important;
        background-color: $tertiaryhover;
    }
}



.gray-text {
    color: $opalsilk;
}

.red-text {
    color: $pastelred;
}

.btn:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed; 
}

.profile_round {
    width: 60px;
    text-align: center;
    font-size: 30px;
    background: #ccc;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    color: $whitecolor;
}

.text-primary {
    color: #e23744 !important;
    text-decoration: none !important;
}

.bg-primary {
    background: $qingdynastyfire;
    background: -moz-linear-gradient(45deg, $qingdynastyfire 0%, #e23744 100%);
    background: -webkit-linear-gradient(45deg, $qingdynastyfire 0%, #e23744 100%);
    background: linear-gradient(45deg, $qingdynastyfire 0%, #e23744 100%);
}

.bg-success {
    background-color: #28a745 !important;
}



.border-top {
    border-top: 1px solid #dee2e6 !important;
}

.no-border {
    border: 0px !important;
}

p.form-control {
    border: 0px;
    padding: 0px;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.text-right {
    text-align: right !important;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-control-label::before {
    position: absolute;
    top: 2px;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: $whitecolor;
    border: #adb5bd solid 1px;
}

.custom-control-label::after {
    position: absolute;
    top: 6px;
    left: -20px;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-control-input:checked~.custom-control-label::before {
    color: $whitecolor;
    border-color: #007bff;
    background-color: #007bff;
}

.custom-control-input:checked~.custom-control-label::before {
    color: $whitecolor;
    border-color: #e23744;
    background-color: #e23744;
}


.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-color: $whitecolor;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

.radio_box .custom-control label:before,
.radio_box .custom-control label:after {
    display: none;
}

.radio_box .custom-control {
    padding: 0px;
}

.radio_box .custom-control .custom-control-label {
    padding: 5px 10px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    cursor: pointer;
    background: $whitecolor;
    box-shadow: 0 2px 2px rgb(0 0 0 / 8%);
}

.radio_box .custom-control .custom-control-label:hover,
.radio_box .custom-control .custom-control-input:checked~.custom-control-label {
    background: #9c1625;
    border-radius: 5px;
    color: $whitecolor;
    border: 1px solid #9c1625;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-color: $whitecolor;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.display-inlineblock {
    display: inline-block;
}
.display-inlineblock {
    display: inline-block;
}
.form-control {
    -webkit-appearance: auto;
    appearance: auto;
}

// Global CSS - Buttons /other global styling
.global-button {
    background-color: $whitecolor;
    color: $aquamarine;
    border-radius: 100px;
}
.global-button:hover {
    background: #F1EFFF;
    transition: all 0.3s ease !important;
    color:$aquamarine ;
    border-color: #F1EFFF;
}

.mat-drawer-container {
    z-index: 2 !important;
    overflow: visible !important;
}

.main_content {
    min-height: 100%; 
}

.right_ele {
    margin-left: auto;
    text-align: right;
}

.app-container {
    height: 100%;
    padding: 0px;
    margin: 0px auto;
}

.app-innercontainer {
    padding: 15px;
}

// mat-tab-start - move to component level 
.mat-tab-nav-bar,
.mat-tab-header {
    border-bottom: none;
}

.tooltip-list {
    white-space: pre;
}

.white-box, .white-box-svg {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
    position: relative;
}

// .mat-tab-label {
//     height: 35px;
//     min-width: 110px;
//     padding: 8px 35px;
//     background: #A3A7B7;
//     border-radius: 200px;
//     color: $whitecolor;
//     margin-left: 10px;
// }

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: unset;
}

.mat-tab-label {
    min-width: auto !important;
}

.mat-tab-label-active {
    background: $aquamarine;
    color: $whitecolor;
}

p.label_object {
    color: $suvagrey;
    padding-bottom: 8px;
}

p.label_value {
    padding-bottom: 8px;
}

p.label_value span {
    padding: 0px 20px;
}

p.label_value span:first-child {
    padding-left: 0px;
}

.mat-drawer-side {
    background: transparent;
}

// mat tab end
// netsec table Start
.table_fixed {
    table-layout: fixed;
}

.netsec_table {
    box-shadow: none;
    width: 100%;
    table-layout: fixed;
    .mat-row { 
        &:nth-child(odd) {
            background:#F8F8FF;  
        } 
        &:hover {
            background: #E7E6F3;
        }
    } 
    .mat-icon {
        color: $dimgray;
    }

    td.mat-cell {
        font-size: 13px; 
        color: $dimgray; 
        &::after {
            content: '';
            display: block;
        }

    }

    .mat-header-cell {
        font-weight: bold;
        color: $darkcharcoal;
        border: 0px;

    }

    tr.mat-row:hover {
        border: 1px solid $aquamarine;
        cursor: pointer;
        border-left: 0px;
        border-right: 0px;

        .mat-cell {
            color: $darkblackcolor;
        }

    }

    &.disable_netsec_table {
        tr mat-row:hover {
            border: 1px solid $aquamarine;
            background: #EFF9F7;
            transition: all 0.3s ease 0s;
            box-shadow: none;
            border-radius: 0px;
            background: transparent;

            td {
                background: transparent;

                &::before {
                    border-color: lightgray;
                    border-top: 0px;
                    border-left: 0px;
                    border-right: 0px;
                    border-radius: 0;
                }

                &:first-child:before {
                    border-color: lightgray;
                }
            }

        }
    }

    :host ::ng-deep .mat-menu-content {
        width: 120px;
    }

    :host ::ng-deep .mat-menu-panel .mat-menu-item {
        font-size: 13px;
        height: 30px;
    }

    .mat-row {
        padding: 100px;
        height: 39px;
        transition: all 0.3s ease 0s;
    }

    .mat-row:hover {
        border: 1px solid $aquamarine;
        background: #F1EFFF;
        transition: all 0.3s ease 0s;
    }

    .mat-row td {
        transition: all 1s ease 0s;
    }

    .mat-row:hover td {
        background: #F1EFFF;
        transition: all 1s ease 0s;
    }

    .mat-row span {
        position: relative;
        // display: inline-block;
        max-width: 100%;
        //vertical-align: bottom;
        align-items: center;
        &.text_ellipsis {
            display: inline-block;
        }
    }

    .mat-row {
        .mat-checkbox {
            .mat-checkbox-inner-container {
                position: relative;
            }

            .mat-checkbox-ripple,
            .mat-ripple-element,
            .mat-checkbox-frame,
            .mat-checkbox-background {
                position: absolute;
            }
        }
    }

    .mat-row td {
        height: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: none;
        position: relative;
        z-index: 1;
        font-weight: 600;
        color: $lightblack;
        padding-right: 5px;
    }

    .mat-row td::before {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0px;
        margin: auto;
        border-bottom: 0px; 
        z-index: -1;
        top: 0px;
    }

    // .mat-row:last-child td::before {
    //     border-bottom: 1px solid lightgray;
    // }

    .mat-row:first-child td::before {
        border-top: 0px;
    }

    .mat-row td:first-child:before {
        border-left: 1px solid $whitecolor;
        border-top-left-radius: 1px solid $whitecolor;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: 0px;
        border-right: 0px;
    }

    .mat-row td:last-child:before {
        border-right: 1px solid $whitecolor;
        border-top-right-radius: 1px solid $whitecolor;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .mat-row:hover td:last-child:before {
        border-top-right-radius: 1px solid $aquamarine;
    }

    .mat-row:hover td:first-child:before {
        border-top-left-radius: 1px solid $aquamarine;
    }

}

.text_ellipsis,
td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.page_header {
    background: #F1F3F9;
    padding: 0px 22px;
    position: relative;
    display: flex;

    h2 {
        font-size: 26px;
        line-height: 30px;
        color: #10111E;
        margin: 0px;
        vertical-align: top;

        span {
            margin-right: 10px;
            vertical-align: top;
            height: 30px;
            display: inline-block;

            .mat-icon {
                width: 30px;
                height: 30px;
                font-size: 30px;
            }
        }
    }

}

// netsec table End

.mat-tab-header {
    background: #E2E6F3;
    box-shadow: 0px 1px 0px #d7dbe9;
    padding: 0px;

    @media screen and (max-width: $mobile) {
        padding: 0px 10px;
    }

    .mat-tab-label {
        background: transparent;
        color: $darkblackcolor;
        font-size: 14px;
        height: 40px;
        border-radius: 0px;
        border-bottom: 3px solid #E2E6F3;
        opacity: 1;

        @media screen and (max-width: $tab) {
            padding: 0px 15px;
        }
    }

    .mat-tab-label:focus {
        background: transparent !important;
    }

    .mat-tab-label-active {
        background: transparent;
        color: $aquamarine;
        border-bottom: 3px solid $aquamarine;
    }
}

/*-----------tool list ------------*/

.tool_list {
    margin: 0px;
    padding: 0px;

    li {
        padding: 11px 40px;

        label {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px; 
            cursor: pointer;
            //color: $darkblackcolor;
            margin-bottom: 0px !important;
        }
    }

    .tool-sublist {
        margin-left: 10px;
        background: #f4f5fa;
        margin-top: 6px;
        padding: 0px 10px;

        ul {
            li:last-child {
                border-bottom: 0px;
            }
        }
    }
}

/*-----------switch button ------------*/

.tool_switch {
    height: 18px;
    .mat-slide-toggle {
        height: 18px;
    }

    .mat-slide-toggle-thumb-container {
        transform: translate3d(3px, 0, 0) !important;
        transition: all 0.5s ease !important;
        top: 2px;
        width: 15px;
        height: 15px;
    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
        transform: translate3d(18px, 0, 0) !important;
    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container,
    .mat-slide-toggle-bar,
    .mat-slide-toggle-thumb {
        transition: all 0.5s ease !important;
    }

    .mat-slide-toggle-bar {
        height: 18px !important;
        width: 35px !important;
        border-radius: 36px !important;
        background-color: #dfe3f2 !important;
        position: relative;
    }

    .mat-slide-toggle-thumb { 
        position: relative;
        background-color: #a3b4c3 !important;
        width: 14px !important;
        height: 14px !important;
        box-shadow: none;

    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
        background-color: $mustardgreen !important;
    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
        background-color: $whitecolor !important;
        border: 1px solid $mustardgreen !important;
        left: auto;
    }

    .mat-slide-toggle .mat-slide-toggle-ripple {
        display: none;
    }

    .mat-slide-toggle-bar:hover .mat-slide-toggle-persistent-ripple {
        background: transparent !important;
    }
}

/*-----------value button ------------*/
.tool_value {
    margin: 0 auto;
    text-align: right;

    .value-button {
        display: inline-block;
        border: 1px solid #BEC5E0;
        margin: 0px;
        width: 21px;
        height: 30px;
        text-align: center;
        vertical-align: top;
        padding: 0;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 14px;
        line-height: 30px;
        color: #8891B8;

        &.disabled {
            cursor: not-allowed;
        }
    }

    .value-button:hover {
        cursor: pointer;

        &.disabled {
            cursor: not-allowed;
        }
    }

    #decrease {
        margin-right: -1px;
        border-radius: 100px 0 0 100px;
        border-right: 0px;
    }

    #increase {
        margin-left: -4px;
        border-radius: 0 100px 100px 0;
        border-left: 0px;
    }

    #input-wrap {
        margin: 0px;
        padding: 0px;
    }

    input#number {
        text-align: center;
        border: none;
        outline: none;
        border-top: 1px solid #BEC5E0;
        border-bottom: 1px solid #BEC5E0;
        margin: 0px;
        width: 23px;
        height: 30px;
        padding: 0px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.file-upload {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
}

.main-identified-unidentified {
    padding: 0;

    .mat-dialog-container {
        padding: 0;
        border-radius: 16px;
    }
}

.delete {
    font-size: 20px;
    color: $pastelred;
    cursor: pointer;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $aquamarine;
}

:host ::ng-deep .mat-form-field,
.mat-form-field {
    color: $blackcolor !important;

}

:host ::ng-deep input:autofill,
:host ::ng-deep input,
input {
    color: $blackcolor !important;
}

.mat-button.no-hover :host ::ng-deep .mat-button-focus-overlay {
    background: transparent;
    border-radius: 0px;
}

.mat-button-ripple.mat-ripple,
.mat-button-focus-overlay {
    background: transparent !important;
    border-radius: 0px !important;
    outline: none;
}

.mat-button-focus-overlay {
    background-color: transparent !important;
}

.mat-checkbox .mat-ripple-element {
    background-color: transparent !important;
    border-radius: 0px;
}

.small-spinner {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    margin-top: -25px;
    border: 4px solid #3f51b5;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: loader-spin-small 0.8s infinite linear;
    -o-animation: loader-spin-small 0.8s infinite linear;
    -ms-animation: loader-spin-small 0.8s infinite linear;
    -webkit-animation: loader-spin-small 0.8s infinite linear;
    -moz-animation: loader-spin-small 0.8s infinite linear;
    z-index: 99;
}

@keyframes loader-spin-small {
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes loader-spin-small {
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes loader-spin-small {
    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes loader-spin-small {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes loader-spin-small {
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: $aquamarine;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $aquamarine;
}

.mat-radio-button.mat-accent.scan-radio-button .mat-radio-inner-circle { 
    background-color: $aquamarine;
    transform: scale(1);
}
.mat-radio-button.mat-accent.scan-radio-button .mat-radio-inner-circle::before {
    width: 20px;
    height: 20px;
    content: "\f00c";
    font-size: 13px;
    font-weight: bold;
    position: absolute;
    align-items: center;
    justify-content: center;
    font-family: 'FontAwesome';
    text-align: center;
    color: $whitecolor;
    line-height: 20px;
}

.mat-radio-button.mat-accent.mat-radio-checked.scan-radio-button .mat-radio-outer-circle {
    border-color: $aquamarine;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $aquamarine;
}

:host ::ng-deep .mat-form-field-subscript-wrapper.mat-error {
    font-size: 13px;
}

.scroll {
    overflow: scroll;
    overflow-x: hidden;

}

.scroll::-webkit-scrollbar {
    width: 5px;
    right: 3px;
}

.scroll::-webkit-scrollbar-track {
    right: 3px;
}

.scroll::-webkit-scrollbar-thumb {
    background: $gainsboro;
    right: 3px;
}

.h-scroll {
    overflow: scroll;

}

.h-scroll::-webkit-scrollbar {
    height: 5px;
    bottom: 3px;
}

.h-scroll::-webkit-scrollbar-track {
    bottom: 3px;
}

.h-scroll::-webkit-scrollbar-thumb {
    background: $gainsboro;
    bottom: 3px;
}
.cve-table {
    height: 100%;
}
.cve-details-assets-table{
    height: calc(100vh - 160px);
}
.user_mngt_class {
    height: calc(100% - 55px);
}
.cloud_security_class {
    height: calc(100% - 55px);
}
.resource_page_content{
    height: calc(100vh - 194px);  
}
.white_list_details_tabel {
    height: calc(100% - 55px);
}
.whitlist-details-table   {
    height: 100%;
}
.whitelist-tabel, .my-scan-table, .new_vul_tabel, .list-details-table, .schedule-list-table, .scan-status-table, .asset-with-dashboard,.cloud-resource-table{
    .ag-row {
        cursor: pointer;
    }
}

.my-scan-table{
    height: calc(100% - 55px);  
    
}
.cloud-resource-table{
    height: calc(100% - 55px);  
}
.whitelist-tabel {
    height: calc(100% - 55px); 
    .ag-cell.ag-cell-last-left-pinned {
        border: none !important;
    }
    .ag-pinned-left-header {
        border: none !important;
    }
}
.cve_vul_tabel {
    height: 100%;
}
.new_vul_tabel {
    // height: 87%;
    height: calc(100% - 55px);

}
.control-panel .control-panel-row .common-vulnerabilities-box .spark-whitebox .commonvulnarabilites-tab .tabcontent {
    // .cve-overlay-header { 
    //     padding: 0px 10px !important;
    // }
    .new_vul_tabel {
        height: 100% !important; 
    }
}

.list-details-table{
    height: calc(100% - 55px);  
}
.schedule-list-table{
    height: calc(100% - 55px); 
}
.scan-status-table{
    height: calc(100% - 55px);

}

.asset-with-dashboard {
    height: calc(100% - 55px); 
}
.common_vul_class {
    height: 100%;
}
.ag-header-cell[col-id="options"] {
    display: none;
}

.ag-header-cell[col-id="ispassed"] {
    display: none;
}

.ag-header{
    border: none;
}

.indicator-section {
    display: flex;
    margin-right: 8px;
    span{
        width: 11px;
        height: 11px;
        border-radius: 50%;
        margin-right: 2px;
        background-color: rgba(170, 167, 198, 0.26);
    }
}
.ag-scroll{
    .ag-body-vertical-scroll-viewport{
        overflow-x: hidden;
    }
    .ag-body-vertical-scroll-viewport::-webkit-scrollbar {
        height: 5px;
        bottom: 3px;
    }
    
    .ag-body-vertical-scroll-viewport::-webkit-scrollbar-track {
        bottom: 3px;
    }
    
    .ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb {
        background: $gainsboro;
        bottom: 3px;
    }
}

.scroll-large {
    overflow: scroll;
    overflow-x: hidden;

}

.scroll-large::-webkit-scrollbar {
    width: 8px;
    right: 3px;
}

.scroll-large::-webkit-scrollbar-track {
    background: $whitecolor;
    right: 3px;
}

.scroll-large::-webkit-scrollbar-thumb {
    background: $gainsboro;
    right: 3px;
}

.eff_tooltip {
    background: $nightblue;
    border-radius: 6px;
    padding: 12px 15px;
    z-index: 2;
    position: absolute;
    right: 0px;
    min-width: 100px;
    display: none;
}

.vul-count {
    &:hover {
        .eff_tooltip {
            display: block;
        }
    }
}

.legends {
    padding: 10px 0 0 10px;
    margin: 0;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;

    li {
        padding-right: 5px;
        display: flex;
        gap: 5px;

        span {
            padding-right: 5px;
            width: 14px;
            height: 14px;
            background-color: #F6585C;
            border-radius: 3px;
            display: block;

            &.outer-span {
                width: 18px;
                height: 18px;
                overflow: hidden;
                text-align: center;
                padding: 2px;
                color: $whitecolor;
            }

            &.inner-span {
                color: transparent;
                width: 9px;
                display: block;
                margin: 0 auto;
                padding: 0;
                background-color: transparent;

                &:first-letter {
                    color: $whitecolor;
                    font-weight: 500;
                }
            }
        }

        .range-value {
            white-space: nowrap;
            font-size: 10px;
        }

        .legent-name {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.7);
            font-weight: 400;
            line-height: 14px;
            padding-top: 3px;
        }
    }
}

.chart-section {

    min-width: 70%;
    margin: 0 auto;

    .box-label {
        font-weight: 700;
        font-size: 16px;
        line-height: 15px;
        color: #1A1C2F;
        padding-bottom: 10px;
    }

    .control-panel-col-header {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0px;

        .region-sort {
            margin-left: auto;
        }
    }

    .region-sort {
        margin-left: auto;

        .sort-title {
            margin-bottom: 0px;
            color: #222222;
            font-weight: 800;
            font-size: 12px;
            line-height: 14px;
            cursor: pointer;
        }

        .mat-button {
            margin: 0px;
            padding: 0px;
            min-width: auto;
            line-height: 15px;
            font-size: 12px;
            cursor: pointer;
            transition: all 0.5s;

            // &:hover {
            //     cursor: pointer;
            //     opacity: 0.6;
            //     transition: all 0.5s;
            // }
            &:disabled {
                cursor: not-allowed;
                pointer-events: none;
            }

            .mat-icon {
                width: 21px;
                height: 12px;
                line-height: 10px;
            }
        }
    }
    &.cyber-reilience {
        .date-range-section + .region-sort {
            margin-left: 15px; 
        }
    }
}
.mat-menu-panel {
    &.mapregion-info {
        p {
            padding-bottom: 15px;
            font-size: 13px;
        }
    }
}

.mat-menu-panel {
    background: #151732;
    padding: 15px 20px;
    max-height: 300px !important;
    // max-width: 500px !important;
    overflow: auto !important;
    min-width: 170px !important;
&.scan-list-menu {
        padding-right: 15px;
        .mat-menu-content {
            padding-right: 5px !important;
        }
    }
    &.account_dropdown {
        // max-width: none !important;
        max-width: 220px;
    }

&.mapregion-info {
    overflow: visible !important;
    box-shadow: 0px 0px 15px 0px #00000040;
    position: absolute;
    top: 11px;
    left: -152px;
    max-width: 327px !important;
}
&.mapregion-info::before {
    content: " ";
    display: block;
    width: 14px;
    height: 14px;
    margin: auto;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    margin-top: 2px;
}

    &.benchmark-info {
        background: $eggwhite;
        border: 1px solid #D6CA8C;
        right: -10px;
        top: 10px;
        overflow: visible !important;
        box-shadow: 0px 0px 15px 0px #00000040;
        &.whitebox-info {
            background: #fff;
            border: none;
            right: 132px;
            top: 10px;
        }
        .list-order {
            padding-left: 20px;
        }

        ol {
            margin: 0px !important;
            padding: 0px !important;
        }

        .list-item {
            font-weight: 700;
        }

        .list-item-value {
            font-weight: 400;
            font-size: 12px;
            list-style-type: disc !important;
        }
    }

    &.benchmark-info::before {
        content: " ";
        display: block;
        width: 14px;
        height: 14px;
        margin: auto;
        position: absolute;
        right: 16px;
        top: -10px;
        transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin-top: 2px;
        border-top: 1px solid #D6CA8C;
        border-left: 1px solid #D6CA8C;
    }
    &.whitebox-info::before{
        border-color: transparent;
        background: $whitecolor;
        margin-left: -7px;
       left: 50%;
    }

    &.white-bg {
        background: $whitecolor;
    }

    .mat-menu-content {
        padding: 0px !important;
        overflow: auto;
        height: 100%;

        .mat-checkbox-label {
            width: calc(100% - 24px);
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $linkwater;
        }
        .mat-checkbox-checked {
            .mat-checkbox-label {
                color: $whitecolor;
            }
        }
        .mat-checkbox {
            width: 100%;
        }

        label {
            margin-bottom: 0px;
            width: 100%;
        }

        .mat-checkbox-frame {
            border-color: #C5CADE !important;
        }

        .mat-checkbox-checked.mat-accent .mat-checkbox-background {
            background-color: $aquamarine;
        }

        .mat-checkbox-checkmark-path {
            stroke: $whitecolor !important;
        }
        // p {
        //     color: $lightgrayish;
        // }
        .mat-menu-item {
            //color: $darkgray !important;
            padding: 0px;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            height: auto;
            padding: 8px 0px;
            &.black {
                color: $nightblue; 
                padding: 4px 0px;
            }
            .green {
                color: $junglegreen ;
            }
            &.red-text {
                color: $tartorange;
            }
            // &:hover {
            //     color: $whitecolor ;
            //     background: transparent;
            // }
            

            // &:first-child {
            //     padding-top: 0px;
            // }

            // &:last-child {
            //     padding-bottom: 0px;
            // } 

            .mat-icon {
                color: $aquamarine;
                font-size: 19px;
                line-height: 20px;
                margin-left: 2px;
            }
        }
    }

    .scan-list-menu {
        .mat-menu-content {
            &::-webkit-scrollbar {
                width: 5px;
                right: 3px;
            }

            &::-webkit-scrollbar-track {
                right: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background: $gainsboro;
                right: 3px;
            }
        }
    }
}

.mat-menu-scroll {
    .mat-menu-content::-webkit-scrollbar {
        width: 5px;
        right: 3px;
    }

    .mat-menu-content::-webkit-scrollbar-track {
        right: 3px;
    }

    .mat-menu-content::-webkit-scrollbar-thumb {
        background: $gainsboro;
        right: 3px;
    }
}
.account_dropdown.mat-menu-panel .mat-menu-content .mat-checkbox-label, .mat-menu-panel .mat-menu-content .mat-checkbox-label.mat-checkbox-checked {
    color: $darkgray;
    font-weight: 600;
}
.account_dropdown {
   &.mat-menu-panel {
        padding: 10px 6px; 
        .mat-menu-content {  
            ul {
                margin: 0px;
                .mat-menu-item {
                    padding: 8px 5px;
                    color: $darkgray !important;
                    font-weight: 600 !important;
                    font-size: 10px !important;
                    display: flex;
                    align-items: center;
                    &:hover {
                        //background: $lavendermist;
                        &:nth-child(3), &:nth-child(2) {
                            background:none;  
                        }
                    }
                    &.theme-selection {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 10px;
                        .theme-btns{
                            width: 100%;
                            gap:2%;
                            display: flex;
                            .theme-option{
                                background: $menuItemColorHover;
                                border-radius:  4px;
                                color: $menuItemColor;
                                font-weight: 500;
                                font-size: 12px;
                                padding: 4px 10px;
                                width: 49%;
                                &:hover {
                                    background-color: $aquamarine;
                                    color: $whitecolor;
                                    .mat-icon{
                                        color: $whitecolor !important;
                                    }
                                }
                                .mat-icon{
                                    margin: 0px !important;
                                    color: $stormgray !important;
                                    line-height: 22px !important;
                                }
                                &.selected {
                                    background-color: $aquamarine;
                                    color: $whitecolor;
                                    .mat-icon{
                                        color: $whitecolor !important;
                                    }
                                }
                            }
                            .browser-selection{
                                .mat-menu-panel .mat-menu-content .mat-checkbox-label {
                                    color: #222222 !important;
                                    font-weight: 600 !important;
                                }

                            }
                        }
                        .browser{
                            width: 100%;
                        }
                    }

                    button {
                      //color: $darkgray;
                      padding: 0px;
                      font-size: 12px;
                      font-weight: 600;
                      line-height: 16px;
                      height: auto;
                      background: transparent;
                      border: 0;
                      text-align: justify;
                      cursor:  pointer !important;
                    } 
                    // button:hover {
                    //     color: #A8A8BE;
                    // }
                    .myaccount-li { 
                      width: 100%;
                      .myaccount-li-left {
                        span {
                          display: block;
                          font-size: 10px;
                          color: $scarlet;
                          padding-top: 2px;
                        }
                      }
                      .myaccount-li-right {
                        margin-left: auto;
                        .plan-label {
                          padding: 2px 4px;
                          color: $whitecolor;
                          border-radius: 2px;
                          text-transform: capitalize;
                          cursor: pointer !important;
                        }
                        .free {
                            background-color: $green;
                        }
                        
                        .gold {
                            background-color: #C7AB14;
                        }
                        
                        .diamond {
                            background-color: #1BA8B1;
                        }
                        
                        .platinum {
                            background-color: $lightblack;
                        }
                      }
                    }
                }
            }
        } 
      }
}
.notification-dropdown {
    &.mat-menu-panel {
        padding: 5px;
        min-height: 155px;
        border-radius: 8px;
        .mat-menu-content {
            overflow: hidden !important;
            .mat-radio-button {
                color:#696595;
                font-weight: 500;
                font-size: 12px;
                padding: 10px;
                &:hover {
                background: $lavendermist;
                }
            }
            .active {
                color: $aquamarine;
            }

        }
    }
}
.vulnerab_label {
    border-radius: 100px;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    padding: 4px 8px;
    &.cloudvulnerab_label {
        line-height: 10px !important;
        border-radius: 8px !important;
    }
    &.manual {
        background: #DEDDF2;
        color: #2B335F;

    }
    &.br-radius {
        border-radius: 4px !important;
    }
    &.label_dot {
        padding-left: 18px;
        position: relative;

        &::after {
            content: '';
            display: block;
            left: 7px;
            top: 6px;
            position: absolute;
            border-radius: 100px;
            width: 8px;
            height: 8px;
        }
    }
    &.cancle {
    color: $lightblack;
    background: $lightblackbackground;
    border: 1px solid $lightblack;

    &::after {
        background: $lightblack;
       }
    }
    &.hide {
        padding: 0 !important;

        &::after {
            display: none;
        }
    }
&.in-queue {
    color:$aquamarine;
    padding: 0px !important;
}
&.in-pgr{
    color:$aquamarine;
    background: $aquamarinebackground;
        border: 1px solid $aquamarine;
        &::after {
            background: $aquamarine;
        }
}

&.partial {
    color: $lightorange;
    background: $lightorangebackground;
    border: 1px solid $lightorange;

    &::after {
        background: $lightorange;
       }
}
    &.critical {
        color: $red;
        background: $redbackground;
        border: 1px solid $red;

        &::after {
            background: $red;
        }
    }
    &.new-critical {
        color: $criticalindicator;
        background:rgba(103, 0, 4, 0.14);
        &::after {
            background: $criticalindicator;
        }
    }
&.pending {
    color: #E5C205;
        background: #E5C2051A;
        border: 1px solid #E5C205;
}
&.inactive {
    color: #7C79A3;
    border: 1px solid #7C79A3;
    background: #7C79A31A;
}
&.paused {
    color: #F7941F;
    border: 1px solid #F7941F;
    background: #F7941F1A;
}
&.un-enrolled {
    color: $lightblack;
    border: 1px solid $lightblack;
    background: #6965951A;
}
&.install-agent {
    color: #6582FD;
    border: 1px solid #6582FD;
    background: #6582FD1A;
}
&.install-failed {
    color: $tertiary;
    border: 1px solid $tertiary;
    background: #B71E311A;
}
    &.medium {
        color: $olivedrab;
        background: $olivedrabbackground;
        border: 1px solid $olivedrab;

        &::after {
            background: $olivedrab;
        }
    }
    &.new-medium {
        color: $mediumindicator;
        background: rgba(232, 88, 0, 0.14);
        &::after {
            background: $mediumindicator;
        }
    }
    &.high {
        color: $tangerine;
        background: $tangerinebackground;
        border: 1px solid $tangerine;

        &::after {
            background: $tangerine;
        }
    }
    &.new-high {
        color: $highindicator;
        background: rgba(167, 0, 15, 0.14);
        &::after {
            background: $highindicator;
        }
    }
    &.low {
        color: $green;
        background: $greenbackground;
        border: 1px solid $green;

        &::after {
            background: $green;
        }
    }
    &.new-low {
        color: $lowindicator;
        background: rgba(242, 191, 0, 0.14);
        &::after {
            background: $lowindicator;
        }
    }
    &.schedule-cancel {
        color: $coralRed;
        background: $coralRedbackground;
        border: 1px solid $coralRed;
        &::after {
            background: $coralRed;
        }
    }
    &.strong{
        font-weight: 500;
        padding-left: 0;
    }
}

.sec-value {
    padding: 1px 4px 0px;
    font-weight: 700;
    font-size: 13px;
    line-height: 17px;
    display: inline-block;
    margin-right: 5px;
}
/* severity label colors for accepted risk graph bar*/
.bg-Medium{
    background: $olivedrab;
    }
    .bg-Critical {
        background: $red;
    }
    .bg-High {
        background: $tangerine;
    }
    .bg-Low {
        background: $green;
    }

.bg-critical {
    background: $pastelred;
}

.bg-medium {
    background: $palatinateblue;
}

.bg-high {
    background: $gray23color;
}

.bg-low {
    background: $green;
}
.cl-low{
    color: $green;
}
.cl-medium {
 color: $palatinateblue;   
}
.cl-high {
    color: $gray23color;
}
.cl-critical {
    color: $pastelred;
}
.cl-decommission{
    color: $nightblue;
}
.bg-green {
    background: $junglegreen;
}

.bg-indviolet {
    background: #AF69F5;
}

.bg-tertiary {
    background: $tertiary;
}

.bg-decommission{
    background: $nightblue;
}

.severity-critical {
    background: #670004;
}
.severity-medium {
    background: #E85800;
}
.severity-high {
    background: #A7000F;
}
.severity-low {
    background: #FFC900;
}

.severity-1 {
  background-color: #FFE455; // Your specific color for severity 1
}

.severity-2 {
  background-color: #FFC900; // Your specific color for severity 2
}

.severity-3 {
  background-color: #F2BF00; // Your specific color for severity 3
}

.severity-4 {
  background-color: #FF9642; // Your specific color for severity 4
}

.severity-5 {
  background-color: #FF6600; // Your specific color for severity 5
}

.severity-6 {
  background-color: #E85800; // Your specific color for severity 6
}

.severity-7 {
  background-color: #C60005; // Your specific color for severity 7
}

.severity-8 {
  background-color: #A7000F; // Your specific color for severity 8
}

.severity-9 {
  background-color: #960006; // Your specific color for severity 9
}

.severity-10 {
  background-color: #670004; // Your specific color for severity 10
}




// .text-critical {
//     color: $pastelred;
// }

// .text-medium {
//     color: $aquamarine;
// }

// .text-high {
//     color: $gray23color;
// }

// .text-low {
//     color: #19A71F;
// }

// .text-medium-level {
//     color: $orange
// }

// .text-green {
//     color: $junglegreen;
// }

.text-indviolet {
    color: #AF69F5;
}
.text-bluelight {
    color: $aquamarine;
}
.text-tertiary {
    color: $tertiary;
}

.text-decommission{
    background: $nightblue;
}


.text-tertiary {
    color: $tertiary;
}

.text-tertiary {
    color: $tertiary;
}

.small-dot {
    width: 8px;
    height: 8px;
    border-radius: 100px;
}

.br-small {
    border-radius: 4px;
}

.white-text {
    color: $whitecolor;
    cursor:default;
}

.full-radius {
    border-radius: 100px !important;
}

.page-extend-buttons {
    top: -45px;
    right: 260px;
    position: absolute;
    display: flex;
    align-items: center;

    .btn {
        margin-right: 15px;
        transition: all 0.3s ease;

        .mat-icon {
            margin-top: 4px;
            transition: all 0.3s ease;
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            pointer-events: none;
        }
    }
}
.filter-sidenav { 
    .mat-drawer-inner-container {
        overflow: hidden;
        .sub-container {
            height: calc(100vh - 210px) !important;
          .filter-row  {
            height: 98% !important;
          }
        }
        
    }
}
.global-filter-main.mat-drawer-container {
    width: 100%;
    position: fixed;
    right: 0;
    bottom: 0;
    height: 100%;
    display: none;
    z-index: 100;
    &.auto-width{
        width: auto;
    }
    &.hide {
        display: none !important;
    }

    &.mat-drawer-container-has-open {
        display: block;
    }

   
    .global-filter-side-nav {
        min-width: 522px;
        background: $whitecolor;
        box-shadow: -10px 0px 14px rgba(30, 32, 54, 0.1);
        z-index: 10;
        width: 40%;
        .mat-drawer-inner-container {
            overflow: hidden;
        }
    }
    
    .mat-drawer-backdrop.mat-drawer-shown {
        background-color: rgba(255, 255, 255, 0.2);
        -webkit-backdrop-filter: blur(2px);
        // backdrop-filter: blur(2px);
    }
}
.cloud-scan-sidenav  {
    // min-width: 350px;
    background: $whitecolor;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    max-width: 350px;
    width: 350px;
    position: fixed;
    margin: 18px 16px;
}
.new-scan-side-nav-container{
    @extend .global-filter-main;
    .new-scan-side-nav{
        min-width: 350px;
        background: $whitecolor;
    }
    .mat-drawer-inner-container {
        overflow: hidden ;
    }
   .mat-tab-body-content {
        overflow: hidden !important;
       }
}

.global-filter-main11.mat-drawer-container {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0; /* Adjusted 'bottom' to 'top' */
    height: 100%;
    display: none;
    z-index: 100;

    &.hide {
        display: none !important;
    }

    &.mat-drawer-container-has-open {
        display: block;
    }

    .global-filter-side-nav {
        min-width: 522px;
        background: $whitecolor;
        box-shadow: -10px 0px 14px rgba(30, 32, 54, 0.1);
        border-radius: 12px 0px 12px 12px;
        z-index: 10;
        width: 50%;
        .mat-drawer-inner-container {
            overflow: hidden;
        }
    }
    .mat-drawer-backdrop.mat-drawer-shown {
        background-color: rgba(255, 255, 255, 0.2);
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
    }
    &.mat-sidenav-container {
        .mat-drawer-backdrop.mat-drawer-shown {
            display: none;
        }
    }
}

.mat-sidenav {
    box-shadow: 2px 0px 50px 0px rgba(0, 0, 0, 0.27) !important;
}

.users_header {
    position: absolute;
    top: -44px;
    right: 228px;
    z-index: 5;
}

.loading-text {
    position: absolute;
    width: 100%;
    height: 20px;
    text-align: center;
    top: 50%;
    margin-top: -10px;
    left: 0px;
    color: #F1EFFF;
    font-size: 13px;
}

// .personal_details .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
//     transform: translateY(-1.75em) scale(0.95);
// }

.card-title {
    //color: $darkcharcoal;
    font-weight: 700;
    font-size: 14px;
}

.org-levels input.mat-input-element {
    height: 30px;
    line-height: 30px;
}

.org-levels .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0px 0.75em 0.75em 0.75em !important;
}
.org-levels .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0px;
}

.org-levels .mat-form-field-appearance-fill .mat-form-field-label {
    margin-top: 0;
    line-height: 20px;
}

.org-levels .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.79375em) scale(0.85);
    width: auto;
    max-width: 100%;
    margin-top: -8px;
}

.org-levels .mat-form-field-label-wrapper {
    overflow: visible;
}

.details-right .details-info .mat-form-field {
    width: 100%;
    height: calc(100vh - 500px);
    min-height: 100px;
}

.details-right .details-info .mat-form-field-appearance-outline .mat-form-field-outline {
    height: calc(100vh - 500px);
    min-height: 100px;
}

.details-right .details-info .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    height: calc(100vh - 500px);
    min-height: 100px;
}

.details-right .details-info .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    color: rgb(0 0 0 / 60%);
}

.details-right .details-info textarea.mat-input-element.cdk-textarea-autosize {
    resize: none;
    height: calc(100vh - 534px) !important;
    min-height: 100px;
}

.details-right :host ::ng-deep .filter-section {
    height: calc(100vh - 80px);
}

.mat-dialog-title {
    word-break: break-all;
}

.profile_page .mat-form-field-label-wrapper {
    overflow: visible;
}

.whitelistdailogbox .mat-dialog-container {
    padding: 0px !important;
}

.filter-icon {
    .mat-icon-button {
        position: relative;
        width: 20px;
        height: 18px;
        line-height: 18px;

        .mat-icon {
            line-height: 18px;
            width: 21px;
            height: 18px;
            font-size: 16px;
            color: $stormgray;
        }

        &:hover {
            .mat-icon {
                color: $darkblackcolor;
            }
        }

        &.applied {
            position: relative;

            &::after {
                position: absolute;
                content: "";
                width: 5px;
                height: 5px;
                border-radius: 100px;
                display: block;
                background: #ff5b5b;
                top: 2px;
                right: -2px;
            }
        }
    }
} 
// .mat-sort-header-arrow {
//     display: none !important;
// } 
.mat-tooltip{
    &.remove-overflow{
        overflow: visible;
        word-break: break-word;;
    }
}
.mat-tooltip, .tooltip {
    // -webkit-hyphens: auto;
    // -moz-hyphens: auto;
    // -ms-hyphens: auto;
    // hyphens: auto;
    font-size: 10px;
    margin: 0px !important;
    font-weight: 600;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
} 
tooltip.tooltip {
    font-size: 10px;
    &.cve-tooltip-width{
        max-width: 300px;
    }
}
.multiline-tooltip{
    white-space: pre-line !important;
    word-wrap: break-word;
    width: 90px;
    line-height: 20px;
    font-size: 12px;
}
.textarea-field.mat-form-field-appearance-outline .mat-form-field-outline {
    height: 120px;
} 
.green {
    color: $junglegreen !important;
}

.black {
    color: $nightblue !important;
}

.monaco-diff-editor.vs .diffOverview {
    display: none;
}

.editor-container {
    width: calc(100% + 30px) !important;
}

.decorationsOverviewRuler,
.monaco-editor .vertical {
    width: 5px !important;
}

.decorationsOverviewRuler {
    border: 1px solid #e9e9e9;
}

.scan-radio-button .mat-radio-label {
    white-space: normal !important;
}

.tool_managementright .filter-section.has-second-level {
    @media screen and (max-width: $tab) {
        height: auto !important;
    }

}

// .security_score .apexcharts-inner {
//     transform: translate(0, 0) !important;
// }
.common-vul {
    .chart-section {
        height: 100%;
    }
}

::ng-deep .mat-checkbox-layout .mat-checkbox-label {
    width: calc(100% - 24px);
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.benchmark-mapping {
    height: 30px;
    display: flex;
        align-items: center;
       

    .benchmark-text {
        color: $aquamarine;
        font-weight: 700;
        position: relative;
        line-height: 16px;
        display: inline-block;
    }

    .infoicon {
        margin-left: auto;
        // display: flex;

        mat-icon {
            color: $aquamarine;
            position: relative;
            cursor: pointer;
            font-size: 18px;
            height: 17px;
        }
    }
}

.mat-dialog-container {
    padding: 20px !important;
}

.loading {
    position: relative;

    .bar {
        background-color: #E7E7E7;
        height: 14px;
        width: 80%;
        margin-top: 5px;

        &:first-child {
            margin-top: 0px;
        }
    }

    &:after {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 24px;
        background-image: linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 80%);
        background-size: 200px 24px;
        background-position: -100px 0;
        background-repeat: no-repeat;
        animation: loading 1s infinite;
    }
} 
/*Loader section */

.list-loader {
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
    padding: 10px;
    .listdot-loader {
        align-self: center;
        height: 12px;

        .circle {
            margin: 0px !important;
        }
    }

    .text-loader {
        width: 85%;
        height: 15px;

        .skeleton-loader {
            height: 15px;
        }

    }

    .box-loader {
        margin-left: auto;
        width: 15px;
        height: 15px;
        border-radius: 4px;
        margin-right: 10px;
        .skeleton-loader {
            height: 15px;
        }
    }
}

.ag-loader{
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    margin-top: 10px;
    padding: 0px 15px;
    > div {
        flex: 1;
        overflow: hidden;
    }
}
.table-loader {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    margin-top: 10px;
    padding: 0px 15px;

    >div:first-child {
        width: 4% !important;
    }

    >div:nth-child(2) {
        width: 40% !important;
    }

    >div:nth-child(3) {
        width: 11% !important;
    }

    >div:nth-child(4) {
        width: 13% !important;
    }

    >div:nth-child(5) {
        width: 13% !important;
    }

    >div:nth-child(6) {
        width: 9% !important;
    }
    
    &.cyber-reilience-loader{
        padding: 0;
        >div:first-child {
            width: 44% !important;
        }
    
        >div:nth-child(2) {
            width: 53% !important;
        }
        .skeleton-loader{
             background-color: rgb(239, 241, 246);
        }
    }
}

.rhs-content-loader {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    margin-top: 16px;
    padding: 0px 15px;

    &.tab-content-loader {
        >div {
            width: 25%;
            border-radius: 0;
            height: 15px;
            margin-bottom: 0;
        }
    }

    &.selected-severity-loader {
        >div {
            width: 100%;
            border-radius: 0;
            height: 40px;
            margin-bottom: 10px;
        }
    }

    &.white-list-history-loader {
        >div {
            width: 50%;
            border-radius: 15;
            height: 15px;
            margin-bottom: 0;
        }
    }

    &.white-list-history-detail-loader {
        >div {
            width: 100%;
            border-radius: 0;
            height: 25px;
            margin-bottom: 10px;
        }
    }

    &.beach-mark-loader {
        >div {
            width: 20%;
            border-radius: 0;
            height: 15px;
            margin-bottom: 10px;
        }
    }
    
    &.graph-section-loader {
        >div {
            width: 40%;
            height: 70px;
            border-radius: 0;
        }

        >div:first-child {
            border-radius: 70px 70px 0 0;
            margin-right: 10%;
        }
    }

    &.content-loader {
        >div {
            margin-bottom: 10px;
            border-radius: 0;
        }

        >div:nth-child(odd) {
            width: 30%;
            height: 15px;
        }

        >div:nth-child(even) {
            width: 100%;
            height: 100px;
            margin-bottom: 25px;
        }
    }

    &.tab-heading-loader {
        width: 20%;
        border-radius: 0;
        height: 15px;
        margin-bottom: 10px;
    }

    &.device-filter-loader {
        >div:first-child {
            width: 60%;
            margin-right: 15%;
            height: 40px;
        }

        >div:nth-child(2) {
            width: 20%;
            margin-top: 8px;
        }
    }

    &.device-details-loader {
        >div {
            height: 25px;
            border-radius: 0px;
        }

        >div:first-child {
            width: 5%;
            height: 10px;
            margin-top: 7px;
        }

        >div:nth-child(2) {
            width: 79%;
        }

        >div:nth-child(3) {
            width: 4%;
            height: 10px;
            margin-top: 7px;
        }

        >div:nth-child(4) {
            width: 4%;
            height: 10px;
            margin-top: 7px;
        }
    }

    &.region-graph-loader {
        >div {
            width: 80%;
            margin: 50px auto 0 auto;
            height: 100px;
            border-radius: 100px 100px 0 0;
        }
    }

    &.region-content-loader {
        >div {
            width: 31%;
            margin: 10px 0;
            height: 30px;
            background-color: #D7D5EC;
        }
    }

    &.region-legend-loader {
        width: 50%;
        margin: 0px auto;

        >div {
            width: 45%;
            margin-left: 2%;
            margin-top: 70px;
        }
    }

    &.region-level-loader {
        gap: 0;
        padding: 0 5px;

        >div {
            height: 40px;
            width: 96%;
            margin-left: 4%;
        }
    }

    &.search-tab-loader {
        margin-top: 30px;

        >div {
            width: 15%;
            height: 30px;
            border-radius: 20px;
        }
    }

    &.search-result-header-loader {
        margin-top: 20px;
        margin-bottom: 20px;

        >div {
            width: 12%;
            height: 15px;
        }
    }

    &.search-result-loader {
        >div {
            height: 25px;
            width: 18%;
        }
    }
    &.head {
        > div {
            display: flex;
            height: 30px;
            margin-bottom: 25px;
            &:first-child {
                width: 30%;
                margin-right: 30%;
            }
            &:nth-child(2) {
                width: 10%;
            }
            &:nth-child(3) {
                width: 10%;
            }
            &:nth-child(4) {
                width: 10%;
            }
        }
    }
    &.global-search-loader{
        > div {
            height: 40px;
        }
    }
    &.checkbox-box-loader{
        > div{
            width: 20px;
            height: 20px;
            &:nth-child(2) {
                width: calc(98% - 20px);
            }
        }
    }
}

.white-list-table-loader {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    margin-top: 10px;
    padding: 0px 15px;

    >div:first-child {
        width: 4% !important;
    }

    >div:nth-child(2) {
        width: 50% !important;
    }

    >div:nth-child(3) {
        width: 17% !important;
    }

    >div:nth-child(4) {
        width: 12% !important;
    }

    >div:nth-child(5) {
        width: 9% !important;
    }
} 
.no-data {
    color: $manatee;
    font-size: 13px;
    text-align: center;
    line-height: 18px;
    left: 0px;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    padding: 0px 15px;
    &.index {
        z-index: 1;
    }
    p {
        padding-bottom: 6px;
    }
}
.severity-label-loders {
    width: 100%;
    .severity-loader{

        .skeleton-loader {
            height: 40px;
            margin-bottom: 16px;
            margin-right: 10px;
            width: 44%;
            display: inline-block;
        }
    }
}
.cve-loader {
    .new-cve-loader{
        .skeleton-loader{
            // margin-bottom: 14px;
            height: 44px;
        }
    }

}
.cve-data-loader{
    width: 100%;
    .cve-data-value {
        display: flex;
        grid-template-columns: repeat(2, auto); 
        gap: 5px;
        .skeleton-loader{
            margin-bottom: 14px;
            height: 44px;
        }
    }
}
.category-loader {
    margin-top: 50px;
    padding: 20px 0;
    width: 100%;
    text-align: center;

    .category-bar {
        margin-top: 20px;
        width: 100%;
        gap: 2%;
        height: 25px;
        display: flex;
        flex-direction: row;
        text-align: center;

        .skeleton-loader {
            height: 25px;
            width: 30% !important;
            margin-bottom: 20px;

            &:last-child {
                width: 65% !important;
            }
        }
    }
}

.canvas-loader {
    margin:0 30px !important;
    .vertical-bar{
        margin-right: 41px;
    
    .skeleton-loader.circle{
        margin: 0px !important;
    }
}
    .horizontal-bar{
        .skeleton-loader
        {
            border-radius: 0px !important;
        }
    }
}

.security-score-loader {
    @media screen and (max-width: $tab) {
        justify-content: flex-start !important;
    }
    width: 100%;
    text-align: center;
    padding: 20px;
    align-items: center;
    display: flex;
    .apx-chart-loader {
        width: 100%;
        .circle {
            margin: 0px !important;
            @media screen and (max-width: $tab) {
                width: 60px !important;
                height: 60px !important;
            } 
        }
    }

    .chart-text-loader {
        width: 70%;
        height: 15px;

        .skeleton-loader {
            margin-top: 10px;
            height: 15px;
        }
    }
}

.vulnerability-loader {
    width: 100%;
    display: flex;
    flex-direction: row;
    .chart-loader {
        width: 40%;
        align-self: center;

        .circle {
            margin: 0px !important;
        }
    }

    .vunerability-value {
        width: 100%;       
    .skeleton-loader {
        display: flex;
        gap: 1%;
        .progress{
            height: 20px;
        }
    }
    }

    &.acc-parameter-graph{
        padding-left: 10%;
        .skeleton-loader.progress{
            height: 20px;
        }
        .vunerability-value{
            grid-template-columns: repeat(1, auto); 
            gap: 1px;
            width: 40%;
            padding-left: 20px;
        }
    }
    
}
.summary-data-loaders {
    width: 100%;
    display: flex;
    flex-direction: row;
    .summary-data-value {
        width: 100%;       
    .skeleton-loader {
        display: flex;
        gap: 1%;
        .progress{
            height: 40px;
        }
    }
    }
}
.device-data-loader{
width: 100%;
.device-data-value {
    display: flex;
    grid-template-columns: repeat(3, auto); 
    gap: 5px;
    .skeleton-loader{
        margin-bottom: 14px;
        height: 44px;
    }
}
}
.devices-with-loader {
    display: flex;
    margin: 10px 8px 20px 10px;
    gap: 12px;

    .devices-with-data-loader {
        width: 100%;
        height: 35px;

        .skeleton-loader {
            height: 35px;
            margin-top: 5px;
        }
    }
    .device-graph-loader {
        height: 35px;
        width: 4%;
        .skeleton-loader.progress{
            margin-left: 0px ;
            margin-right: 0px;
        }

        .circle {
            margin-top: 2px !important;
        }
        &.device-score-loader{
            top: 5px;
            position: relative;
        }
       
    }
}
.btn-loader {
    display: none;
}
@keyframes spin {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
  }
.loader-btn { 
    display: flex;
    align-items: center; 
    justify-content: center; 
    gap: 3%;
    .btn-loader {
        width: 16px;
        height: 16px;
        display: flex;
        img  {
            width: 100%;
        }
        &.spin {
            -webkit-animation: spin 2s infinite linear;
            animation: spin 2s infinite linear;
       
        }
    }
}
.disabled-link {
    pointer-events: none;
}
.createrole-input .mat-form-field-appearance-fill .mat-form-field-infix {
    margin-top: -7px;
}

.createrole-input .mat-form-field-label-wrapper {
    overflow: visible;
}

.createrole-input .mat-form-field-appearance-outline .mat-form-field-label {
    line-height: 18px;
}
.log-info .mat-form-field-appearance-outline .mat-form-field-outline {
    height: 32px !important;
}
.log-info .mat-form-field-label {
    top: 22px !important;
} 
.log-info .mat-form-field {
   line-height: 1.17 !important;
} 
.log-info .mat-form-field-appearance-outline .mat-form-field-infix { 
    margin-top: 0px;
    padding-top: 5px;
}
.scan-details-container {
    .details-info-list { 
      height: calc(100vh - 440px) !important; 
    }
  }
  .apply-btn {
    width: 90%;
    margin: 5%!important;
    border-radius: 6px;
    border: 1px solid $whitecolor !important;
    background: transparent;
    color: $whitecolor;
}
.custom-disabled {
    color: $whitecolor;
    opacity: 1; 
  }
.remember_link .mat-checkbox-layout .mat-checkbox-label {
    color: rgb(0 0 0 / 50%)!important;
    font-weight: 400; 
} 
.tooltip{
    word-wrap: break-word;

  &::after{
    display: none;
  }
  &.bar-tooltip {
    background-color: $darkblackcolor;
    color: $whitecolor;
    font-size: 12px;
    text-align: left;
    line-height: 18px;
    br {
        padding: 3px 0;
    } 
}
}
// .mat-drawer.mat-drawer-end { 
//     transform: translate3d(0%, 0, 0)  !important;
// }
.sso-popup {
    .mat-dialog-container {
        padding: 25px 30px !important;
        .netsec-modelbox {
            h2 {
                text-align: center;
                font-size: 18px !important;
                line-height: 24px !important;
                font-weight: 800 !important;
                word-break: break-word !important;
                padding-bottom: 10px !important;
            }
            p {
                text-align: center;
                font-size: 13px !important;
                line-height: 18px !important;
                font-weight: 400 !important;
                word-break: break-word !important;
                color: rgb(0 0 0 / 50%) !important;
                padding-bottom: 10px !important;
            }
            .modelbox-footer {
                display: flex;
                gap: 6%;
                .btn-grayborder {
                    border: 1px solid $dimgray;
                    color: $dimgray;
                }
                .mat-button {
                    width: 47%;
                }
            } 
        }
    }
    .admin_section .mat-tab-body-content {
        transform: translate3d(0%, 0px, 0px) !important;
        overflow: visible;
    
    } 
}

.netsec-modelbox {
    h2 {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 9px;
        text-align: center;
    }
    p {
        font-size: 16px;
        line-height: 21px; 
        font-weight: 700;
        text-align: center;
    }
    .modelbox-body {
        p {
            font-size: 13px;
            line-height: 18px; 
            text-align: center;
        }
    }
    
    .modelbox-footer {
        margin-top: 20px;
        display: flex;
        gap: 15px;
        align-items: center;
        justify-content: center;
        .mat-button {
            width: 40%;
        }
        .filled-btn {
            background: $pastelred;
            color: $whitecolor;
        }
        .filled-btn-1{
            background: $aquamarine;
            color: $whitecolor; 
        }

    }
} 
.right-side-si .semin-donut-chart {
    @media screen and (max-width: 1365px) and (min-width: 1025px) {
      left: 50% !important;
      transform: translateX(-50%);
    }
}
.disable-telnet .semin-donut-chart {
    @media screen and (max-width: 1365px) and (min-width: 1025px) {
      left: 50% !important;
      transform: translateX(-50%);
    }
}
::-ms-reveal {
    display: none;
}
.date-range-section .mat-form-field-appearance-legacy .mat-form-field-label {
    transform: perspective(100px);
    top: 1px;
    line-height: 24px;
}
.date-range-section, .date-range-section-filter {
    .mat-form-field-infix { 
        width: 120px;
        padding: 0px;
        border: 0px;
    }
} 
.date-range-section-filter{
    input.mat-input-element{
        height: 22px;
    }
    .mat-form-field-infix { 
        margin-left: 6px;
    }
}

.date-range-section .mat-form-field-label-wrapper {
    top: 0px;
    padding: 0px;
}
mat-datepicker-toggle  .mat-icon-button {
        width: 20px;
        height: 20px;
        line-height: 20px;
    } 
.date-range-section input.mat-input-element { height: 24px; }
.date-range-section .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label { display: none; }

.sort-icon{
    height: 18px;
    width: 21px;
    line-height: 24px;
    vertical-align: middle;
    font-size: 20px!important;
    color: $stormgray;
 
    &.sort-icon:hover{
        color: $darkblackcolor;
    }
}

.scan-details-container .telent-content .log-item-list-scroll {
    height: calc(100vh - 280px) !important;
    border-radius: 0 0 8px 8px;
}
.scan-details-container .telent-content .log-info .input-box-container.mat-form-field {
    line-height: 1.17 !important;
}
::ng-deep .terms-condition-container .list-content ul,
li {
    list-style-type: circle !important;
    margin: 10px !important;
}
  ::ng-deep .terms-condition-container .list-content ol,
  li {
      list-style-type: lower-alpha !important;
      margin: 10px !important;
  }
  .mat-menu-panel .mat-menu-content {
    .severity-icons{
        .mat-menu-item {
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 20px;
            padding-top: 4px;
            padding-bottom: 4px;
            &:hover {
                background-repeat: no-repeat;
            }
        }
    }
}

  /* Spark new design CSS*/
  body {
    font-family: 'Manrope', sans-serif;
    font-size: 13px;
    color: #000;
    background: $snowgray; 
  }
  .spark-whitebox {
    border-radius: 8px; 
    padding: 16px 20px; 
    &.padd4{
        padding-bottom: 4px;
    }
    .control-panel-col-header {
        padding: 0px;
        align-items: center;
        margin-bottom: 16px;
        &.mb0 {
            margin-bottom: 0px;
        }
        h3 {
            padding: 0px;
        }
    }
    &.p0 {
        padding: 0px;
        .control-panel-col-header {
            padding: 12px 16px;
        }
    }
  }
  .device-popup {
    .spark-whitebox { 
        .control-panel-col-header { margin-bottom: 6px; }

    }
  }
  
  .spark-whiteboxshadow {
    box-shadow: 0px 1px 16px 0px #0000000D;
  }
  

  .spark-sec-tittle {
    color: #222222; 
    font-size: 13px;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
    padding-bottom: 26px;
    margin: 0px;
    &.low-padding {
        padding-bottom: 18px !important;
    }

  }
  .no-transform{
    text-transform: none;
  }
  .vultooltip-section{
        padding: 15px;
      //background-color: #fff !important;
        position: relative;
        overflow: visible !important;
        border: none !important;
        margin-left: -50px;
        margin-top: -50px;

      //box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 12px 8px !important;
        &::after {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            bottom: -10px;
            left: 50%;
            border-left: 16px solid transparent; 
            border-right: 16px solid transparent; 
            //border-top: 19px solid #fff; 
            margin-left: -16px;
        }

         div {
            //color: rgb(58, 42, 218);
            font-weight: 700;
            padding-bottom: 2px;

            span{
                //color: rgb(105, 101, 149);
                font-weight: 400;
            }
        }
        .serv-badge{
            display: inline-block;
            padding: 2px 4px;
            border-radius: 2px;
            font-weight: 800;
            text-transform: capitalize;
        }
    }
  .sparkdata-dividerslist {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 24px 2%;
  //  justify-content: space-around;
    align-items: stretch;
    --bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Crect width='1' height='1' fill='%ccc'/%3E%3C/svg%3E"); 
    background-repeat: repeat-y;
    background-position-x: 33%, 66%;
    background-position-y: top;
    background-clip: content-box;
    background-size: 2px 2px;
    background-image: var(--bg-image), var(--bg-image), var(--bg-image);
    margin-bottom: 0px;
    // display: inline-block;
    text-align: center;
    width: 100%;
    margin-top: -14px;
    .sparkdata-item {
        display: inline-block;
        text-align: left;
        // width: 32%;
        margin: 16px 0;
        .m-box{
            max-width: 168px;
        }

        .spark-label { 
            font-size: 16px;
            font-weight: 800;
            line-height: 22px;  
            display: flex;
            align-items: center;
            padding-bottom: 5px;
            .sparkdata-icon {
                padding-right: 10px;
                display: inline-flex;
            }
            sub {
                padding-left: 6px; 
                line-height: 10px;
                vertical-align: sub;
                font-size: 9px;
                color: #8480AD ;
                margin-bottom: -5px;
                font-weight: 600;
            }
        }
        .spark-value { 
            font-size: 12px;
            font-weight: 600;
            line-height: 16px; 
            color:  #8480AD;
        }
    } 
    
    &.three-items {
        .sparkdata-item { 
            &:nth-child(2), &:nth-child(3),  &:nth-child(5), &:nth-child(6) {
                border-left: 1px solid #CCCAE7;
                padding-left: 10px;
            }
        }  

    }
    // &.sumbox-child-6{
    //     text-align: left;
    // }
    // &.sumbox-child-5{
    //     text-align: left;
    // }
    &.sumbox-child-4{
        text-align: center;
        padding-left: 8%;
        margin-top: -10px;
        vertical-align: middle;
        .sparkdata-item{
           width: 37%;
           &:nth-child(2),  &:nth-child(4) {
            border-left: 1px solid #CCCAE7;
            padding-left: 10px;
          }
          &:nth-child(3) {
            border: none;
            padding-left: 10px;
          }
        }
    }
    &.sumbox-child-3{
        vertical-align: middle;
        margin-top: 30px;
        padding-left: 5%;
    }
    &.sumbox-child-2{
        vertical-align: middle;
        margin-top: 30px;
        padding-left: 12%;
    }
    &.sumbox-child-1{
        vertical-align: middle;
        margin-top: 30px;
        padding-left: 12%;
    }
  }

  .spark-btngroup {
    .mat-button-toggle-group-appearance-standard {
        border: solid 1px #D7D5EC;
        height: 28px
    }
    .mat-button-toggle-appearance-standard { 
        background: #F2F1FE;
        color: #8480AD; 
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em; 
    }
    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        line-height: 28px;
    }
    .mat-button-toggle-group-appearance-standard .mat-button-toggle {
        border: 0px;
    } 

    .mat-button-toggle-appearance-standard:hover { 

        background: #3A2ADA;
        color: $whitecolor;
    }
    .mat-button-toggle.active {
        background: #3A2ADA;
        color: $whitecolor;
      }
    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        padding: 0 6px;
    }
  } 
.spark_table_schedule {
    box-shadow: none;
    width: 100%;
    tr.mat-row, tr.mat-footer-row {
        height: 45px !important;
    }
    .mat-header-row {
        height: auto !important;
    }
    .mat-header-cell {
        // color: #2B335F; 
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;  
        padding: 8px 16px;
    } 
    th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
        padding-left: 16px;
    }
    .mat-row {
        .mat-cell {
            border: 0px; 
            padding: 12px;
            // color: #696595; 
            font-size: 13px;
            font-weight: 600;
            line-height: 18px;  
            &.text_black {
                color: #000000; 
            }
            &::after {
                content: '';
                display: block;
            }
            span.text_ellipsis {
                display: inline-block;
                max-width: 100%;
            }
        }
        // &:nth-child(odd) {
        //     background:#F8F8FF;  
        // } 
        // &:hover {
        //     background: #E7E6F3;
        // }
    } 
    th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
        padding-right: 24px;
    }
}
.spark_table {
    box-shadow: none;
    width: 100%;
    tr.mat-row, tr.mat-footer-row {
        height: 45px !important;
    }
    .mat-header-row {
        height: auto !important;
    }
    .mat-header-cell {
        color: #2B335F; 
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;  
        padding: 8px 16px;
    } 
    th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
        padding-left: 16px;
    }
    .mat-row {
        .mat-cell {
            border: 0px; 
            padding: 12px;
            color: #696595; 
            font-size: 13px;
            font-weight: 600;
            line-height: 18px;  
            &.text_black {
                color: #000000; 
            }
            &::after {
                content: '';
                display: block;
            }
            span.text_ellipsis {
                display: inline-block;
                max-width: 100%;
            }
        }
        // &:nth-child(odd) {
        //     background:#F8F8FF;  
        // } 
        // &:hover {
        //     background: #E7E6F3;
        // }
    } 
    th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
        padding-right: 24px;
    }
}
.whitelisted-label {
    display: inline-flex;
    border-radius: 100px; 
    padding: 2px 10px 2px 2px; 
    align-items: center;
    
    span.whitelisted-text {
        color: $whitecolor;
        font-size: 10px; 
        line-height: 15px;
        font-weight: 600;
        display: inline-block;
    }

    span.whitelisted-value { 
        font-size: 10px;
        border-radius: 100px;
        background: $whitecolor; 
        min-width: 20px; 
        text-align: center;
        font-weight: 700;
        line-height: 14px;
        display: inline-block;
        margin-right: 4px;
        padding: 0px 3px;
    }
    &.no-count {
        padding: 2px 10px;
    }
}
/* Table styles */ 
  .mat-row{
    border-bottom-width: 0px !important;
    border: none;
    
}
.mat-header-row {
    border: none;
}
.cybergraph-zoomin {
    max-width: none !important;
    .mat-dialog-container {
        border-radius: 12px !important ;
    }
}
.devicepopup, .device-popup { max-width: none !important;
border-radius: 12px !important;  }

.device-popup .vulnerabilty-chart .pie-chart {
    height: 110px !important;
    width: 130px !important; 
}
.device-popup  .device-data-right app-common-vulnerability {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.device-popup  .device-data-right .chart-section .vul-list  {  
    height: calc(100vh - 234px) !important;
     @media screen and (max-width: $medium) {
        max-height: 380px !important;
    }
}
th.mat-header-cell {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $whitecolor;
}
.doc-persentage {
    font-weight: 500;
    position: relative;
    font-size: 12px;
    line-height: 15px !important;
    min-width: 80px;
  }
  .status-options {
    color: $whitecolor;
    padding: 7px 0px;

  }
    .globalsearch-popup {
        .mat-dialog-container {
            padding: 16px 0px !important;
        }       
    }
   
.myaccount-popup {
    .mat-drawer.mat-drawer-end {
        // border-radius: 12px;
        // margin: 18px;
        width: 360px;
        .mat-drawer-inner-container {
            overflow: hidden !important;
        }

    }
}
/*--------------- My account tabs -------------- */

.commonvulnarabilites-tab {
    .mat-tab-header { 
        padding: 12px 16px 0px;
        background: transparent;
        border-bottom: 1px solid #DBDBEF;
        .mat-tab-label-container {
            .mat-tab-list {
                .mat-tab-labels {
                    .mat-tab-label {
                        flex-basis: auto;
                        flex-grow: unset;
                        padding: 12px;
                        border-color: transparent;
                        &:first-child {
                            padding-left: 0px;
                        }
                        .mat-tab-label-content {
                            color: #8787AE;
                            font-size: 13px;
                            font-weight: 800;
                        }
                        &.mat-tab-label-active {
                            border-bottom: 3px solid $aquamarine;
                        }
                    }
                }
            }
          }
          
    }
    .mat-tab-body {
        padding: 0px;
        height: calc(100vh - 325px);
        .mat-tab-body-content{
            overflow: hidden;
            .tabcontent {
                height: 100%;
                .mat-card {
                    padding: 0px;
                    height: 100%;
                    .tabel-height{
                        height: 100%;
                    }
                }
            }
        }

        
    }
}

.asset-tabgroup {
        .mat-tab-body {
                padding: 0px !important;
            }
    }
    .newscan-tabgroup {
        height: calc(100vh - 104px) !important;
        padding-top: 20px;
        .mat-tab-header {
            background: transparent;
            margin: 0px;
            box-shadow: none !important;
            border-bottom: 1px solid #D9D9D9 !important;
            .mat-tab-label-container {
              .mat-tab-list {
                  .mat-tab-labels {
                      .mat-tab-label {
                          flex-basis: auto;
                          flex-grow: unset;
                          padding: 12px;
                          border-color: transparent;
                          &:first-child {
                              padding-left: 0px;
                          }
                          .mat-tab-label-content {
                              color: #8787AE;
                              font-size: 13px;
                              font-weight: 800;
                          }
                          &.mat-tab-label-active {
                              border-bottom: 3px solid $aquamarine;
                              .mat-tab-label-content {
                                  color: $aquamarine; 
                              }
                          }
                      }
                  }
              }
            }
          }
    }
.asset-tabgroup,.account-tabgroup {
    .mat-tab-header {
      background: transparent;
      padding: 0px 16px;
      .mat-tab-label-container {
        .mat-tab-list {
            .mat-tab-labels {
                .mat-tab-label {
                    flex-basis: auto;
                    flex-grow: unset;
                    padding: 12px;
                    border-color: transparent;
                    &:first-child {
                        padding-left: 0px;
                    }
                    .mat-tab-label-content {
                        color: #8787AE;
                        font-size: 13px;
                        font-weight: 800;
                    }
                    &.mat-tab-label-active {
                        border-bottom: 3px solid $aquamarine;
                        .mat-tab-label-content {
                            color: $aquamarine; 
                        }
                    }
                }
            }
        }
      }
    }
    .mat-tab-body {
        padding: 20px 0px 0px;
        height: calc(100vh - 128px);
        .asset-tabcontent {
            height: 100%;
        }
        
    }
}

.newscan-popup-container {
    .mat-card {
        box-shadow: none;
    }
}
.plans-box {
    width: 915px !important;
    max-width: 95% !important;
    position: relative !important;
}
.netsec-expiredbox {
    text-align: center;
    position: relative; 
    .close-btn {
        right: 12px;
        top: 12px;
        position: absolute;
        padding: 0px; 
        min-width: auto;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        background: $closebackground;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        .mat-icon {
            font-size: 15px;
            line-height: 20px;
            width: 20px;
            color: $whitecolor;
        }
    }
    .expiredbox-image {
        padding-bottom: 24px;
    }
    h3 {
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
        padding-bottom: 8px;
        margin: 0px;
    }
    p{
        font-size: 14px;
        line-height: 18px;
        color: $dimgray;
        padding-bottom: 8px;
    }
    .expiredbox-btns {
        margin: 16px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        &.multi-btns {
            display: flex;
            align-items: center;
            gap: 20px;
            justify-content: center;
            .btn {
                padding: 0px 20px !important;
                border-left: 1px solid $gainsboro !important;
                &:first-child {
                    border-left: 0px !important;  
                }
            }
        }
    }
}
.account-tabgroup {
    .mat-tab-body {
        padding: 20px 0px 0px;
        height: calc(100vh - 92px) !important;
    }

}
.add-vpn .mat-dialog-container {
    overflow: hidden;
    border-radius: 4px;
}
.invite-user .mat-dialog-container {
    overflow: hidden;
    border-radius: 12px;
}
.trail-box {
    min-width: 380px !important;
    max-width: 480px !important;
    width: auto !important;
}
.dowload-report-box {
    min-width: 380px !important;
    max-width: 380px !important;
    width: auto !important;
}
.cdk-global-scrollblock {
    overflow: hidden !important;
} 
  .white-box-header {
    padding: 16px;
    border-bottom: 1px solid $grayborderlight;
    height: 55px;
  }
//   .search-input {
//     border: 0px;
//     outline: none;
//     font-weight: 400;
//     font-size: 13px;
//     color: #80809E;
//     background-color: #F5F5FF;
//     vertical-align: super;
//     width: 100%;
//     height: 36px;
//     padding: 0px 100px 0px 30px;
//     outline: none;
//   }
  .mat-button-ripple.mat-ripple, .mat-button-focus-overlay {
    display: none;
  }
  .history-popup .mat-dialog-container {
    padding: 0px !important;
  }
  .device-popup-row .textarea-field.mat-form-field-appearance-outline .mat-form-field-outline {
    height: 80px;
  }
  .device-popup-row .textarea-field.mat-form-field-appearance-outline .mat-form-field-outline {
    height: 80px;
  }
  .mat-form-field-appearance-fill.no-bg .mat-form-field-flex, .mat-form-field-appearance-outline.no-bg .mat-form-field-outline {
     background: transparent !important; 
    }
    
    .mat-form-field-appearance-outline.no-bg .mat-form-field-outline.mat-form-field-suffix.mat-datepicker-toggle {
        position: relative;
        top: -3px;
        font-size: 14px;
    }
  
.mat-form-field-appearance-fill.no-bg .mat-form-field-flex, .mat-form-field-appearance-outline .mat-form-field-outline { background: transparent !important; }
.spark-btngroup {
    height: 28px;
    &.mat-button-toggle-appearance-standard {
        color: rgba(0, 0, 0, 0.87);
        background: $gainsboro;
        color: $grayundertone;
        font-family: Manrope,sans-serif;
      .mat-button-toggle-label-content  {
        line-height: 28px;
      } 
      &.mat-button-toggle-checked {
        background: $aquamarine;
        color: $whitecolor;
      }  
    }
    .mat-button-toggle + .mat-button-toggle {
        border-left: 0px  !important;
    }
} 
.spark-accordion-tab {
    background-color: $graybackground;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 10px;
    .spark-accordion-tabheader {
        position: relative;
        padding-bottom: 6px;
        p { 
            font-size: 13px;
            font-weight: 700;
            line-height: 15px;  
            color: $darkblackcolor;
        }
        .accordion-arrow {
            position: absolute;
            right: -4px;
            top: -4px;
            color: #79799A;
        }
    }
}
 
.new-scan-popup {
    .mat-drawer.mat-drawer-end {
        // border-radius: 12px;
        // margin: 18px;
        width: 360px;
        padding: 16px;
    }
}

.tabcontent {
    .new-scan-box {
        
      .header-box { 
        .new-scan-boxtitle {
          display: none;
        }
      }
      .scancontent-box {
        // form {
        //     min-height: calc(100vh - 186px) !important;
        //   }
        // .api-form {
        //   form {
        //     min-height: calc(100vh - 200px) !important;
        //   }
        // }
      }
      &.upload-file-container {
        // height: 98% !important;
        .upload-browse-file {
            min-height: calc(100vh - 300px) !important;
        }
        .upload-submit {
            margin-bottom: 4px;
        }
      }
    //   .uploaded-files-section {
    //     .uploaded-list {
    //         height: calc(100vh - 454px) !important;
    //     }
    //   } 
    } 
  }
.profiletab-edit .mat-form-field { width:100% !important}
/*------tool management page---------------*/
.role-management-page  {
    .mat-tab-group {
        display: flex;
        flex-direction: row;
        max-width: 100%;
        width: 100%;
        gap: 16px;
        .mat-tab-header {
            background: transparent; 
            box-shadow: none;
            .mat-tab-label-container {
                background: $whitecolor;
                border: 1px solid $grayborderlight;
                box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
                border-radius: 10px;
                padding: 0px;
                position: relative;
                width: 203px;
                padding-top: 49px; 
                .mat-tab-list {
                    overflow: scroll;
                    overflow-x: hidden;

                } 
                .mat-tab-list::-webkit-scrollbar {
                    width: 5px;
                    right: 3px;
                }

                .mat-tab-list::-webkit-scrollbar-track {
                    right: 3px;
                }

                .mat-tab-list::-webkit-scrollbar-thumb {
                    background: $gainsboro;
                    right: 3px;
                }
                .mat-tab-labels {
                    flex-direction: column;
                    .mat-tab-label {
                        padding: 0px; 
                        justify-content: flex-start;
                        padding: 10px 15px;
                        color: $lightblack;
                        height: 36px;
                        margin: 0px;
                        border: 0px;
                        cursor: pointer;
                        &:nth-child(even) {
                            background: $ghostwhite;
                        }
                        &.mat-tab-label-active {
                            background: $aquamarine !important;
                            color: $whitecolor;
                            border: 0px;
                        }
                    }
                } 
            }
        }
        .mat-tab-body-wrapper {
            flex:1;
            background: $whitecolor;
            border: 1px solid $grayborderlight;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 0px;
            position: relative;
            .mat-tab-body-content {
                .role-custom-cta {
                    height: 61px;
                    align-items: center;
                    display: flex;
                    padding: 10px 15px;
                    border-bottom: 1px solid $gainsboro;
                }
            }
        }
    }
        .regions-devices {
            max-width: 500px;
    
            .tool_managementright-regioninner {
                width: 60%;
                margin:  0 auto;
            }
    
            .filter-section {
                height: 100%;
                margin-top: 10px;
                .sub-container {
                    width: 500px;
                    height: calc(100% - 140px);
                    flex-wrap: nowrap;
                }
    
                &.has-second-level {
                    .filter-row {
                        height: calc(100vh - 238px) !important;
                    }
                }
            }
        }
}  
.ngx-toastr {
    background-color: #030303;
    pointer-events: auto;
  }
.toast-container {
    box-shadow: none;
    button.toast-close-button{
        color: $whitecolor;
            z-index: 999;
            top: 11px;
            right: 14px;
            font-size: 18px;
            line-height: 12px;
            font-weight: 400;
            width: 17px;
            height: 17px;
            border-radius: 100%;
            background-color: $closebackground;
            position: absolute;
            &:hover {
                opacity: 1;
            }
    }
}
.toast-container .ngx-toastr {  
    border-radius: 2px;
    max-width: 444px; 
    width: auto;
} 
  .toast-success {
    background-color: #30994E;
    box-shadow: 0px 0px 5px 0px #30994E80;

  }
  .toast-error {
    background-color: #FF4B4B;
    box-shadow: 0px 0px 5px 0px #FF4B4B80;
  }
  .toast-info {
    background-color: #2F96B4;
  }
  .toast-warning {
    background-color: #E28025;
    box-shadow: 0px 0px 5px 0px #E2802580;

  }
.infocheck-list.mat-menu-panel {
    background: #fff;
    max-width: none;
    ul {
        margin: 0px;
        li {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
        }
    }
}
.change-location {
    .mat-toolbar-single-row {
        padding: 0px !important;
      }
      .filter-section {
        padding: 10px 0px !important;
        height: 100%;
        .sub-container {
            height: calc(100vh - 145px) !important;
            .filter-row {
                height: 100%;
            }
        }
      }
    .change-header {
        font-size: 16px;
        font-weight: 800;
        line-height: 22px;
        color: $darkblackcolor;
      
      }
    .close-sidebar{
        position: absolute !important;
        font-size: 30px;
        right: -19px !important;
        top: 0px !important;
        cursor: pointer;
        color: $closebackground;
    }
    .header-tool-content  {
        display: flex;
        justify-content: space-between !important;
        align-items: center !important;
        .page_header {
            margin-left: 25px;
            padding: 0px !important;
            display: flex;
            .page_header_right{
                .btn:last-child {
                margin-right: 0px;
                width: 30px !important;
                max-width: 30px !important;
            }
        }
        }
    }

}

.save-changes-popup {
    width: 360px !important;
    .modelbox-footer {
       margin-top: 35px !important;
    }
        .modelbox-body p {
            color: $dimgray;
            font-size: 13px;
            font-weight: 500;
            line-height: 18px;
        }
 }
.cancel-popup {
    .modelbox-footer {
      margin-top: 45px !important;
    }
  }
  .notification-popup {
    // height: calc(100vh - 60px);
    .notification-side-nav{
        .mat-drawer-inner-container {
            overflow: hidden;
        }
        max-width: 350px;
        width: 350px;
        position: fixed;
    }
}
.schedule-removeassetfilter {
    position: absolute !important;
    bottom: 6px;
    left: 185px;

    .mat-dialog-container {
        overflow: hidden !important;
        padding: 16px 16px 0px !important;
        border-radius: 12px;
        border: 1px solid $ash;
    }

    .mat-toolbar-single-row {
        padding: 0px !important;
    }

    .filter-section.has-second-level .filter-row {
        position: relative;
        width: 49%;
        height: calc(100vh - 464px) !important;
    }

    .sub-container {
        flex-wrap: nowrap !important;
        height: calc(100vh - 465px) !important;
    }

    .filter-section {
        padding: 15px 0px 0px !important;
    }

    .close-sidebar {
        right: -22px !important;
    }
}
.schedule-addassetfilter {
    position: absolute !important;
    bottom: 6px;
    right: 20px !important;
    .mat-dialog-container {
        overflow: hidden !important;
        padding: 16px 16px 0px !important;
        border-radius: 12px;
        border: 1px solid $ash;
    }

    .mat-toolbar-single-row {
        padding: 0px !important;
    }

    .filter-section.has-second-level .filter-row {
        position: relative;
        width: 49%;
        height: calc(100vh - 464px) !important;
    }

    .sub-container {
        flex-wrap: nowrap !important;
        height: calc(100vh - 465px) !important;
    }

    .filter-section {
        padding: 15px 0px 0px !important;
    }

    .close-sidebar {
        right: -22px !important;
    }


.tooltip-color{
    font-size:14px;
    color:#666;
    font-weight:400;
    line-height:1;
}
.tooltip-bottom{
    margin: 10px 0 0;
    line-height:1;
    .badge {
        display:inline-block;
        margin-right:4px;
        border-radius:10px;
        width:10px;
        height:10px;
        background-color:#5470c6;
    }
    .badge-value{
        float:right;
        margin-left:10px;
        font-size:14px;
        color:#666;
        font-weight:900
    }

}

}

.calender-popup .mat-dialog-container {
 padding: 0px !important;
 overflow: hidden;
}

.schedule-tool_switch {
    .mat-slide-toggle-bar {
      height: 25px !important;
      width: 55px !important;
      background-color: $aquamarine !important;
      border-radius: 36px !important;
    //   background-color: #dfe3f2 !important;
      position: relative;
    }
    .mat-slide-toggle-thumb {
      position: relative !important;
      background-color: $whitecolor !important;
      width: 20px !important;
      height: 20px !important;
      box-shadow: none;
      top: 5px;
      left: 8px;
    }
  }
.custom-schedule-popup {
    .text-center {
        text-align: left !important;
    }
}


.severity-label {
    display: inline-flex;
    border-radius: 100px; 
    padding: 2px 10px 2px 2px; 
    align-items: center;
    height: 19px;
    
    span.severity-text {
        color: $whitecolor;
        font-size: 10px; 
        line-height: 16px;
        font-weight: 600;
        display: inline-block;
    }

    span.severity-value { 
        font-size: 10px;
        border-radius: 100px;
        background: $whitecolor; 
        min-width: 20px; 
        text-align: center;
        font-weight: 700;
        line-height: 14px;
        display: inline-block;
        margin-right: 4px;
        padding: 0px 3px;
    }
    &.no-count {
        padding: 2px 10px;
    }
    &.severity-critical {
        background: #670004;
        span.severity-value {
            color: #670004;
        } 
    }
    &.severity-medium {
        background: #E85800;
        span.severity-value {
            color: #E85800;
        } 
    }
    &.severity-high {
        background: #A7000F;
        span.severity-value {
            color: #A7000F;
        } 
    }
    &.severity-low {
        background: #FFC900;
        span.severity-value {
            color: #FFC900;
        } 
    }
    &.severity-3 {
        background: #F2BF00;
        span.severity-value {
            color:#F2BF00
        } // Your specific color for severity 3
      }
    
}

.vector-list {
    width: 320px !important;
    background: #fff;
    box-shadow: 0px 0px 40px 0px #00000040;
    max-width: 320px !important; 
    .mat-menu-content {
        overflow: scroll;
        overflow-x: hidden;
    
    }
    
    .mat-menu-content::-webkit-scrollbar {
        width: 5px;
        right: 3px;
    }
    
    .mat-menu-content::-webkit-scrollbar-track {
        right: 3px;
    }
    
    .mat-menu-content::-webkit-scrollbar-thumb {
        background: $gainsboro;
        right: 3px;
    }
    .vector-list-section {
        .vector-list-header {
            padding-bottom: 7px;
            border-bottom: 1px solid #E2E2F2;
            margin-bottom: 10px;
            p {
                color: #696595; 
                font-size: 12px;
                font-weight: 800;
                line-height: 20px;  
            }
            &.vector-drop-header{
                p {
                    height: 22px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 300px;
                }
            }
        }
        .vector-list-info {
            display: flex;
            gap: 12px 2%;
            flex-wrap: wrap;
            .vector-list-item {
                width: 49%;
                &.full-width {
                    width: 100%;
                }
                p {
                    color: #696595;
                }
                .vector-list-label { 
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: left;
                }
                .vector-list-value { 
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 20px;
                    text-align: left;                }
            }
        }
    }
    &.cve-summarypopup {
        width: 405px !important;
        max-width: 405px !important;
        // height: 340px  !important;
        max-height: 340px !important;
    }
    &.nist-list {
        width: 160px !important;
        min-width: 160px !important;
        max-width: 160px !important;
        border-radius: 8px;
        padding: 12px 6px !important;
        ul {
            margin: 0px;
            li { 
                padding: 4px 0px 4px 10px;
                font-family: Manrope;
                font-size: 12px;
                font-weight: 500;
                line-height: 20px; 
                border-radius: 4px;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
                &.selected{
                    color: rgb(26, 7, 182);
                }
                &.hide{
                    display: none;
                }
            }
        }
}
}
.hide-overflow{
    .mat-dialog-container{
        overflow: hidden;
        h5{
            margin: 0;
            padding-bottom: 15px;
        }
        .mat-dialog-content {
            margin: 0px -21px;
        }
    }
}
.mat-menu-panel{
    &.vector-cvss-list{
        background: #fff;
        min-height: 0;
        min-width: 0 !important;
        padding: 10px 12px;
        > div {
            cursor: pointer;
        }
    }
}

.schedule-tooltip {
    padding: 16px;
    position: relative;
    border-radius: 8px;
    z-index: 1000;
    max-width: 200px;
  }
  
  .schedule-tooltip .tooltip-header {
    font-weight: 800;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  .schedule-tooltip::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    margin-left: -10px;
  }
  
  .schedule-tooltip.arrow-top::after {
    top: 100%;
  }
  
  .schedule-tooltip.arrow-bottom::after {
    bottom: 100%;
  }
  
  
  .tooltip-label {
    color: $lightblack;
    font-weight: 400;
    font-size: 12px;
    padding-bottom: 10px;

  }
  .tooltip-value {
    font-weight: 700;
    color: $lightblack;
    padding-left: 10px;
}

.vultooltip-section{
    &.default-vul-position{
        margin-left: -67px;
    }
    &.update-position{
        margin-left: 50px;
    }
    &.update-vul-position{
        margin-left: 67px;
    }
}
.add-icon {
    position: relative;
      top: 5px;
  }
  .select-credentials {
    max-height: calc(100vh - 470px);
  }

  .font {
    
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    color: $darkcharcoal;
  }


  .cve-accordion-title{
    &.mat-expansion-panel-header{
        .mat-expansion-indicator{
            margin-top: -20px;
            margin-right: 15px;
        }
    }
    &.mat-expanded{
        &.mat-expansion-panel-header{
            .mat-expansion-indicator{
                &::after {
                    transform: rotate(135deg) !important;
                    margin-left: 5px;
                }
            }
        }
    }
  }
  .new-scan-box .shedular-check .mat-checkbox-layout .mat-checkbox-label {
    color: $lightblack !important;
  }
  .affected-search-bar{
    height: 30px;
    display: flex;
    margin-right: 10px;
    .mat-form-field {
        width: 120px;
    }
    .mat-form-field-appearance-fill .mat-form-field-flex, 
    .mat-form-field-appearance-outline .mat-form-field-outline{
        background: rgb(245, 245, 255) !important;
        border-radius: 4px;
    }
    .mat-button{
        line-height: 1;
        height: 30px;
        margin: 0 0 0 10px !important;
    }
  }
.assets-list-menu{
    .mat-menu-content{
        overflow: hidden;
    }
}
.bench-mark-panel{
    min-width:318px !important;
    max-height: 310px !important;
    position: relative;
    top: 40px;
    left: 30px;
    border-radius: 4px;
    border: 1px solid #ADADD0;
    overflow: hidden !important;
}
.cred-drop-width{
    min-width:318px !important;
    max-width: 350px;
    margin-left: 30px;
}
.creden_management .disabled .mat-form-field-appearance-outline .mat-form-field-outline {
    background: $ghostwhite !important;
}
.schedule-whitebox .disabled .mat-form-field-appearance-outline .mat-form-field-outline {
    background: $ghostwhite !important;
}
.profiletab-edit .disabled .mat-form-field-appearance-outline .mat-form-field-outline {
    background: $ghostwhite !important;
}
.edit-pan-icon{
    background: url("/assets/images/pen-icon.svg") center center no-repeat rgb(58, 42, 218);
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 3px;
    bottom: 6px;
    border-radius: 50%;
    cursor: pointer;
}
.edit-button{
    cursor: pointer;
    color: rgb(58, 42, 218);
    font-weight: 600;
    height: auto;
    line-height: 1 !important;
    font-size: 13px;
    background: url("/asets/images/blue-pen-icon.svg") 7px 1px no-repeat rgba(0, 0, 0, 0);
}
.g-search-bar .mat-form-field-appearance-fill .mat-form-field-infix {
   padding-left: 6px;
   padding-right: 6px;
}
app-table-loader{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
}
// .list-details-table{
//     .ag-overlay-wrapper {
//         justify-content:start ;
//     }
// }
.assets-cve-table{
    .ag-row{
        color: #696595;;
        font-weight: 600;
    }
}
.ag-bold{
    font-weight: 500;
}
.ag-bold800{
    font-weight: 800;
}
.ag-red{
    color: #F53B3B !important;
}
.ag-custom-tooltip{
    position: absolute;
    z-index: 99;
    margin-bottom: 0;
    width: 255px;
    padding: 15px 18px;
    border-radius: 8px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, .25);
    font-size: 12px;
    right: 0;
    strong{
        font-weight: 700;
    }
    div {
        font-size: 10px;
        font-weight: normal;
        line-height: 1.5;
        padding-top: 5px;
    }
   
}
.cisa-col{
    &:hover{
        .ag-custom-tooltip{
            visibility: visible;
            opacity: 1;
        }
    }
}


.ag-grid-loader-bg {
    .ag-overlay-panel{
        background:#fff;
    }
    
}
.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-ltr .ag-cell-range-single-cell,
.ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-rtl .ag-cell-range-single-cell,
.ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: 1px solid transparent;
  outline: none;
}
.ag-center{
    display: flex;
    align-items: center;
    align-content: center;
    height: 100%;
}
.highlight-search{
    color: #3A2ADA;
}
  .half-width .mat-form-field-appearance-outline.no-bg .mat-form-field-outline {
    background: $ghostwhite !important;
    border: 1px solid #DBDBEF !important;
    height: 30px !important ;
    
}

.half-width .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0em 0 1em 0;
}
.field-hide {
    display: none !important;
  }
  .assets-details-side-nav {
    &.global-filter-main.mat-drawer-container {
        .mat-drawer-backdrop{
            &.mat-drawer-shown{
                background: rgba(0, 0, 0, 0.6);
            }
        }
    }
    mat-sidenav{
        width: 56%;
        // margin: 16px;
        // border-radius: 12px;
        // padding: 16px 0px;
        .mat-drawer-inner-container {
            overflow: hidden;
        }
    }
  }
  .mat-cve-tabs{
    .mat-tab-header {
        border: 1px solid #DBDBEF;
        border-radius: 8px 8px 0 0;
        .mat-tab-label{
            height: 48px;
            border-bottom-width: 3px;
            border-color: transparent;
            color: rgb(135,135,174);
            padding: 0;
            margin: 0px 12px;
            &.mat-tab-label-active{
                color: #3A2ADA;
                border-color: #3A2ADA;
                border-bottom-width: 3px;
            }
        }
        
    }
 }
 .assets-details-side-nav {
    .mat-drawer-inner-container{
        @extend .scroll;
    }
 }
 .cve-tab-details-section{
    @extend .mat-cve-tabs;
    .mat-tab-header{
        border: none;
        border-radius: 0;
        background: none;
    }
 }
  .cve-main-section{
    .cve-tab-section{
        @extend .mat-cve-tabs;
        height: 100%;
        .mat-tab-body-wrapper{
            height: 100%;
        }
        .spark-whitebox{
            border-radius: 0 0 8px 8px;
        }
        
    }
    .white-box-header{
        border: 1px solid rgb(238,238,253);
    }
  }

  .severity-label .color-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 2px;
}
.plain {
    background-color: $plainIndicator;

}
.critical {
    background-color: $criticalindicator;
}

.high {
    background-color: $highindicator;
}

.medium {
    background-color: $mediumindicator;
}

.low {
    background-color: $lowindicator;
}

.text {
    font-weight: bold;  
    margin-left: 6px;
}
.c-sec-loader{
    display: flex;
    flex-direction: row;
    width: 100%;
    .rhs-content-loader{
        &.graph-section-loader{
            width: 50%;
        }
        &.beach-mark-loader{
            width: 50%;
            flex-direction: column;
            > div{
                width: 100%;
                height: 28px;
            }
        }
    }
    
}
.upload-typelist{
    .mat-radio-button:not(.mat-radio-disabled){
        &.cdk-keyboard-focused {
            .mat-radio-persistent-ripple{
                opacity: 0;
            }
        }
        &.cdk-program-focused {
            .mat-radio-persistent-ripple{
                opacity: 0;
            }
        }
    }
}
.vpn-modalbox-readonly .mat-form-field-appearance-outline {
    .mat-form-field-outline {
        color:transparent;    
    }
     .mat-form-field-infix{
        padding: 6px 0 6px 0;
     }
}

.header-row-z-index {
    z-index: 9 !important; /* Set z-index to 9 for header row */
    position: relative; /* Ensure the z-index works */
  }
.mat-menu-panel {
    &.assetwith-btn{
        min-width: 123px !important;
    }
    &.white-menu {
            padding: 12px !important;
            background-color: #fff;
            min-height: 50px !important;
            .mat-menu-item {
                color: #8784AA;
                font-weight: 500;
                font-size: 12px;
                padding: 8px;
               
                &:hover {
                    color: #222222;
                    background: #F6F6FA;
                    border-radius: 4px;
                }
                &.active {
                    color: #222222;
                    background: #F6F6FA;
                    border-radius: 4px;

                }

            }
    }


}

:root {
    --ag-checkbox-checked-color: #3A2ADA !important;
  }

  
  .ag-theme-material {
    .ag-pinned-left-header{
        border: none;
    }
    .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell){
        border: none;
    }
    .ag-checkbox-input-wrapper.ag-checked::after{
        color: #3A2ADA !important;

    }
    --ag-selected-row-background-color: transparent; /* or use none */
}
.spark-region-map img {
    @media screen and (max-width: $mobile) {
        width: 100% !important;
    }
    @media screen and (min-width: 750px) and (max-width: 1023px) {
        width: 100% !important;
    }
}

.horizontal-border-bottom{
    border-bottom: 1px solid rgb(226, 226, 242);
}

::ng-deep .framework-tabgroup .mat-tab-header {
    background: transparent !important;
    padding: 0 16px !important;
    border-bottom: 1px solid rgb(226, 226, 242);
    .mat-tab-label { 
        border-bottom: none;
    }
    .mat-tab-label-active {
        background: transparent;
        color: $aquamarine;
        border-bottom: 3px solid $aquamarine !important;
    }
}
 

::ng-deep.new-scan-vendor .newscan-popup-container .newscan-info .newscan-vendorbox .api-height .api-section .scancontent-box-api .api-form form .upload-submit {
    display: flex;
   align-items: flex-end !important;
   margin-top: 0px !important;
  }


  .cloud-my-scans{
    height: calc(100% - 55px);  
}